import DashboardAdd from "../../Components/Layouts/DashboardAdd";
import FaqsForm from "../../Components/Forms/FaqsForm";
import {
  useGetFaqByIdQuery,
  useCreateFaqMutation,
} from "../../redux/slice/faq/faqApiSlice";
import { useEffect } from "react";
import { message } from "antd";
import useHandleCreateErrorsAndSuccess from "../../hooks/useHandleCreateErrorsAndSuccess";

function Add() {
  const [
    createEntity,
    {
      data: createdFaq,
      isLoading: creatingFaq,
      isError: createFaqError,
      isSuccess: faqCreated,
      error,
    },
  ] = useCreateFaqMutation("createFaq");

  let data = {
    data: undefined,
    loading: creatingFaq,
    error: createFaqError,
    dataById: undefined,
  };
  const selectedForm = {
    data: undefined,
    loading: creatingFaq,
    error: error?.data.errors,
    loadedRecently: false,
    dataById: undefined,
  };

  useHandleCreateErrorsAndSuccess(error, faqCreated, "Faq");

  return (
    <DashboardAdd>
      <FaqsForm
        isAddForm
        {...{
          getEntityById: useGetFaqByIdQuery,
          createEntity,
          data,
          selectedForm,
        }}
      />
    </DashboardAdd>
  );
}

export default Add;
