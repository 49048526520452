import { useParams } from "react-router-dom";

import DashboardUpdate from "../../Components/Layouts/DashboardUpdate";
import StoryForm from "../../Components/Forms/StoryForm";
import { useEffect } from "react";
import {
  useCreateStoryMutation,
  useGetStoryByIdQuery,
  useUpdateStoryInfoMutation,
} from "../../redux/slice/story/storyApiSlice";

function Update() {
  let params = useParams();
  const getEntityById = useGetStoryByIdQuery;

  const [
    createEntity,
    {
      data: createdStoryData,
      isLoading: creatingStory,
      isError,
      error: createStoryError,
    },
  ] = useCreateStoryMutation("createStory");

  const [
    updateStory,
    {
      data: updatedStory,
      isLoading: updating,
      isError: updateStoryError,
      error,
    },
  ] = useUpdateStoryInfoMutation("updateStory");

  const {
    data: stories,
    isLoading: loadingById,
    isError: updateError,
    isSuccess: successById,
    refetch,
  } = getEntityById(params.id);

  useEffect(() => {
    refetch();
  }, []);

  let data = {
    data: undefined,
    loading: updating || loadingById || updating,
    error: updateError || updateStoryError,
    dataById: successById && stories,
  };
  const selectedForm = {
    data: undefined,
    loading: creatingStory || loadingById || updating,
    error: error?.data?.errors,
    loadedRecently: false,
    dataById: undefined,
  };
  return (
    <DashboardUpdate>
      <StoryForm
        isUpdateForm
        {...{
          params,
          createEntity,
          updateEntityInfo: updateStory,
          data,
          selectedForm,
        }}
      />
    </DashboardUpdate>
  );
}

export default Update;
