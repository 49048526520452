import DashboardAdd from "../../Components/Layouts/DashboardAdd";
import TripinfoForm from "../../Components/Forms/TripinfoForm";
import {
  useCreateTripInfoMutation,
  useGetTripInfoByIdQuery,
} from "../../redux/slice/tripInfo/tripInfoApiSlice";
import useHandleCreateErrorsAndSuccess from "../../hooks/useHandleCreateErrorsAndSuccess";

function Add() {
  const [
    createEntity,
    {
      data: createdTripInfoData,
      isLoading: creatingTripInfo,
      isError: createTripInfoError,
      isSuccess: tripInfoCreated,
      error,
    },
  ] = useCreateTripInfoMutation("createTripInfo");

  let data = {
    data: undefined,
    loading: creatingTripInfo,
    error: createTripInfoError,
    dataById: undefined,
  };
  const selectedForm = {
    data: undefined,
    loading: creatingTripInfo,
    error: error?.data.errors,
    loadedRecently: false,
    dataById: undefined,
  };

  useHandleCreateErrorsAndSuccess(error, tripInfoCreated, "Tripinfo");

  return (
    <DashboardAdd>
      <TripinfoForm
        isAddForm
        {...{
          getEntityById: useGetTripInfoByIdQuery,
          createEntity,
          data,
          selectedForm,
        }}
      />
    </DashboardAdd>
  );
}

export default Add;
