import DashboardAdd from "../../Components/Layouts/DashboardAdd";
import BookingForm from "../../Components/Forms/BookingForm";
import { useCreateBookingMutation } from "../../redux/slice/booking/bookingApiSlice";
import { message } from "antd";
import { useEffect } from "react";
import useHandleCreateErrorsAndSuccess from "../../hooks/useHandleCreateErrorsAndSuccess";

function Add() {
  const [
    createEntity,
    {
      data: createdBookingData,
      isLoading: creatingBooking,
      isError: createBookingError,
      isSuccess: bookingSuccess,
      error,
    },
  ] = useCreateBookingMutation("createBooking");

  let data = {
    data: undefined,
    loading: creatingBooking,
    error: createBookingError,
    dataById: undefined,
  };
  const selectedForm = {
    data: undefined,
    loading: creatingBooking,
    error: error?.data.errors,
    loadedRecently: false,
    dataById: undefined,
  };

  useHandleCreateErrorsAndSuccess(error, bookingSuccess, "Booking");

  return (
    <DashboardAdd>
      <BookingForm
        isAddForm
        {...{
          createEntity,
          data,
          selectedForm,
        }}
      />
    </DashboardAdd>
  );
}

export default Add;
