import DashboardList from "../../Components/Layouts/DashboardList";
import {
  useGetDeletedStoryAuthorListQuery,
  usePermanentDeleteStoryAuthorMutation,
  useRestoreStoryAuthorListMutation,
} from "../../redux/slice/storyAuthor/storyAuthorApiSlice";

function DeletedList() {
  const [onDelete, { isLoading: permaDeleting }] =
    usePermanentDeleteStoryAuthorMutation();
  const [onRestore, { isLoading: restoring }] =
    useRestoreStoryAuthorListMutation();

  let loading = permaDeleting || restoring;
  const tableConfig = {
    title: "Deleted Author(s)",
    filterOptionTitle: "Author Type",
    filterOptions: ["admin", "superadmin", "member"],
    defaultFilterValue: "admin",
    applyFilter: false,
    tableColumns: [
      { name: "Status", selector: "status", width: "5%" },
      { name: "Name", selector: "name", width: "75%" },
      { name: "Modified Date", selector: "updated_at", width: "15%" },
      { name: "ID", selector: "id", width: "5%" },
    ],
    filterByColumn: "name",
    searchByColumn: "name",
    updatePath: "/story-author/update",
    addPath: "/story-author/add",
    restorePath: "/story-authors",
    noDataMessage: "No Author exists",
    updateLinkColumn: "name",
    deleteBtnText: "Permanent Delete",
    deleteItemsByIdList: (idList) => {
      onDelete(idList);
    },
    restoreItemsByIdList: (idList) => {
      onRestore(idList);
    },
    loading,
  };
  return (
    <DashboardList
      getList={useGetDeletedStoryAuthorListQuery()}
      tableConfig={tableConfig}
    />
  );
}

export default DeletedList;
