import { apiSlice } from "../../api/apiSlice";
import {
  createQueryForList,
  createQueryById,
  createDeleteRestoreMutation,
} from "../commonFunction";

export const commentApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["Comment", "CommentById", "DeletedComment"],
  endpoints: (builder) => ({
    getCommentList: createQueryForList(builder, "/blog/comment/all", ["Comment"]),

    getCommentById: createQueryById(builder, "/blog/comment/single", ["CommentById"]),

    getDeletedCommentList: createQueryForList(builder, "/blog/comment/rejected", [
      "DeletedComment",
    ]),

    deleteComment: createDeleteRestoreMutation(builder, "/blog/comment/bulk/delete", [
      "DeletedComment",
      "Comment",
    ]),

    // permanentDeleteComment: builder.mutation({
    //   query: (idList) => ({
    //     url: "/blog/comment/bulk/perma-delete",
    //     method: "POST",
    //     body: { bulk: idList },
    //   }),
    //   invalidatesTags: ["DeletedComment"],
    // }),
    // restoreCommentList: builder.mutation({
    //   query: (idList) => ({
    //     url: "/blog/comment/bulk/restore",
    //     method: "POST",
    //     body: { bulk: idList },
    //   }),
    //   invalidatesTags: ["Comment", "DeletedComment"],
    // }),

    createComment: builder.mutation({
      query: (formData) => {
        let data = Object.fromEntries(
          Object.entries(formData)
            .filter(([_, v]) => v != null)
            .filter(([_, v]) => v !== "")
            .filter(([_, v]) => v !== undefined)
        );

        let webKitFormData = new FormData();
        // if (data.package_id) {
        //   for (let i = 0; i < data.package_id.length; i++) {
        //     webKitFormData.append("package_id[]", data.package_id[i]);
        //   }
        // } ["package_id"].forEach(
        //   (e) => delete data[e]
        // );

        Object.keys(data).forEach((item, i) => {
          webKitFormData.append(item, data[item]);
        });

        return {
          url: "/blog/comment/add",
          method: "POST",
          headers: {
            Accept: "application/json",
          },
          body: webKitFormData,
        };
      },
      invalidatesTags: ["Comment"],
    }),

    updateCommentInfo: builder.mutation({
      query: ({ formData, id }) => {
        let data = Object.fromEntries(
          Object.entries(formData)
            .filter(([_, v]) => v != null)
            .filter(([_, v]) => v !== "")
            .filter(([_, v]) => v !== undefined)
        );

        let webKitFormData = new FormData();
        // if (data.package_id) {
        //   for (let i = 0; i < data.package_id.length; i++) {
        //     webKitFormData.append("package_id[]", data.package_id[i]);
        //   }
        // }
        // ["package_id"].forEach(
        //   (e) => delete data[e]
        // );
        Object.keys(data).forEach((item, i) => {
          webKitFormData.append(item, data[item]);
        });
        webKitFormData.append("_method", "put");

        return {
          url: `/blog/comment/edit/${id}`,
          method: "POST",
          headers: {
            Accept: "application/json",
          },
          body: webKitFormData,
        };
      },
      invalidatesTags: ["Comment", "CommentById"],
    }),
  }),
});
export const {
  useGetCommentListQuery,
  useGetCommentByIdQuery,
  useGetDeletedCommentListQuery,
  useDeleteCommentMutation,
  // usePermanentDeleteCommentMutation,
  // useRestoreCommentListMutation,
  useCreateCommentMutation,
  useUpdateCommentInfoMutation,
} = commentApiSlice;
