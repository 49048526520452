import DashboardList from "../../Components/Layouts/DashboardList";
import {
  useDeleteStoryMutation,
  useGetStoryListQuery,
} from "../../redux/slice/story/storyApiSlice";

function List() {
  const [onDelete] = useDeleteStoryMutation("deleteEntityById");

  const tableConfig = {
    title: "Stories",
    filterOptionTitle: "Story Type",
    filterOptions: ["admin", "superadmin", "member"],
    defaultFilterValue: "admin",
    applyFilter: false,
    tableColumns: [
      // { name: "Order", selector: "order", width: "5%" },
      { name: "Status", selector: "status", width: "5%" },
      { name: "Title", selector: "title", width: "70%" },
      { name: "Modified Date", selector: "updated_at", width: "15%" },
      { name: "ID", selector: "id", width: "5%" },
    ],
    filterByColumn: "title",
    searchByColumn: "title",
    updatePath: "/story/update",
    addPath: "/story/add",
    noDataMessage: "No story exists",
    deleteBtnText: "Delete",
    updateLinkColumn: "title",
    deleteItemsByIdList: (idList) => {
      onDelete(idList);
    },
  };

  return (
    <DashboardList getList={useGetStoryListQuery()} tableConfig={tableConfig} />
  );
}

export default List;
