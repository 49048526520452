import { useEffect } from "react";
import { useParams } from "react-router-dom";

import DashboardUpdate from "../../Components/Layouts/DashboardUpdate";
import BookingForm from "../../Components/Forms/BookingForm";

import {
  useCreateBookingMutation,
  useGetBookingByIdQuery,
  useUpdateBookingInfoMutation,
} from "../../redux/slice/booking/bookingApiSlice";

function Update() {
  let params = useParams();
  const getEntityById = useGetBookingByIdQuery;

  const [
    createEntity,
    {
      data: createdBookingData,
      isLoading: creatingBooking,
      isError,
      error: createBookingError,
    },
  ] = useCreateBookingMutation("createBooking");

  const [
    updateBooking,
    {
      data: updatedBooking,
      isLoading: updating,
      isError: updateBookingError,
      error,
    },
  ] = useUpdateBookingInfoMutation("updateBooking");

  const {
    data: bookings,
    isLoading: loadingById,
    isError: updateError,
    isSuccess: successById,
    refetch,
  } = getEntityById(params.id);

  useEffect(() => {
    refetch();
  }, []);

  let data = {
    data: undefined,
    loading: updating || loadingById || updating,
    error: updateError || updateBookingError,
    dataById: successById && bookings,
  };
  const selectedForm = {
    data: undefined,
    loading: creatingBooking || loadingById || updating,
    error: error?.data?.errors,
    loadedRecently: false,
    dataById: undefined,
  };

  return (
    <DashboardUpdate>
      <BookingForm
        isUpdateForm
        {...{
          params,
          getEntityById,
          updateEntityInfo: updateBooking,
          data,
          selectedForm,
        }}
      />
    </DashboardUpdate>
  );
}

export default Update;
// const mapStateToProps = (state) => {
//   return {
//     data: state.booking,
//     loginData: state.loginData,
//     selectedForm: state.bookingForm,
//   };
// };

// const mapDispatchToProps = (dispatch) => {
//   return {
//     getEntityById: (tokenData, id) => dispatch(getEntityById(tokenData, id)),
//     createEntity: (formData, tokenData) => dispatch(createEntity(formData, tokenData)),
//     updateEntityInfo: (formData, tokenData, id) => dispatch(updateEntityInfo(formData, tokenData, id)),
//     setUpdatedRecentlyToFalse: () => dispatch(setUpdatedRecentlyToFalse()),
//   };
// };

// export default connect(mapStateToProps, mapDispatchToProps)(Update);
