import DashboardAdd from "../../Components/Layouts/DashboardAdd";
import ItineraryForm from "../../Components/Forms/ItineraryForm";
import { useCreateItineraryMutation } from "../../redux/slice/itinerary/itineraryApiSlice";
import useHandleCreateErrorsAndSuccess from "../../hooks/useHandleCreateErrorsAndSuccess";

function Add() {
  const [
    createEntity,
    {
      data: createdItineraryData,
      isLoading: creatingItinerary,
      isError: createItineraryError,
      isSuccess: createItinerarySuccess,
      error,
    },
  ] = useCreateItineraryMutation("createItinerary");

  let data = {
    data: undefined,
    loading: creatingItinerary,
    error: createItineraryError,
    dataById: undefined,
  };
  const selectedForm = {
    data: undefined,
    loading: creatingItinerary,
    error: error?.data.errors,
    loadedRecently: false,
    dataById: undefined,
  };

  useHandleCreateErrorsAndSuccess(error, createItinerarySuccess, "Itinerary");

  return (
    <DashboardAdd>
      <ItineraryForm
        isAddForm
        {...{
          createEntity,
          data,
          selectedForm,
        }}
      />
    </DashboardAdd>
  );
}

export default Add;
