import DashboardList from "../../Components/Layouts/DashboardList";
import {
  useDeleteItineraryMutation,
  useGetItineraryListQuery,
} from "../../redux/slice/itinerary/itineraryApiSlice";

function List() {
  const [onDelete] = useDeleteItineraryMutation("deleteEntityById");

  const tableConfig = {
    title: "Itineraries",
    filterOptionTitle: "Team Type",
    filterOptions: ["package"],
    defaultFilterValue: "admin",
    applyFilter: false,
    tableColumns: [
      { name: "Status", selector: "status", width: "3%" },
      //{name: "Order", selector: 'order', width: '3%'},
      { name: "Title", selector: "itinerary_title", width: "70%" },
      { name: "Modified Date", selector: "updated_at", width: "20%" },
      { name: "ID", selector: "id", width: "5%" },
    ],
    filterByColumn: "itinerary_title",
    searchByColumn: "itinerary_title",
    updatePath: "/itinerary/update",
    addPath: "/itinerary/add",
    noDataMessage: "No Itinerary exists",
    updateLinkColumn: "itinerary_title",
    deleteBtnText: "Delete",
    deleteItemsByIdList: (idList) => {
      onDelete(idList);
    },
  };

  return (
    <DashboardList
      getList={useGetItineraryListQuery()}
      tableConfig={tableConfig}
    />
  );
}
export default List;
