import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import DashboardUpdate from "../../Components/Layouts/DashboardUpdate";
import CategoryForm from "../../Components/Forms/CategoryForm";
import { message } from "antd";
import {
  useUpdateCategoryMutation,
  useGetCategoryByIdQuery,
} from "../../redux/slice/category/categoryApiSlice";

export default function Update() {
  let params = useParams();

  const {
    data: categories,
    isLoading: loadingById,
    isSuccess: successById,
    refetch,
  } = useGetCategoryByIdQuery(params.id, {});

  useEffect(() => {
    refetch();
  }, []);

  let [
    updateEntityInfo,
    {
      isLoading: updating,
      error: updateEntityError,
      isSuccess: categoryUpdated,
    },
  ] = useUpdateCategoryMutation("update");

  let data = {
    data: undefined,
    loading: updating || loadingById,
    error: false,
    dataById: successById && categories,
  };

  useEffect(() => {
    updateEntityError?.message && message.error(updateEntityError?.message);
    categoryUpdated &&
      message.success("Category details updated successfully!");
  }, [updateEntityError?.message, categoryUpdated]);

  return (
    <DashboardUpdate>
      <CategoryForm isUpdateForm {...{ params, updateEntityInfo, data }} />
    </DashboardUpdate>
  );
}
