import {
  Alert,
  Button,
  Col,
  Collapse,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Select,
  Spin,
  Switch
} from "antd";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import slugify from "react-slugify";
import { BASE_URL } from "../../lib/urls";
import TextEditor from "../Core/ReactTinymce/ReactTinymce";
import MediaManager from "../MediaManager";
import SpeedoMeter from "../SpeedoMeter";

import {
  useCreateItineraryMutation,
  useGetItineraryByPackageIdQuery,
  useUpdateItineraryInfoMutation,
} from "../../redux/slice/itinerary/itineraryApiSlice";
import {
  DATA_TEMPLATE,
  DEFAULT_SELECTED_DAY,
  DEFAULT_SELECTED_STOP,
} from "./MapResolution/ItineraryFormNewSupportComponents";
import MapDataForm from "./MapResolution/MapDataForm";

import { PlusOutlined } from "@ant-design/icons";
import { useGetPackageListQuery } from "../../redux/slice/package/packageApiSlice";
import { base64ToBlob } from "../../utils/base64ToBlob";
import { cleanFormValues } from "../../utils/cleanValues";

const { Option } = Select;
const { Panel } = Collapse;

const ItineraryForm = ({ isUpdateForm, isAddForm, package_id }) => {
  const navigate = useNavigate();
  let params = useParams();

  const [
    createEntity,
    {
      data: createdItineraryData,
      isLoading: creatingItinerary,
      isError,
      error: createItineraryError,
    },
  ] = useCreateItineraryMutation("createItinerary");

  const [
    updateEntityInfo,
    { data: updatedItinerary, isLoading: updating, isError: updateItineraryError, error },
  ] = useUpdateItineraryInfoMutation("updateItinerary");

  const {
    data: itinerarys,
    isLoading: loadingById,
    isError: isGetError,
    // error: itineraryError,
    isSuccess: successById,
  } = useGetItineraryByPackageIdQuery(package_id, { skip: !isUpdateForm });

  let data = {
    data: undefined,
    loading: updating || loadingById || updating,
    error: isGetError || updateItineraryError,
    dataById: successById && itinerarys,
  };
  const selectedForm = {
    data: undefined,
    loading: creatingItinerary || loadingById || updating,
    error: error?.data?.errors,
    loadedRecently: false,
    dataById: undefined,
  };

  const [description, setDescription] = useState(isUpdateForm && data.dataById?.description);
  const [hidden, setHidden] = useState(false);
  const [formFields, setFormFields] = useState({
    [DEFAULT_SELECTED_DAY]: { [DEFAULT_SELECTED_STOP]: DATA_TEMPLATE },
  });
  const [selectedItems, setSelectedItems] = useState({});
  const [hasMap, setHasMap] = useState(true);
  const [itineraryThumb, setItineraryThumb] = useState(null);

  const [form] = Form.useForm();
  const { setFieldsValue } = form;

  const {
    data: packageListData,
    isLoading: packageListLoading,
    isError: packageListError,
  } = useGetPackageListQuery("packageList");

  const loading = selectedForm.loading || packageListLoading;
  if (packageListError) {
    message.error("Error on package list");
  }

  const packageListOptions = packageListData?.map((itm) => {
    return {
      value: itm.id,
      label: itm.package_title,
    };
  });

  function transformDataForMap(itineraries) {
    return itineraries.reduce((acc, itinerary) => {
      const day = itinerary.itinerary_day;
      if (!acc[day]) {
        acc[day] = {};
      }
      const stopNumber = Object.keys(acc[day]).length + 1;
      acc[day][stopNumber] = { ...itinerary };
      return acc;
    }, {});
  }

  const userAction = (e, action) => {
    e.preventDefault();
    form.validateFields().then((formValues) => {
      let values = cleanFormValues(formValues);
      if (action === "add") {
        createEntity({
        ...values,
          status: values.status ? 1 : 0,
          remove_map: values.remove_map ? 1 : 0,
          ...(itineraryThumb && { itinerary_map: base64ToBlob(itineraryThumb) }),
          itineraries: hasMap ? formFields : transformDataForMap(values.itineraries),
          package_id: params.id,
        });
      } else if (action === "addandexit") {
        createEntity({
          ...values,
          status: values.status ? 1 : 0,
          remove_map: values.remove_map ? 1 : 0,
          ...(itineraryThumb && { itinerary_map: base64ToBlob(itineraryThumb) }),
          itineraries: hasMap ? formFields : transformDataForMap(values.itineraries),
          package_id: params.id,
        })
          .unwrap()
          .then(() => navigate("/itineraries"))
          .catch();
      } else if (action === "update") {
        updateEntityInfo({
          formData: {
            ...values,
            status: values.status ? 1 : 0,
            remove_map: values.remove_map ? 1 : 0,
            ...(itineraryThumb && { itinerary_map: base64ToBlob(itineraryThumb) }),
            itineraries: hasMap ? formFields : transformDataForMap(values.itineraries),
            package_id: params.id,
          },
          id: data.dataById?.id,
        });
      }
    });
  };

  const addNewEntry = (e) => {
    userAction(e, "add");
  };

  const addNewEntryAndExistToList = (e) => {
    userAction(e, "addandexit");
  };

  const updateExistingEntry = (e) => {
    userAction(e, "update");
  };

  const clearForm = () => {
    form.resetFields();
    setDescription("");
  };
  if (isUpdateForm) {
    const itinerariesDetails = data.dataById?.details?.map((e) => {
      return {
        ...e,
        day: e.itinerary_day,
        description: e.itinerary_description,
        title: e.itinerary_title,
        facts: e.facts,
        accommodation: e.accommodation,
        distance: e.distance,
        duration: e.duration,
        destination: e.destination,
        travel_mode: e.travel_mode,
        meals: e.meals,
        origin: e.origin,
      };
    });

    setFieldsValue({
      itinerary_title: data.dataById?.itinerary_title,
      url_title: data.dataById?.urlinfo?.url_title,
      slug: data.dataById?.urlinfo?.url_slug,
      package_id: data.dataById?.package_id,
      status: data.dataById?.status === 1 ? true : false,
      remove_map: data.dataById?.remove_map === 1 ? true : false,
      meta_title: data.dataById?.meta?.meta_title,
      meta_keywords: data.dataById?.meta?.meta_keywords,
      meta_description: data.dataById?.meta?.meta_description,
      itineraries: itinerariesDetails,
    });
  }

  const actionBarOptions = {
    titleToAdd: "Add Itinerary",
    titleToUpdate: "Update Itinerary",
    taskCompleteMessageDisplayTime: 2000,
    addButtonLabel: "Save & Add New",
    updateButtonLabel: "Update Itinerary",
    taskCompleteAddMessage: "Itinerary is added!",
    taskCompleteUpdateMessage: "Itinerary is updated!",
    discardLink: "itineraries",
    addNewEntry,
    updateExistingEntry,
    loading,
    isAddForm,
    isUpdateForm,
    selectedForm,
    clearForm,
    addNewEntryAndExistToList,
  };

  const handleSelectItems = (itineraryId, items) => {
    setSelectedItems((prevState) => ({
      ...prevState,
      [itineraryId]: items,
    }));
  };

  const handleDeleteMedia = (itineraryId, itemId) => {
    setSelectedItems((prevState) => {
      const { [itineraryId]: items, ...rest } = prevState;
      const remainingItems = items.filter((item) => item.id !== itemId);

      return remainingItems.length ? { ...rest, [itineraryId]: remainingItems } : rest;
    });
  };

  return (
    <>
      <Form
        form={form}
        size="large"
        layout="vertical"
        className="custom-form"
        wrapperCol={{
          flex: 1,
        }}
      >
        {selectedForm?.error?.errors && (
          <Alert
            className="custom-alert"
            message="Error"
            showIcon
            description={[
              <ul>
                {Object.values(selectedForm?.error?.errors).map((itm, idx) => {
                  return <li key={idx}>{itm}</li>;
                })}
              </ul>,
            ]}
            type="error"
            closable
          />
        )}

        <Spin spinning={loading} delay={500}>
          <div className="inner-action-bar header">
            <div className="left-content">
              <h2>Itinerary</h2>
              {isUpdateForm ? (
                <SpeedoMeter score={20} totalScore={20} />
              ) : (
                <SpeedoMeter score={5} totalScore={20} />
              )}
            </div>
            <div className="right-content">
              <div className="btn-group">
                <button
                  disabled={actionBarOptions.loading}
                  htmlType="submit"
                  className="btn btn-primary"
                  onClick={
                    isUpdateForm
                      ? actionBarOptions.updateExistingEntry
                      : actionBarOptions.addNewEntry
                  }
                  id="add_new"
                >
                  <i className="bi bi-save2"></i>
                  {isUpdateForm ? "Save" : "Create New"}
                </button>
                {/* <button className="btn btn-secondary" onClick={() => setHasMap(!hasMap)}>Toggle Itinerary</button> */}
              </div>
            </div>
          </div>

          <div className="module-container">
            <div className="common-module mt-3">
              <Row gutter={{ xs: 8, sm: 16, md: 24 }} hidden={isUpdateForm}>
                <Col className="gutter-row" xs={24} sm={12} xl={8}>
                  <Form.Item
                    label="Title"
                    name="itinerary_title"
                    rules={[
                      {
                        required: true,
                        message: "Itinerary title is required",
                      },
                      { min: 5, max: 255, message: "Invalid Itinerary title" },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} sm={12} xl={8}>
                  <Form.Item
                    label="Url Title"
                    name="url_title"
                    rules={[
                      {
                        required: true,
                        message: "Url title is required",
                      },
                      { min: 5, max: 255, message: "Invalid package title" },
                    ]}
                  >
                    <Input
                      onChange={(e) => form.setFieldsValue({ slug: slugify(e.target.value) })}
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} sm={12} xl={8}>
                  <Form.Item
                    label={
                      <span className="ant-form-item-optional">Auto Generate from Url Title</span>
                    }
                    name="slug"
                    title="Auto Generate from Url Title"
                    rules={[
                      {
                        required: true,
                        message: "Slug is required",
                      },
                    ]}
                  >
                    <Input readOnly />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} sm={12} xl={8}>
                  <Form.Item
                    label="Select a package"
                    name="package_id"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Package field is required",
                    //   },
                    // ]}
                    defaultValue={
                      packageListOptions?.find((item) => item.id === parseInt(params.id))?.label
                    }
                  >
                    <Select
                      onSelect={(e) => e !== null && setHidden(true)}
                      showSearch
                      filterOption={(input, option) => option.children.includes(input)}
                      filterSort={(optionA, optionB) =>
                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                      }
                      defaultValue={
                        packageListOptions?.find((item) => item.value === parseInt(params.id))
                          ?.label
                      }
                      disabled
                    >
                      {packageListOptions?.map((itm) => {
                        return (
                          <Option key={itm.value} value={itm.value}>
                            {itm.label}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} xl={16}>
                  <div className="switch-group">
                    <Form.Item label="Status" valuePropName="checked" name="status">
                      <Switch checkedChildren="Publish" unCheckedChildren="Unpublish" />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              {hasMap ? (
                <>

<MapDataForm
                  {...{
                    itineraryThumb,
                    setItineraryThumb,
                    formFields,
                    setFormFields,
                    isUpdateForm,
                    ...{
                      itinerariesDetails: isUpdateForm && itinerarys ? itinerarys.details : [],
                    },
                  }}
                />
                  <div className="switch-group">
                    <Form.Item label="Disable Map" valuePropName="checked" name="remove_map">
                      <Switch checkedChildren="Yes" unCheckedChildren="No" />
                    </Form.Item>
                  </div>
                </>
              ) : (
                <>
                  {form.getFieldValue("package_id") !== undefined && (
                    <Form.List name="itineraries">
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map((field, index) => {
                            return (
                              <div key={field.key} className="itineraries">
                                <Collapse
                                  bordered={false}
                                  defaultActiveKey={["0"]}
                                  expandIcon={({ isActive }) =>
                                    isActive ? (
                                      <i className="bi bi-plus"></i>
                                    ) : (
                                      <i className="bi-dash"></i>
                                    )
                                  }
                                >
                                  <Panel
                                    header={
                                      <h6 className="form-label">
                                        Day #{index + 1}{" "}
                                        {index >= 1 && (
                                          <>
                                            <span
                                              className="btn-delate"
                                              onClick={() => remove(field.name)}
                                            >
                                              Delete
                                            </span>
                                          </>
                                        )}
                                      </h6>
                                    }
                                    key={index}
                                  >
                                    <div class="common-module bg-white">
                                      <Row gutter={{ xs: 8, sm: 10, md: 10 }}>
                                        <Col className="gutter-row" xs={24} sm={4} xl={3} hidden>
                                          <Form.Item
                                            {...field}
                                            name={[field.name, "itinerary_day"]}
                                            initialValue={index + 1}
                                            rules={[
                                              {
                                                required: true,
                                                message: "Field is required",
                                              },
                                            ]}
                                          >
                                            <Input placeholder="Day" />
                                          </Form.Item>
                                        </Col>
                                        <Col className="gutter-row" xs={24} sm={24} xl={24}>
                                          <Form.Item
                                            {...field}
                                            name={[field.name, "itinerary_title"]}
                                            rules={[
                                              {
                                                required: true,
                                                message: "Title is required",
                                              },
                                            ]}
                                          >
                                            <Input placeholder="Itinerary title (Outline)*" />
                                          </Form.Item>
                                          <Form.Item
                                            {...field}
                                            name={[field.name, "itinerary_description"]}
                                          >
                                            <TextEditor
                                              simpleTollbar
                                              placeholder="Itinerary description"
                                            />
                                          </Form.Item>
                                          {isUpdateForm && (
                                            <>
                                              <Form.Item
                                                hidden
                                                {...field}
                                                label="&nbsp;"
                                                initialValue={0}
                                                name={[field.name, "id"]}
                                              >
                                                <InputNumber />
                                              </Form.Item>
                                            </>
                                          )}
                                        </Col>

                                        <Col className="gutter-row" xs={24} sm={12} xl={8}>
                                          <Form.Item
                                            label="Accommodation"
                                            {...field}
                                            name={[field.name, "accommodation"]}
                                          >
                                            <Input placeholder="accommodation.." />
                                          </Form.Item>
                                        </Col>
                                        <Col className="gutter-row" xs={24} sm={12} xl={8}>
                                          <Form.Item
                                            label="Meals"
                                            {...field}
                                            name={[field.name, "meals"]}
                                          >
                                            <Input placeholder="meals.." />
                                          </Form.Item>
                                        </Col>
                                        {/* <Col className="gutter-row" xs={24} sm={12} xl={8} >
                                          <Form.Item {...field} name={[field.name, "origin"]}>
                                            <Input placeholder="origin.." />
                                          </Form.Item>
                                        </Col>
                                        <Col className="gutter-row" xs={24} sm={12} xl={8} >
                                          <Form.Item {...field} name={[field.name, "destination"]}>
                                            <Input placeholder="destination.." />
                                          </Form.Item>
                                        </Col> */}
                                        <Col className="gutter-row" xs={24} sm={12} xl={8}>
                                          <Form.Item
                                            label="Transportation"
                                            {...field}
                                            name={[field.name, "travel_mode"]}
                                          >
                                            <Input placeholder="travel_mode.." />
                                          </Form.Item>
                                        </Col>
                                        <Col className="gutter-row" xs={24} sm={12} xl={8}>
                                          <Form.Item
                                            label="Distance"
                                            {...field}
                                            name={[field.name, "distance"]}
                                          >
                                            <Input placeholder="distance.." />
                                          </Form.Item>
                                        </Col>
                                        <Col className="gutter-row" xs={24} sm={12} xl={8}>
                                          <Form.Item
                                            label="Duration"
                                            {...field}
                                            name={[field.name, "duration"]}
                                          >
                                            <Input placeholder="duration.." />
                                          </Form.Item>
                                        </Col>
                                        <Col className="gutter-row" xs={24}>
                                          <Form.Item
                                            {...field}
                                            name={[field.name, "gallery"]}
                                            className="mb-0"
                                            label="Gallery"
                                          >
                                            <div className="media-display-slot">
                                              <ul>
                                                {selectedItems[
                                                  form.getFieldValue([
                                                    "itineraries",
                                                    field.name,
                                                    "id",
                                                  ])
                                                ]?.map((item, idx) => {
                                                  return (
                                                    <li key={idx}>
                                                      <figure>
                                                        <div className="image-slot">
                                                          <img
                                                            key={idx}
                                                            src={BASE_URL + item.full_path}
                                                            alt={`Gallery item ${idx + 1}`}
                                                          />
                                                        </div>

                                                        <figcaption>
                                                          <button
                                                            type="button"
                                                            className="btn-trash"
                                                            onClick={() =>
                                                              handleDeleteMedia(
                                                                form.getFieldValue([
                                                                  "itineraries",
                                                                  field.name,
                                                                  "id",
                                                                ]),
                                                                item.id
                                                              )
                                                            }
                                                          >
                                                            <i className="bi-trash"></i>
                                                          </button>
                                                        </figcaption>
                                                      </figure>
                                                    </li>
                                                  );
                                                })}

                                                {form
                                                  .getFieldValue([
                                                    "itineraries",
                                                    field.name,
                                                    "gallery",
                                                  ])
                                                  ?.map((item, idx) => (
                                                    <li key={idx}>
                                                      <figure>
                                                        <div className="image-slot">
                                                          <img
                                                            key={idx}
                                                            src={BASE_URL + item?.media.full_path}
                                                            alt={`Gallery item ${idx + 1}`}
                                                          />
                                                        </div>
                                                        <figcaption>
                                                          <button
                                                            type="button"
                                                            className="btn-trash"
                                                            onClick={() =>
                                                              handleDeleteMedia(
                                                                form.getFieldValue([
                                                                  "itineraries",
                                                                  field.name,
                                                                  "id",
                                                                ]),
                                                                item.id
                                                              )
                                                            }
                                                          >
                                                            <i className="bi-trash"></i>
                                                          </button>
                                                        </figcaption>
                                                      </figure>
                                                    </li>
                                                  ))}
                                                <li className="btn-media-add">
                                                  <MediaManager
                                                    mediaKeyType="Itinerary Gallery"
                                                    isMultiple
                                                    onSelectItems={(items) =>
                                                      handleSelectItems(
                                                        form.getFieldValue([
                                                          "itineraries",
                                                          field.name,
                                                          "id",
                                                        ]),
                                                        items
                                                      )
                                                    }
                                                  />
                                                </li>
                                              </ul>
                                            </div>
                                          </Form.Item>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Panel>
                                </Collapse>
                              </div>
                            );
                          })}
                          <Form.Item>
                            <Button
                              className="rounded"
                              type="primary"
                              onClick={() => add()}
                              block
                              icon={<PlusOutlined />}
                            >
                              Add New Itinerary/DAY
                            </Button>
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                  )}
                </>
              )}
            </div>
          </div>
        </Spin>
      </Form>
    </>
  );
};

export default ItineraryForm;
