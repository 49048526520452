import DashboardAdd from "../../Components/Layouts/DashboardAdd";
import PageForm from "../../Components/Forms/PageForm";
import {
  useCreatePageMutation,
  useGetPageByIdQuery,
} from "../../redux/slice/page/pageApiSlice";
import useHandleCreateErrorsAndSuccess from "../../hooks/useHandleCreateErrorsAndSuccess";

function Add() {
  const [
    createEntity,
    {
      data: createdPageData,
      isLoading: creatingPage,
      isError: createPageError,
      isSuccess: pageCreated,
      error,
    },
  ] = useCreatePageMutation("createPage");

  let data = {
    data: undefined,
    loading: creatingPage,
    error: createPageError,
    dataById: undefined,
  };
  const selectedForm = {
    data: undefined,
    loading: creatingPage,
    error: error?.data.errors,
    loadedRecently: false,
    dataById: undefined,
  };

  useHandleCreateErrorsAndSuccess(error, pageCreated, "Article");

  return (
    <DashboardAdd>
      <PageForm
        isAddForm
        {...{
          getEntityById: { useGetPageByIdQuery },
          createEntity,
          data,
          selectedForm,
        }}
      />
    </DashboardAdd>
  );
}

export default Add;
