import axios from "axios";
import { useLocation, Navigate, Outlet, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import useIdleLogout from "../hooks/useIdleLogout";
import { selectCurrentToken, logOut as clearToken } from "../redux/slice/auth";
import { message } from "antd";

const RequireAuth = () => {
  const token = useSelector(selectCurrentToken) || localStorage.getItem("access_token");
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useIdleLogout(() => {
    handleLogout();
  });

  const handleLogout = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}api/v1/admin/auth/logout`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Logout successful:", response.data);
    } catch (err) {
      message.error("Failed to logout");
      console.error("Failed to logout:", err);
    } finally {
      dispatch(clearToken());
      navigate("/login");
    }
  };

  return token ? <Outlet /> : <Navigate to="/login" state={{ from: location }} replace />;
};

export default RequireAuth;
