import { apiSlice } from "../../api/apiSlice";
import {
  createQueryForList,
  createQueryById,
  createDeleteRestoreMutation,
} from "../commonFunction";

export const paymentApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["Payment", "PaymentById", "DeletedPayment"],
  endpoints: (builder) => ({
    getPaymentList: createQueryForList(builder, "/front/payment/admin", [
      "Payment",
    ]),

    getPaymentById: createQueryById(builder, "/front/payment/single", [
      "PaymentById",
    ]),

    createPayment: builder.mutation({
      query: (formData) => {
        let webKitFormData = new FormData();
        Object.keys(formData).forEach((item, i) => {
          webKitFormData.append(item, formData[item]);
        });

        return {
          url: "/front/payment/create",
          method: "POST",
          headers: {
            Accept: "application/json",
          },
          body: webKitFormData,
        };
      },
      invalidatesTags: ["Payment"],
    }),

    updatePaymentInfo: builder.mutation({
      query: ({ formData, id }) => {
        let webKitFormData = new FormData();
        Object.keys(formData).forEach((item, i) => {
          webKitFormData.append(item, formData[item]);
        });
        webKitFormData.append("_method", "put");

        return {
          url: `/front/payment/edit/${id}`,
          method: "POST",
          headers: {
            Accept: "application/json",
          },
          body: webKitFormData,
        };
      },
      invalidatesTags: ["Payment", "PaymentById"],
    }),
  }),
});
export const {
  useGetPaymentListQuery,
  useGetPaymentByIdQuery,
  useCreatePaymentMutation,
  useUpdatePaymentInfoMutation,
} = paymentApiSlice;
