import { useParams } from "react-router-dom";
import DashboardUpdate from "../../Components/Layouts/DashboardUpdate";
import StoryAuthorForm from "../../Components/Forms/StoryAuthorForm";
import { useEffect } from "react";
import {
  useCreateStoryAuthorMutation,
  useGetStoryAuthorByIdQuery,
  useUpdateStoryAuthorMutation,
} from "../../redux/slice/storyAuthor/storyAuthorApiSlice";

function Update() {
  let params = useParams();

  const [
    createEntity,
    {
      data: createdStoryAuthorData,
      isLoading: creatingStoryAuthor,
      isError,
      error: createStoryAuthorError,
    },
  ] = useCreateStoryAuthorMutation("createStoryAuthor");

  const [
    updateStoryAuthor,
    {
      data: updatedStoryAuthor,
      isLoading: updating,
      isError: updateStoryAuthorError,
      error,
    },
  ] = useUpdateStoryAuthorMutation("updateStoryAuthor");

  const {
    data: author,
    isLoading: loadingById,
    isError: updateError,
    isSuccess: successById,
    refetch,
  } = useGetStoryAuthorByIdQuery(params.id);

  useEffect(() => {
    refetch();
  }, []);

  let data = {
    data: undefined,
    loading: updating || loadingById || updating,
    error: updateError || updateStoryAuthorError,
    dataById: successById && author,
  };
  const selectedForm = {
    data: undefined,
    loading: creatingStoryAuthor || loadingById || updating,
    error: error?.data.errors,
    loadedRecently: false,
    dataById: undefined,
  };
  return (
    <DashboardUpdate>
      <StoryAuthorForm
        isUpdateForm
        {...{
          params,
          createEntity,
          updateEntityInfo: updateStoryAuthor,
          data,
          selectedForm,
        }}
      />
    </DashboardUpdate>
  );
}

export default Update;
