import DashboardAdd from "../../Components/Layouts/DashboardAdd";
import PaymentForm from "../../Components/Forms/PaymentForm";

import { useCreatePaymentMutation } from "../../redux/slice/payment/paymentApiSlice";
import { useEffect } from "react";
import { message } from "antd";
import useHandleCreateErrorsAndSuccess from "../../hooks/useHandleCreateErrorsAndSuccess";

function Add() {
  const [
    createEntity,
    {
      data: createdPaymentData,
      isLoading: creatingPayment,
      isError: createPaymentError,
      isSuccess: createPaymentSuccess,
      error,
    },
  ] = useCreatePaymentMutation("createPayment");

  let data = {
    data: undefined,
    loading: creatingPayment,
    error: createPaymentError,
    dataById: undefined,
  };
  const selectedForm = {
    data: undefined,
    loading: creatingPayment,
    error: error?.data.errors,
    loadedRecently: false,
    dataById: undefined,
  };

  useHandleCreateErrorsAndSuccess(error, createPaymentSuccess, "Payment");

  return (
    <DashboardAdd>
      <PaymentForm
        isAddForm
        {...{
          createEntity,
          data,
          selectedForm,
        }}
      />
    </DashboardAdd>
  );
}

export default Add;
// const mapStateToProps = (state) => {
//   return {
//     data: state.booking,
//     loginData: state.loginData,
//     selectedForm: state.bookingForm,
//   };
// };

// const mapDispatchToProps = (dispatch) => {
//   return {
//     getEntityById: (tokenData, id) => dispatch(getEntityById(tokenData, id)),
//     createEntity: (formData, tokenData, exitAfter, history) =>
//       dispatch(createEntity(formData, tokenData, exitAfter, history)),
//     updateEntityInfo: (formData, tokenData, id) =>
//       dispatch(updateEntityInfo(formData, tokenData, id)),
//     setUpdatedRecentlyToFalse: () => dispatch(setUpdatedRecentlyToFalse()),
//   };
// };

// export default connect(mapStateToProps, mapDispatchToProps)(Add);
