import UserLogPageLayout from "../Components/LogPageLayout";
import { useDispatch } from 'react-redux'
import LoginForm from "../Components/Forms/LoginForm";
import { setCredentials } from '../redux/slice/auth'
import { useLoginMutation } from '../redux/slice/auth/authApiSlice'
import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect } from "react";

export default function Login() {
  const navigate = useNavigate()
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const [signInUser, { data, isLoading, isError, isSuccess }] = useLoginMutation();

  const dispatch = useDispatch()

  let loginClicked = (userCredentials) => {
    signInUser(userCredentials)
  }

  if (isSuccess) {
    dispatch(setCredentials({ data }))
    navigate(from, { replace: true });
  }

  useEffect(() => {
    if (localStorage.access_token) {
      navigate(from, { replace: true });
    }
  }, [from, navigate])

  return (
    <UserLogPageLayout>
      <LoginForm loginClicked={loginClicked} {...{ isLoading, isError, isSuccess }} />
    </UserLogPageLayout>
  );
}

