import DashboardAdd from "../../Components/Layouts/DashboardAdd";
import CommentForm from "../../Components/Forms/CommentForm";
import {
  useCreateCommentMutation,
  useGetCommentByIdQuery,
} from "../../redux/slice/comment/commentApiSlice";
import { useEffect } from "react";
import { message } from "antd";
import useHandleCreateErrorsAndSuccess from "../../hooks/useHandleCreateErrorsAndSuccess";

function Add() {
  const [
    createEntity,
    {
      data: createdCommentData,
      isLoading: creatingComment,
      isError: createCommentError,
      isSuccess: createCommentSuccess,
      error,
    },
  ] = useCreateCommentMutation("createComment");

  let data = {
    data: undefined,
    loading: creatingComment,
    error: createCommentError,
    dataById: undefined,
  };
  const selectedForm = {
    data: undefined,
    loading: creatingComment,
    error: error?.data?.errors,
    loadedRecently: false,
    dataById: undefined,
  };

  useHandleCreateErrorsAndSuccess(error, createCommentSuccess, "Comment");

  return (
    <DashboardAdd>
      <CommentForm
        isAddForm
        {...{
          getEntityById: useGetCommentByIdQuery,
          createEntity,
          data,
          selectedForm,
        }}
      />
    </DashboardAdd>
  );
}

export default Add;
