import DashboardAdd from "../../Components/Layouts/DashboardAdd";
import ReelForm from "../../Components/Forms/ReelForm";
import { useCreateReelMutation } from "../../redux/slice/reel/reelApiSlice";
import { useEffect } from "react";
import { message } from "antd";
import useHandleCreateErrorsAndSuccess from "../../hooks/useHandleCreateErrorsAndSuccess";

function Add() {
  const [
    createEntity,
    {
      data: createdReelData,
      isLoading: creatingReel,
      isError: createReelError,
      isSuccess: createReelSuccess,
      error,
    },
  ] = useCreateReelMutation("createReel");

  let data = {
    data: undefined,
    loading: creatingReel,
    error: createReelError,
    dataById: undefined,
  };
  const selectedForm = {
    data: undefined,
    loading: creatingReel,
    error: error?.data.errors,
    loadedRecently: false,
    dataById: undefined,
  };

  useHandleCreateErrorsAndSuccess(error, createReelSuccess, "Reel");

  return (
    <DashboardAdd>
      <ReelForm
        isAddForm
        {...{
          createEntity,
          data,
          selectedForm,
        }}
      />
    </DashboardAdd>
  );
}

export default Add;
