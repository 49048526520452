import { UploadOutlined } from "@ant-design/icons";
import { Alert, Col, DatePicker, Form, Input, Rate, Row, Select, Spin, Switch, Upload } from "antd";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import slugify from "react-slugify";
import ImageField from "../Core/ImageField";
import TextEditor from "../Core/ReactTinymce/ReactTinymce";
import ImageCrop from "../ImageCrop";

//import "antd/dist/antd.css";
import TextArea from "antd/lib/input/TextArea";
import countryCode from "../../lib/country-code.json";
import { BASE_URL } from "../../lib/urls";
import { useGetPackageListQuery } from "../../redux/slice/package/packageApiSlice";
import { useGetTeamListQuery } from "../../redux/slice/team/teamApiSlice";
import { cleanFormValues } from "../../utils/cleanValues";
import DashboardActionBar from "../DashboardActionBar";

import DraggableMediaManager from "../MediaManager/DraggableMediaManager";

const { Option } = Select;

const TestimonialForm = ({
  selectedForm,
  createEntity,
  updateEntityInfo,
  isAddForm,
  isUpdateForm,
  params,
  setUpdatedRecentlyToFalse,
  data,
}) => {
  const navigate = useNavigate();
  const [review, setReview] = useState(isUpdateForm && data.dataById?.review);
  const [avatar, setAvatar] = useState(
    isUpdateForm ? data.dataById && data.dataById?.avatar && data.dataById?.avatar.full_path : null
  );
  const [bannerImage, setBannerImage] = useState();

  const [selectedBanners, setSelectedBanners] = useState([]);
  const [image_crop_open, setImageCropOpen] = useState(false);

  const [form] = Form.useForm();
  const { setFieldsValue } = form;

  const [ratingValue, setRatingValue] = useState();

  const { data: teamListData, isLoading: teamListLoading } = useGetTeamListQuery("teamList");

  const { data: packageListData, isLoading: packageListLoading } =
    useGetPackageListQuery("packageList");

  const loading = selectedForm.loading || packageListLoading || teamListLoading || data.loading;

  useEffect(() => {
    if (isUpdateForm) {
      let banners = data.dataById?.banners
        ?.map((item) => item.media)
        .filter((item) => item !== null);
      setSelectedBanners(banners);
    }
  }, [data.dataById, isUpdateForm]);

  const countryNameList = countryCode.map((itm) => {
    return {
      label: itm.name,
      value: itm.name,
    };
  });

  const countryCodeList = countryCode.map((itm) => {
    return {
      label: itm.name,
      value: itm.code,
    };
  });

  const packageListOptions = packageListData?.map((itm) => {
    return {
      value: itm.id,
      label: itm.package_title,
    };
  });

  const default_package = {
    value: 0,
    label: "Default Package",
  };

  packageListOptions?.unshift(default_package);

  const teamListOptions = teamListData?.map((itm) => {
    return {
      value: itm.id,
      label: itm.full_name,
    };
  });

  const [reviewTitle, setReviewTitle] = useState();
  const [packageTitle, setPackageTitle] = useState();
  const [reviewDate, setReviewDate] = useState();
  const [reviewMember, setReviewMember] = useState();
  const [country, setCountry] = useState();
  const [fullname, setFullName] = useState();
  const metaTitle = `${reviewTitle} | Reviewed By ${fullname}, ${country}`;
  const metaDiscraption = `Review of Nepal Hiking Team, ${packageTitle}, ${reviewTitle}, Trip did in  ${reviewDate} with ${reviewMember}.`;

  if (reviewTitle && fullname && country) {
    form.setFieldValue("meta_title", metaTitle);
  }
  if (reviewTitle && packageTitle && reviewDate && reviewMember) {
    form.setFieldValue("meta_description", metaDiscraption);
  }
  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  useEffect(() => {
    if (isUpdateForm && data.dataById) {
      setRatingValue(data.dataById?.review_rating);
    }
  }, [data.dataById]);

  if (isUpdateForm) {
    setFieldsValue({
      salutation: data.dataById?.salutation,
      full_name: data.dataById?.full_name,
      url_title: data.dataById?.urlinfo?.url_title,
      review_title: data.dataById?.review_title,
      slug: data.dataById?.urlinfo?.url_slug,
      package_id: data.dataById?.packages?.map((a) => a.id),
      members: data.dataById?.members?.map((a) => a.id),
      review_date: moment(data.dataById?.review_date),
      review_rating: data.dataById?.review_rating,
      reivew_abstract: data.dataById?.reivew_abstract,
      affiliate_organization: data.dataById?.affiliate_organization,
      organization_address: data.dataById?.organization_address,
      organization_position: data.dataById?.organization_position,
      organization_link: data.dataById?.organization_link,
      status: data.dataById?.status === 1 ? true : false,
      is_featured: data.dataById?.is_featured === 1 ? true : false,
      review: data.dataById?.review,
      meta_title: data.dataById?.meta?.meta_title,
      meta_keywords: data.dataById?.meta?.meta_keywords,
      meta_description: data.dataById?.meta?.meta_description,
      meta_review: data.dataById?.meta?.meta_review,
      avatar: data.dataById?.avatar?.thumb_path,
      avatar_alt: data.dataById?.avatar?.avatar_alt,
      avatar_caption: data.dataById?.avatar?.avatar_caption,
      country_code: data.dataById?.country_code,
      country_name: data.dataById?.country_name,
      address: data.dataById?.address,
      review_abstract: data.dataById?.review_abstract,
      banner: data.dataById?.banner?.banner_path,
      banner_alt: data.dataById?.banner?.banner_alt,
      banner_caption: data.dataById?.banner?.banner_caption,
    });
  }

  const userAction = (e, action) => {
    e.preventDefault();
    form.validateFields().then((formValues) => {
      let values = cleanFormValues(formValues);
      values.review_rating = ratingValue;
      values.review_date = formatDate(values.review_date);
      values.country_name = countryCode.filter((a) => a.code === values.country_code)[0].name;
      values.is_featured = values.is_featured ? 1 : 0;
      values.status = values.status ? 1 : 0;
      if (action === "add") {
        if (avatar) {
          axios.get(avatar, { responseType: "blob" }).then((response) => {
            createEntity({
              ...values,
              avatar: response.data,
              ...(bannerImage && { banner_image: bannerImage.file }),
              ...(selectedBanners && {
                banners_media: selectedBanners.map((item) => item.id),
              }),
            });
          });
        } else {
          createEntity({
            ...values,
          });
        }
      } else if (action === "addandexit") {
        if (avatar) {
          axios.get(avatar, { responseType: "blob" }).then((response) => {
            createEntity({
              ...values,
              avatar: response.data,
              ...(bannerImage && { banner_image: bannerImage.file }),
              ...(selectedBanners && {
                banners_media: selectedBanners.map((item) => item.id),
              }),
            })
              .unwrap()
              .then(() => navigate("/testimonials"))
              .catch();
          });
        } else {
          createEntity({
            ...values,
          })
            .unwrap()
            .then(() => navigate("/testimonials"))
            .catch();
        }
      } else if (action === "update") {
        if (avatar) {
          axios.get(avatar, { responseType: "blob" }).then((response) => {
            updateEntityInfo({
              formData: {
                ...values,
                avatar: response.data,
                ...(bannerImage && { banner_image: bannerImage.file }),
                ...(selectedBanners && {
                  banners_media: selectedBanners.map((item) => item.id),
                }),
              },
              id: params.id,
            });
          });
        } else {
          updateEntityInfo({
            formData: {
              ...values,
              ...(bannerImage && { banner_image: bannerImage.file }),
              ...(selectedBanners && {
                banners_media: selectedBanners.map((item) => item.id),
              }),
            },
            id: params.id,
          });
        }
      }
    });
  };

  const addNewEntry = (e) => {
    userAction(e, "add");
  };

  const addNewEntryAndExistToList = (e) => {
    userAction(e, "addandexit");
  };

  const updateExistingEntry = (e) => {
    userAction(e, "update");
  };

  const clearForm = () => {
    form.resetFields();
    setReview("");
    setAvatar("");
  };

  const actionBarOptions = {
    titleToAdd: "Add Testimonial",
    titleToUpdate: "Update Testimonial",
    taskCompleteMessageDisplayTime: 2000,
    addButtonLabel: "Save & Add New",
    updateButtonLabel: "Update Testimonial",
    taskCompleteAddMessage: "Testimonial is added!",
    taskCompleteUpdateMessage: "Testimonial is updated!",
    discardLink: "testimonials",
    pageUrl: isUpdateForm && "review/" + data.dataById?.urlinfo?.url_slug,
    addNewEntry,
    updateExistingEntry,
    loading,
    isAddForm,
    isUpdateForm,
    selectedForm,
    clearForm,
    setUpdatedRecentlyToFalse,
    addNewEntryAndExistToList,
  };

  const desc = ["Terrible", "Poor", "Average", "Very Good", "Excellent"];

  const handleRatingChange = (value) => {
    setRatingValue(value);
  };

  return (
    <>
      <Form
        form={form}
        size="large"
        layout="vertical"
        className="custom-form"
        wrapperCol={{
          flex: 1,
        }}
      >
        <DashboardActionBar {...{ actionBarOptions }} />

        {selectedForm?.error?.errors && (
          <Alert
            className="custom-alert"
            message="Error"
            showIcon
            description={[
              <ul>
                {Object.values(selectedForm?.error).map((itm, idx) => {
                  return <li key={idx}>{itm}</li>;
                })}
              </ul>,
            ]}
            type="error"
            closable
          />
        )}
        <Spin spinning={loading} delay={500}>
          <div className="common-module mt-3 bg-white">
            <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item
                  label="Review Title"
                  name="review_title"
                  rules={[
                    {
                      required: true,
                      message: "title is required",
                    },
                    { min: 5, max: 255, message: "Invalid title" },
                  ]}
                >
                  <Input
                  // onChange={(e) => {
                  //   form.setFieldsValue({ slug: slugify(e.target.value) });
                  //   isAddForm && setReviewTitle(e.target.value);
                  // }}
                  />
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item
                  label="Url Title"
                  name="url_title"
                  rules={[
                    {
                      required: true,
                      message: "Url title is required",
                    },
                    { min: 5, max: 255, message: "Invalid Url title" },
                  ]}
                >
                  <Input
                    onChange={(e) => {
                      form.setFieldsValue({ slug: slugify(e.target.value) });
                      isAddForm && setReviewTitle(e.target.value);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item
                  label={
                    <span className="ant-form-item-optional">Auto Generate from Url Title</span>
                  }
                  name="slug"
                  title="Auto Generate from Url Title"
                  rules={[
                    {
                      required: true,
                      message: "Slug is required",
                    },
                  ]}
                >
                  <Input readOnly />
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Row gutter={{ xs: 2.5, sm: 2.5, md: 2.5 }}>
                  <Col className="gutter-row" xs={6}>
                    <Form.Item
                      label="Full Name"
                      name="salutation"
                      rules={[
                        {
                          required: true,
                          message: "Salutation is required",
                        },
                      ]}
                    >
                      <Select>
                        <Option value="Mr.">Mr.</Option>
                        <Option value="Ms.">Ms.</Option>
                        <Option value="Mrs.">Mrs.</Option>
                        <Option value="Miss.">Miss.</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" xs={18}>
                    <Form.Item
                      label="&nbsp;"
                      name="full_name"
                      className="noStar"
                      rules={[
                        {
                          required: true,
                          message: "Full name is required",
                        },
                        { min: 5, max: 255, message: "Invalid title" },
                      ]}
                    >
                      <Input onChange={(e) => !isUpdateForm && setFullName(e.target.value)} />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Row gutter={{ xs: 2.5, sm: 2.5, md: 2.5 }}>
                  <Col className="gutter-row" xs={10}>
                    <Form.Item
                      label="Address"
                      name="country_code"
                      rules={[
                        {
                          required: true,
                          message: "Select your country",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Country"
                        showSearch
                        onChange={(e) => setCountry(e)}
                        filterOption={(input, option) =>
                          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                        }
                        options={countryCodeList?.map((itm, idx) => {
                          return {
                            value: itm.value,
                            label: itm.label,
                          };
                        })}
                      />
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" xs={14}>
                    <Form.Item
                      label="&nbsp;"
                      name="address"
                      className="noStar"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Address is required",
                      //   },
                      // ]}
                    >
                      <Input placeholder="city, state, zip" />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item label="Abstract" name="reivew_abstract">
                  <Input />
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item label="Select a packages" name="package_id">
                  <Select
                    mode="multiple"
                    showSearch
                    onChange={(e) =>
                      setPackageTitle(
                        packageListOptions?.filter((itm) => itm.value === e[0])[0].label
                      )
                    }
                    filterOption={(input, option) =>
                      (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                    }
                    options={packageListOptions?.map((itm, idx) => {
                      return {
                        value: itm.value,
                        label: itm.label,
                      };
                    })}
                  ></Select>
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item label="Select a Team" name="members">
                  <Select
                    mode="multiple"
                    showSearch
                    onChange={(e) =>
                      setReviewMember(teamListOptions?.filter((itm) => itm.value === e[0])[0].label)
                    }
                    filterOption={(input, option) =>
                      (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                    }
                    options={teamListOptions?.map((itm, idx) => {
                      return {
                        value: itm.value,
                        label: itm.label,
                      };
                    })}
                  />
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item
                  label="Date"
                  name="review_date"
                  rules={[
                    {
                      required: true,
                      message: "Date is required",
                    },
                  ]}
                >
                  <DatePicker
                    disabledDate={(current) => current && current > moment().endOf("day")}
                    format="YYYY-MM-DD"
                    onChange={(date, d) => setReviewDate(moment(d).format("MMM YYYY"))}
                  />
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item label="Credit" name="affiliate_organization">
                  <Select>
                    <Option label="Self" value="self">
                      Self
                    </Option>
                    <Option label="TripAdvisor" value="trip_advisor">
                      Trip Advisor
                    </Option>
                    <Option label="Facebook" value="facebook">
                      Facebook
                    </Option>
                    <Option label="Google" value="google">
                      Google
                    </Option>
                    <Option label="Trustpilot" value="Trust_pilot">
                      Trust Pilot
                    </Option>
                    <Option label="Others" value="others">
                      Others
                    </Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24} sm={12} xl={8} hidden>
                <Form.Item label="Company Address" name="organization_address">
                  <Input />
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24} sm={12} xl={8} hidden>
                <Form.Item label="Designation" name="organization_position">
                  <Input />
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24} sm={12} xl={8} hidden>
                <Form.Item
                  label="Website"
                  name="organization_link"
                  rules={[{ type: "url", message: "Invalid url" }]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24} sm={12} xl={12}>
                <div className="switch-group">
                  <Form.Item label="Ratings" name="review_rating">
                    <span>
                      <Rate tooltips={desc} onChange={handleRatingChange} value={ratingValue} />
                      {ratingValue ? (
                        <span className="ant-rate-text">{desc[ratingValue - 1]}</span>
                      ) : (
                        ""
                      )}
                    </span>
                  </Form.Item>
                  <Form.Item label="Status" valuePropName="checked" name="status">
                    <Switch checkedChildren="Publish" unCheckedChildren="Unpublish" />
                  </Form.Item>
                  <Form.Item label="Is Featured?" valuePropName="checked" name="is_featured">
                    <Switch checkedChildren="Yes" unCheckedChildren="No" />
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </div>
          <div className="common-module pt-0">
            <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
              <Col className="gutter-row" xs={24} xl={16}>
                <Form.Item label="Description" name="review">
                  <TextEditor
                    value={review}
                    initialValue={isUpdateForm && review}
                    onEditorChange={(e) => {
                      setReview(e.target.value);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} xl={8}>
                <label className="form-label">Meta</label>
                <div className="card bg-white mb-4 mb-xl-0">
                  <div className="card-body">
                    <Form.Item
                      label="Meta Title"
                      name="meta_title"
                      rules={[
                        {
                          required: true,
                          message: "Meta title is required",
                        },
                        { min: 5, max: 255, message: "Invalid meta title" },
                      ]}
                    >
                      <Input disabled={isAddForm} />
                    </Form.Item>

                    <Form.Item
                      label="Meta Keywords"
                      name="meta_keywords"
                      rules={[
                        {
                          min: 5,
                          max: 255,
                          message: "Invalid meta keywords",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item className="mb-0" label="Meta Description" name="meta_description">
                      <TextArea rows={8} showCount maxLength={170} disabled={isAddForm} />
                    </Form.Item>
                  </div>
                </div>
              </Col>

              <Col className="gutter-row" xs={24} xl={10}>
                <div className="card bg-white mb-4 mb-xl-0">
                  <div className="card-body">
                    <Form.Item>
                      <ImageField
                        title="Avatar"
                        image={avatar || (isUpdateForm && data.dataById?.avatar?.full_path)}
                        height={150}
                        width={150}
                        setImage={(image_data) => {
                          setAvatar(image_data);
                          setImageCropOpen(true);
                        }}
                      />
                      {avatar && image_crop_open && (
                        <ImageCrop
                          image={avatar}
                          height={150}
                          width={150}
                          setCroppedImage={setAvatar}
                          setImageCropOpen={setImageCropOpen}
                        />
                      )}
                    </Form.Item>
                    <Form.Item
                      style={{ marginTop: "-20px" }}
                      label="Alt Text"
                      name="avatar_alt"
                      rules={[{ min: 5, max: 50, message: "Invalid Alt Text" }]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Caption"
                      name="avatar_caption"
                      rules={[{ min: 5, max: 50, message: "Invalid caption" }]}
                    >
                      <Input />
                    </Form.Item>
                  </div>
                </div>
              </Col>
              <Col className="gutter-row" xs={24} xl={14}>
                <div className="card bg-white mb-4 mb-xl-0">
                  <div className="card-body">
                    <Form.Item name="bannerImage" style={{ marginBottom: "0" }}>
                      <Upload
                        listType="picture-card"
                        maxCount={1}
                        name="tet"
                        beforeUpload={(file) => {
                          return false;
                        }}
                        onChange={(file) => setBannerImage(file)}
                      >
                        {isUpdateForm && data.dataById?.banner?.full_path && !bannerImage ? (
                          <img src={BASE_URL + data.dataById?.banner?.full_path} />
                        ) : (
                          <UploadOutlined style={{ fontSize: "27px" }} />
                        )}
                      </Upload>
                      <span className="text-muted">Size should be 1920x750</span>
                    </Form.Item>
                    <Form.Item
                      label="Alt Text"
                      name="banner_alt"
                      rules={[{ min: 5, max: 50, message: "Invalid Alt Text" }]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Caption"
                      name="banner_caption"
                      rules={[{ min: 5, max: 50, message: "Invalid caption" }]}
                    >
                      <Input />
                    </Form.Item>
                  </div>
                </div>
                <br />
                <div className="card bg-white mb-4 mb-xl-0">
                  <div className="card-body">
                    <h4>Media</h4>
                    <DraggableMediaManager
                      selectedItems={selectedBanners}
                      setSelectedItems={setSelectedBanners}
                      mediaKeyType="article"
                      isMultiple
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Spin>
      </Form>
    </>
  );
};

export default TestimonialForm;
