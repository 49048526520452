import DashboardAdd from "../../Components/Layouts/DashboardAdd";
import StoryForm from "../../Components/Forms/StoryForm";
import { useCreateStoryMutation } from "../../redux/slice/story/storyApiSlice";
import { useEffect } from "react";
import { message } from "antd";
import useHandleCreateErrorsAndSuccess from "../../hooks/useHandleCreateErrorsAndSuccess";

function Add() {
  const [
    createEntity,
    {
      data: createdStoryData,
      isLoading: creatingStory,
      isError: createStoryError,
      isSuccess: createStorySuccess,
      error,
    },
  ] = useCreateStoryMutation("createStory");

  let data = {
    data: undefined,
    loading: creatingStory,
    error: createStoryError,
    dataById: undefined,
  };
  const selectedForm = {
    data: undefined,
    loading: creatingStory,
    error: error?.data.errors,
    loadedRecently: false,
    dataById: undefined,
  };

  useHandleCreateErrorsAndSuccess(error, createStorySuccess, "Story");

  return (
    <DashboardAdd>
      <StoryForm
        isAddForm
        {...{
          createEntity,
          data,
          selectedForm,
        }}
      />
    </DashboardAdd>
  );
}

export default Add;
