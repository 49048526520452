import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import DashboardUpdate from "../../Components/Layouts/DashboardUpdate";
import ReelForm from "../../Components/Forms/ReelForm";
import {
  useGetReelByIdQuery,
  useCreateReelMutation,
  useUpdateReelMutation,
} from "../../redux/slice/reel/reelApiSlice";

function Update() {
  let params = useParams();
  const getEntityById = useGetReelByIdQuery;

  const [
    createEntity,
    {
      data: createdReelData,
      isLoading: creatingReel,
      isError,
      error: createReelError,
    },
  ] = useCreateReelMutation("createReel");

  const [updateReel, { isLoading: updating, isError: updateReelError, error }] =
    useUpdateReelMutation();

  const {
    data: reels,
    isLoading: loadingById,
    isError: updateError,
    isSuccess: successById,
    refetch,
  } = getEntityById(params.id);

  useEffect(() => {
    refetch();
  }, []);

  let data = {
    data: undefined,
    loading: updating || loadingById || updating,
    error: updateError || updateReelError,
    dataById: successById && reels,
  };
  const selectedForm = {
    data: undefined,
    loading: creatingReel || loadingById || updating,
    error: error?.data.errors,
    loadedRecently: false,
    dataById: undefined,
  };

  return (
    <DashboardUpdate>
      <ReelForm
        isUpdateForm
        {...{
          params,
          getEntityById,
          createEntity,
          updateEntityInfo: updateReel,
          data,
          selectedForm,
        }}
      />
    </DashboardUpdate>
  );
}

export default Update;
