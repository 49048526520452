import moment from "moment";
import { apiSlice } from "../../api/apiSlice";
import {
  createQueryForList,
  createQueryById,
  createDeleteRestoreMutation,
} from "../commonFunction";

export const packageApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["Package", "PackageById", "DeletedPackage"],
  endpoints: (builder) => ({
    // getPackageList: createQueryForList(builder, "/travel/package/active", [
    //   "Package",
    // ]),

    getPackageList: builder.query({
      query: () => "/travel/package/active",
      providesTags: ["Package"],
      transformResponse: (response) => response.data,
    }),

    getPackageById: createQueryById(builder, "/travel/package/active", [
      "PackageById",
    ]),

    getDeletedPackageList: createQueryForList(
      builder,
      "/travel/package/deleted",
      ["DeletedPackage"]
    ),

    deletePackage: createDeleteRestoreMutation(
      builder,
      "/travel/package/bulk/delete",
      ["DeletedPackage", "Package"]
    ),

    permanentDeletePackage: createDeleteRestoreMutation(
      builder,
      "/travel/package/bulk/perma-delete",
      ["DeletedPackage"]
    ),

    restorePackageList: createDeleteRestoreMutation(
      builder,
      "/travel/package/bulk/restore",
      ["Package", "DeletedPackage"]
    ),

    sortPackageById: builder.mutation({
      query: (idList) => {
        const webKitFormData = new FormData();
        if (idList.item_array) {
          idList.item_array.forEach((item) => {
            webKitFormData.append("package_array[]", item);
          });
          delete idList.item_array;
        }
        Object.keys(idList).forEach((item) => {
          webKitFormData.append(item, idList[item]);
        });
        return {
          url: "/travel/package/order",
          method: "POST",
          body: webKitFormData,
        };
      },
      invalidatesTags: ["Package"],
    }),

    // sortPackageById: builder.mutation({
    //   query: (idList) => {

    //     let webKitFormData = new FormData();
    //     if (idList.item_array) {
    //       for (let i = 0; i < idList.item_array.length; i++) {
    //         webKitFormData.append("package_array[]", idList.package_array[i]);
    //       }
    //       delete idList.item_array;
    //     }
    //     // ["item_array"].forEach((e) => delete idList[e]);

    //     Object.keys(idList).forEach((item, i) => {
    //       webKitFormData.append(item, idList[item]);
    //     });
    //     console.log("webKitFormData");
    //     return {
    //       url: "/travel/package/order",
    //       method: "POST",
    //       body: webKitFormData,
    //     };
    //   },
    //   invalidatesTags: ["Package"],
    // }),

    createPackage: builder.mutation({
      query: (formData) => {
        let data = Object.fromEntries(
          Object.entries(formData)
            .filter(([_, v]) => v != null)
            .filter(([_, v]) => v !== "")
            .filter(([_, v]) => v !== undefined)
        );

        let webKitFormData = new FormData();

        if (data.categories) {
          for (let i = 0; i < data.categories.length; i++) {
            webKitFormData.append("categories[]", data.categories[i]);
          }
        }
        if (data.custom_related) {
          for (let i = 0; i < data.custom_related.length; i++) {
            webKitFormData.append("custom_related[]", data.custom_related[i]);
          }
        }
        if (data.faqcategories) {
          for (let i = 0; i < data.faqcategories.length; i++) {
            webKitFormData.append("faqcategories[]", data.faqcategories[i]);
          }
        }
        if (data.faqs) {
          for (let i = 0; i < data.faqs.length; i++) {
            webKitFormData.append("faqs[]", data.faqs[i]);
          }
        }
        if (data.group_faqs) {
          for (let i = 0; i < data.group_faqs.length; i++) {
            webKitFormData.append("group_faqs[]", data.group_faqs[i]);
          }
        }
        if (data.trip_info) {
          for (let i = 0; i < data.trip_info.length; i++) {
            webKitFormData.append("trip_info[]", data.trip_info[i]);
          }
        }
        if (data.banners) {
          let bannerImage = data.banners.map((item) => item.banner_image.file);
          let bannerCaption = data.banners.map((item) => item.banner_caption);
          let bannerAlt = data.banners.map((item) => item.banner_alt);
          for (let i = 0; i < bannerImage.length; i++) {
            webKitFormData.append(
              "banners[" + i + "][banner_image]",
              bannerImage[i]
            );
          }
          for (let i = 0; i < bannerCaption.length; i++) {
            webKitFormData.append(
              "banners[" + i + "][banner_caption]",
              bannerCaption[i]
            );
          }
          for (let i = 0; i < bannerAlt.length; i++) {
            webKitFormData.append(
              "banners[" + i + "][banner_alt]",
              bannerAlt[i]
            );
          }
        }
        if (data.facts) {
          let factsTitle = data.facts.map((item) => item.fact_title);
          let factsValue = data.facts.map((item) => item.fact_value);
          for (let i = 0; i < factsTitle.length; i++) {
            webKitFormData.append(
              "facts[" + i + "][fact_title]",
              factsTitle[i]
            );
          }
          for (let i = 0; i < factsValue.length; i++) {
            webKitFormData.append(
              "facts[" + i + "][fact_value]",
              factsValue[i]
            );
          }
        }
        if (data.cost_includes) {
          let includeTitle = data.cost_includes.map(
            (item) => item.detail_title
          );
          let includeKey = data.cost_includes.map((item) => item.detail_key);
          let includeDetail = data.cost_includes.map(
            (item) => item.detail_description
          );
          for (let i = 0; i < includeTitle.length; i++) {
            webKitFormData.append(
              "cost_includes[" + i + "][detail_title]",
              includeTitle[i]
            );
          }
          for (let i = 0; i < includeKey.length; i++) {
            webKitFormData.append(
              "cost_includes[" + i + "][detail_key]",
              includeKey[i]
            );
          }
          for (let i = 0; i < includeDetail.length; i++) {
            webKitFormData.append(
              "cost_includes[" + i + "][detail_description]",
              includeDetail[i]
            );
          }
        }
        if (data.cost_excludes) {
          let excludeTitle = data.cost_excludes.map(
            (item) => item.detail_title
          );
          let excludeKey = data.cost_excludes.map((item) => item.detail_key);
          let excludeDetail = data.cost_excludes.map(
            (item) => item.detail_description
          );

          for (let i = 0; i < excludeTitle.length; i++) {
            webKitFormData.append(
              "cost_excludes[" + i + "][detail_title]",
              excludeTitle[i]
            );
          }
          for (let i = 0; i < excludeKey.length; i++) {
            webKitFormData.append(
              "cost_excludes[" + i + "][detail_key]",
              excludeKey[i]
            );
          }
          for (let i = 0; i < excludeDetail.length; i++) {
            webKitFormData.append(
              "cost_excludes[" + i + "][detail_description]",
              excludeDetail[i]
            );
          }
        }
        if (data.pricegroup) {
          let min_people = data.pricegroup.map((item) => item.min_people);
          let max_people = data.pricegroup.map((item) => item.max_people);
          let unit_price = data.pricegroup.map((item) => item.unit_price);
          let short_description = data.pricegroup.map(
            (item) => item.short_description
          );
          let offer_unit_price = data.pricegroup.map(
            (item) => item.offer_unit_price
          );
          let offer_label = data.pricegroup.map((item) => item.offer_label);
          let offer_information = data.pricegroup.map(
            (item) => item.offer_information
          );
          let is_default = data.pricegroup.map((item) => item.is_default);
          for (let i = 0; i < min_people.length; i++) {
            webKitFormData.append(
              "pricegroup[" + i + "][min_people]",
              min_people[i]
            );
          }
          for (let i = 0; i < max_people.length; i++) {
            webKitFormData.append(
              "pricegroup[" + i + "][max_people]",
              max_people[i]
            );
          }
          for (let i = 0; i < unit_price.length; i++) {
            webKitFormData.append(
              "pricegroup[" + i + "][unit_price]",
              unit_price[i]
            );
          }
          for (let i = 0; i < short_description.length; i++) {
            webKitFormData.append(
              "pricegroup[" + i + "][short_description]",
              (short_description[i] = "")
            );
          }
          for (let i = 0; i < offer_unit_price.length; i++) {
            webKitFormData.append(
              "pricegroup[" + i + "][offer_unit_price]",
              (offer_unit_price[i] = 0)
            );
          }
          for (let i = 0; i < offer_label.length; i++) {
            webKitFormData.append(
              "pricegroup[" + i + "][offer_label]",
              (offer_label[i] = "")
            );
          }
          for (let i = 0; i < offer_information.length; i++) {
            webKitFormData.append(
              "pricegroup[" + i + "][offer_information]",
              (offer_information[i] = "")
            );
          }
          for (let i = 0; i < is_default.length; i++) {
            webKitFormData.append(
              "pricegroup[" + i + "][is_default]",
              is_default[i] === true ? 1 : 0
            );
          }
        }
        if (data.departures) {
          let departureDate = data.departures.map(
            (item) => item.departure_date
          );
          let departureCost = data.departures.map(
            (item) => item.departure_cost
          );
          let departureNote = data.departures.map((itm) => itm.departure_note);
          let departureStatus = data.departures.map(
            (itm) => itm.departure_status
          );
          for (let i = 0; i < departureDate.length; i++) {
            webKitFormData.append(
              "departures[" + i + "][departure_date]",
              moment(departureDate[i]).format("YYYY-MM-DD")
            );
          }
          for (let i = 0; i < departureNote.length; i++) {
            webKitFormData.append(
              "departures[" + i + "][departure_note]",
              departureNote[i]
            );
          }
          for (let i = 0; i < departureStatus.length; i++) {
            webKitFormData.append(
              "departures[" + i + "][departure_status]",
              departureStatus[i]
            );
          }
          for (let i = 0; i < departureCost.length; i++) {
            webKitFormData.append(
              "departures[" + i + "][departure_cost]",
              departureCost[i]
            );
          }
        }

        if (data.extra_faqs) {
          let question = data.extra_faqs.map((item) => item.question);
          let answer = data.extra_faqs.map((item) => item.answer);

          for (let i = 0; i < question.length; i++) {
            webKitFormData.append(
              "extra_faqs[" + i + "][question]",
              question[i]
            );
          }
          for (let i = 0; i < answer.length; i++) {
            webKitFormData.append("extra_faqs[" + i + "][answer]", answer[i]);
          }
        }

        [
          "departures",
          "facts",
          "categories",
          "banners",
          "faqs",
          "pricegroup",
          "trip_info",
          "group_faqs",
          "cost_includes",
          "cost_excludes",
          "faqcategories",
          "extra_faqs",
          "custom_related",
        ].forEach((e) => delete data[e]);

        Object.keys(data).forEach((item, i) => {
          webKitFormData.append(item, data[item]);
        });

        return {
          url: "/travel/package/add",
          method: "POST",
          headers: {
            Accept: "application/json",
          },
          body: webKitFormData,
        };
      },
      invalidatesTags: [{ type: "Package", id: "LIST" }],
    }),

    updatePackageInfo: builder.mutation({
      query: ({ formData, id }) => {
        let data = Object.fromEntries(
          Object.entries(formData)
            .filter(([_, v]) => v != null)
            .filter(([_, v]) => v !== "")
            .filter(([_, v]) => v !== undefined)
        );
        let webKitFormData = new FormData();

        if (data.categories) {
          for (let i = 0; i < data.categories.length; i++) {
            webKitFormData.append("categories[]", data.categories[i]);
          }
        }

        if (data.banners_media) {
          for (let i = 0; i < data.banners_media.length; i++) {
            webKitFormData.append("banners_media[]", data.banners_media[i]);
          }
        }

        if (data.custom_related) {
          for (let i = 0; i < data.custom_related.length; i++) {
            webKitFormData.append("custom_related[]", data.custom_related[i]);
          }
        }
        if (data.faqcategories) {
          for (let i = 0; i < data.faqcategories.length; i++) {
            webKitFormData.append("faqcategories[]", data.faqcategories[i]);
          }
        }
        if (data.faqs) {
          for (let i = 0; i < data.faqs.length; i++) {
            webKitFormData.append("faqs[]", data.faqs[i]);
          }
        }
        if (data.group_faqs) {
          for (let i = 0; i < data.group_faqs.length; i++) {
            webKitFormData.append("group_faqs[]", data.group_faqs[i]);
          }
        }
        if (data.trip_info) {
          for (let i = 0; i < data.trip_info.length; i++) {
            webKitFormData.append("trip_info[]", data.trip_info[i]);
          }
        }
        if (data.banners) {
          let bannerImage = data.banners.map((item) => item.banner_image?.file);
          let bannerCaption = data.banners.map((item) => item.banner_caption);
          let bannerAlt = data.banners.map((item) => item.banner_alt);
          let bannerId = data.banners.map((item) => item.id);

          for (let i = 0; i < bannerImage.length; i++) {
            webKitFormData.append(
              "banners[" + i + "][banner_image]",
              bannerImage[i]
            );
          }

          for (let i = 0; i < bannerCaption.length; i++) {
            webKitFormData.append(
              "banners[" + i + "][banner_caption]",
              bannerCaption[i]
            );
          }
          for (let i = 0; i < bannerAlt.length; i++) {
            webKitFormData.append(
              "banners[" + i + "][banner_alt]",
              bannerAlt[i]
            );
          }
          for (let i = 0; i < bannerId.length; i++) {
            webKitFormData.append("banners[" + i + "][id]", bannerId[i]);
          }
        }
        if (data.facts) {
          let factsTitle = data.facts.map((item) => item.fact_title);
          let factsValue = data.facts.map((item) => item.fact_value);
          let factsId = data.facts.map((item) => item.id);
          for (let i = 0; i < factsTitle.length; i++) {
            webKitFormData.append(
              "facts[" + i + "][fact_title]",
              factsTitle[i]
            );
          }
          for (let i = 0; i < factsValue.length; i++) {
            webKitFormData.append(
              "facts[" + i + "][fact_value]",
              factsValue[i]
            );
          }
          for (let i = 0; i < factsId.length; i++) {
            webKitFormData.append("facts[" + i + "][id]", factsId[i]);
          }
        }
        if (data.cost_includes.length >= 1) {
          let includeTitle = data.cost_includes.map(
            (item) => item.detail_title
          );
          let includeKey = data.cost_includes.map((item) => item.detail_key);
          let includeDetail = data.cost_includes.map(
            (item) => item.detail_description
          );
          let includeId = data.cost_includes.map((item) => item.id);
          for (let i = 0; i < includeTitle.length; i++) {
            webKitFormData.append(
              "cost_includes[" + i + "][detail_title]",
              includeTitle[i]
            );
          }
          for (let i = 0; i < includeKey.length; i++) {
            webKitFormData.append(
              "cost_includes[" + i + "][detail_key]",
              includeKey[i]
            );
          }
          for (let i = 0; i < includeDetail.length; i++) {
            webKitFormData.append(
              "cost_includes[" + i + "][detail_description]",
              includeDetail[i]
            );
          }
          for (let i = 0; i < includeId.length; i++) {
            webKitFormData.append("cost_includes[" + i + "][id]", includeId[i]);
          }
        } else webKitFormData.append("cost_includes", "");
        if (data.cost_excludes.length >= 1) {
          let excludeTitle = data.cost_excludes.map(
            (item) => item.detail_title
          );
          let excludeKey = data.cost_excludes.map((item) => item.detail_key);
          let excludeDetail = data.cost_excludes.map(
            (item) => item.detail_description
          );
          let excludeId = data.cost_excludes.map((item) => item.id);
          for (let i = 0; i < excludeTitle.length; i++) {
            webKitFormData.append(
              "cost_excludes[" + i + "][detail_title]",
              excludeTitle[i]
            );
          }
          for (let i = 0; i < excludeKey.length; i++) {
            webKitFormData.append(
              "cost_excludes[" + i + "][detail_key]",
              excludeKey[i]
            );
          }
          for (let i = 0; i < excludeDetail.length; i++) {
            webKitFormData.append(
              "cost_excludes[" + i + "][detail_description]",
              excludeDetail[i]
            );
          }
          for (let i = 0; i < excludeId.length; i++) {
            webKitFormData.append("cost_excludes[" + i + "][id]", excludeId[i]);
          }
        } else webKitFormData.append("cost_includes", "");
        if (data.pricegroup) {
          let min_people = data.pricegroup.map((item) => item.min_people);
          let max_people = data.pricegroup.map((item) => item.max_people);
          let unit_price = data.pricegroup.map((item) => item.unit_price);
          let short_description = data.pricegroup.map(
            (item) => item.short_description
          );
          let offer_unit_price = data.pricegroup.map(
            (item) => item.offer_unit_price
          );
          let offer_label = data.pricegroup.map((item) => item.offer_label);
          let is_default = data.pricegroup.map((item) => item.is_default);
          let offer_information = data.pricegroup.map(
            (item) => item.offer_information
          );
          let id = data.pricegroup?.map((item) => item.id);
          for (let i = 0; i < min_people.length; i++) {
            webKitFormData.append(
              "pricegroup[" + i + "][min_people]",
              min_people[i]
            );
          }
          for (let i = 0; i < max_people.length; i++) {
            webKitFormData.append(
              "pricegroup[" + i + "][max_people]",
              max_people[i]
            );
          }
          for (let i = 0; i < unit_price.length; i++) {
            webKitFormData.append(
              "pricegroup[" + i + "][unit_price]",
              unit_price[i]
            );
          }
          for (let i = 0; i < short_description.length; i++) {
            webKitFormData.append(
              "pricegroup[" + i + "][short_description]",
              (short_description[i] = "")
            );
          }
          for (let i = 0; i < offer_unit_price.length; i++) {
            webKitFormData.append(
              "pricegroup[" + i + "][offer_unit_price]",
              (offer_unit_price[i] = 0)
            );
          }
          for (let i = 0; i < offer_label.length; i++) {
            webKitFormData.append(
              "pricegroup[" + i + "][offer_label]",
              (offer_label[i] = "")
            );
          }
          for (let i = 0; i < offer_information.length; i++) {
            webKitFormData.append(
              "pricegroup[" + i + "][offer_information]",
              (offer_information[i] = "")
            );
          }
          for (let i = 0; i < is_default.length; i++) {
            webKitFormData.append(
              "pricegroup[" + i + "][is_default]",
              is_default[i] === true ? 1 : is_default[i] === 1 ? 1 : 0
            );
          }
          for (let i = 0; i < id.length; i++) {
            webKitFormData.append("pricegroup[" + i + "][id]", id[i]);
          }
        }

        if (data.departures) {
          let departureDate = data.departures.map(
            (item) => item.departure_date
          );
          let departureCost = data.departures.map(
            (item) => item.departure_cost
          );
          let departureNote = data.departures.map((itm) => itm.departure_note);
          let departureStatus = data.departures.map(
            (itm) => itm.departure_status
          );
          let departureId = data.departures.map((itm) => itm.id);
          for (let i = 0; i < departureDate.length; i++) {
            webKitFormData.append(
              "departures[" + i + "][departure_date]",
              moment(departureDate[i]).format("YYYY-MM-DD")
            );
          }
          for (let i = 0; i < departureNote.length; i++) {
            webKitFormData.append(
              "departures[" + i + "][departure_note]",
              departureNote[i]
            );
          }
          for (let i = 0; i < departureStatus.length; i++) {
            webKitFormData.append(
              "departures[" + i + "][departure_status]",
              departureStatus[i]
            );
          }
          for (let i = 0; i < departureCost.length; i++) {
            webKitFormData.append(
              "departures[" + i + "][departure_cost]",
              departureCost[i]
            );
          }
          for (let i = 0; i < departureId.length; i++) {
            webKitFormData.append("departures[" + i + "][id]", departureId[i]);
          }
        }

        if (data.extra_faqs) {
          let question = data.extra_faqs.map((item) => item.question);
          let answer = data.extra_faqs.map((item) => item.answer);
          let extraFaqsId = data.extra_faqs.map((itm) => itm.id);

          for (let i = 0; i < question.length; i++) {
            webKitFormData.append(
              "extra_faqs[" + i + "][question]",
              question[i]
            );
          }
          for (let i = 0; i < answer.length; i++) {
            webKitFormData.append("extra_faqs[" + i + "][answer]", answer[i]);
          }
          for (let i = 0; i < extraFaqsId.length; i++) {
            webKitFormData.append("extra_faqs[" + i + "][id]", extraFaqsId[i]);
          }
        }

        [
          "departures",
          "facts",
          "categories",
          "banners",
          "faqs",
          "pricegroup",
          "trip_info",
          "group_faqs",
          "cost_includes",
          "cost_excludes",
          "faqcategories",
          "extra_faqs",
          "custom_related",
          "banners_media",
        ].forEach((e) => delete data[e]);

        Object.keys(data).forEach((item, i) => {
          webKitFormData.append(item, data[item]);
        });
        webKitFormData.append("_method", "put");

        return {
          url: `/travel/package/edit/${id}`,
          method: "POST",
          headers: {
            Accept: "application/json",
          },
          body: webKitFormData,
        };
      },
      invalidatesTags: ["Package", "PackageById"],
    }),
  }),
});
export const {
  useGetPackageListQuery,
  useGetPackageByIdQuery,
  useGetDeletedPackageListQuery,
  useDeletePackageMutation,
  usePermanentDeletePackageMutation,
  useRestorePackageListMutation,
  useSortPackageByIdMutation,
  useCreatePackageMutation,
  useUpdatePackageInfoMutation,
} = packageApiSlice;
