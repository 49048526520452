import { Alert, Col, Form, Input, Row, Select, Spin, Switch } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import slugify from "react-slugify";
import ImageField from "../Core/ImageField";
import TextEditor from "../Core/ReactTinymce/ReactTinymce";
import ImageCrop from "../ImageCrop";

import TextArea from "antd/lib/input/TextArea";
import { cleanFormValues } from "../../utils/cleanValues";
import DashboardActionBar from "../DashboardActionBar";

const { Option } = Select;

const StoryAuthorForm = ({
  selectedForm,
  createEntity,
  updateEntityInfo,
  isAddForm,
  isUpdateForm,
  params,
  data,
}) => {
  const navigate = useNavigate();
  const [description, setDescription] = useState(isUpdateForm && data.dataById?.description);
  const [avatar, setAvatar] = useState(
    isUpdateForm ? data.dataById && data.dataById.avatar && data.dataById.avatar.full_path : null
  );
  const [image_crop_open, setImageCropOpen] = useState(false);
  const [form] = Form.useForm();
  const { setFieldsValue } = form;

  const loading = selectedForm.loading || data.loading;

  if (isUpdateForm) {
    setFieldsValue({
      name: data.dataById?.name,
      url_title: data.dataById?.urlinfo?.url_title,
      slug: data.dataById?.urlinfo?.url_slug,
      salutation: data.dataById?.salutation,
      email: data.dataById?.email,
      status: data.dataById?.status === 1 ? true : false,
      is_featured: data.dataById?.is_featured === 1 ? true : false,
      description: data.dataById?.description,
      meta_title: data.dataById?.meta?.meta_title,
      meta_keywords: data.dataById?.meta?.meta_keywords,
      meta_description: data.dataById?.meta?.meta_description,
      avatar: data.dataById?.avatar?.thumb_path,
      avatar_alt: data.dataById?.avatar?.alt_text,
      avatar_caption: data.dataById?.avatar?.caption,
    });
  }

  const authorAction = (e, action) => {
    e.preventDefault();
    form.validateFields().then((formValues) => {
      let values = cleanFormValues(formValues);
      values.status = values.status ? 1 : 0;

      if (action === "add") {
        if (avatar) {
          axios.get(avatar, { responseType: "blob" }).then((response) => {
            createEntity({
              ...values,
              avatar: response.data,
            });
          });
        } else {
          createEntity({
            ...values,
          });
        }
      } else if (action === "addandexit") {
        if (avatar) {
          axios.get(avatar, { responseType: "blob" }).then((response) => {
            createEntity({
              ...values,
              avatar: response.data,
            })
              .unwrap()
              .the(() => navigate("/story-authors"))
              .catch();
          });
        } else {
          createEntity({
            ...values,
          });
        }
      } else if (action === "update") {
        if (avatar) {
          axios.get(avatar, { responseType: "blob" }).then((response) => {
            updateEntityInfo({
              formData: {
                ...values,
                avatar: response.data,
              },
              id: params.id,
            });
          });
        } else {
          updateEntityInfo({
            formData: {
              ...values,
            },
            id: params.id,
          });
        }
      }
    });
  };

  const addNewEntry = (e) => {
    authorAction(e, "add");
  };

  const addNewEntryAndExistToList = (e) => {
    authorAction(e, "addandexit");
  };

  const updateExistingEntry = (e) => {
    authorAction(e, "update");
  };

  const clearForm = () => {
    form.resetFields();
    setDescription("");
    setAvatar("");
  };

  const actionBarOptions = {
    titleToAdd: "Story Author",
    titleToUpdate: "Update Author",
    taskCompleteMessageDisplayTime: 2000,
    addButtonLabel: "Save & Add New",
    updateButtonLabel: "Update Author",
    taskCompleteAddMessage: "Author is added!",
    taskCompleteUpdateMessage: "Author is updated!",
    discardLink: "story-authors",
    //pageUrl: 'author/'+data.dataById?.urlinfo?.url_slug,
    addNewEntry,
    updateExistingEntry,
    loading,
    isAddForm,
    isUpdateForm,
    selectedForm,
    clearForm,
    addNewEntryAndExistToList,
  };

  return (
    <>
      <Form
        form={form}
        size="large"
        layout="vertical"
        className="custom-form"
        wrapperCol={{
          flex: 1,
        }}
      >
        <DashboardActionBar {...{ actionBarOptions }} />

        {selectedForm?.error?.errors && (
          <Alert
            className="custom-alert"
            message="Error"
            showIcon
            description={[
              <ul>
                {Object.values(selectedForm.error.errors).map((itm, idx) => {
                  return <li key={idx}>{itm}</li>;
                })}
              </ul>,
            ]}
            type="error"
            closable={500}
          />
        )}
        <Spin spinning={loading} delay={500}>
          <div className="common-module bg-white mt-3">
            <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
              <Col className="gutter-row" xs={24} sm={16} xl={16}>
                <Row gutter={{ xs: 2.5, sm: 2.5, md: 2.5 }}>
                  <Col className="gutter-row" xs={6}>
                    <Form.Item
                      label="Full Name"
                      name="salutation"
                      rules={[
                        {
                          required: true,
                          message: "Salutation is required",
                        },
                      ]}
                    >
                      <Select>
                        <Option value="Mr.">Mr.</Option>
                        <Option value="Ms.">Ms.</Option>
                        <Option value="Mrs.">Mrs.</Option>
                        <Option value="Miss.">Miss.</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" xs={18}>
                    <Form.Item
                      label="&nbsp;"
                      name="name"
                      className="noStar"
                      rules={[
                        {
                          required: true,
                          message: "Full name is required",
                        },
                        { min: 5, max: 255, message: "Invalid title" },
                      ]}
                    >
                      <Input
                        onChange={(e) =>
                          form.setFieldsValue({
                            slug: slugify(e.target.value),
                            url_title: e.target.value,
                            meta_title: e.target.value,
                          })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

              <Col className="gutter-row" xs={24} sm={16} xl={16} hidden>
                <Form.Item
                  label="Url Title"
                  name="url_title"
                  rules={[
                    {
                      required: true,
                      message: "Url title is required",
                    },
                    { min: 5, max: 255, message: "Invalid Url title" },
                  ]}
                >
                  <Input
                  // onChange={(e) =>
                  //   form.setFieldsValue({ slug: slugify(e.target.value) })
                  // }
                  />
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24} sm={16} xl={8} hidden>
                <Form.Item
                  label={
                    <span className="ant-form-item-optional">Auto Generate from Url Title</span>
                  }
                  name="slug"
                  title="Auto Generate from Url Title"
                  rules={[
                    {
                      required: true,
                      message: "Slug is required",
                    },
                  ]}
                >
                  <Input readOnly />
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24} sm={16} xl={16}>
                <Form.Item
                  label="E-mail"
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                    {
                      required: true,
                      message: "Please input your E-mail!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24} sm={16} xl={16}>
                <div className="switch-group">
                  <Form.Item label="Status" valuePropName="checked" name="status">
                    <Switch checkedChildren="Publish" unCheckedChildren="Unpublish" />
                  </Form.Item>
                </div>
              </Col>

              <Col className="gutter-row" xs={24} xl={16} hidden>
                <Form.Item label="Description" name="description">
                  <TextEditor
                    value={description}
                    initialValue={isUpdateForm && description}
                    onEditorChange={(e) => {
                      setDescription(e.target.value);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} xl={16} hidden>
                <label className="form-label">Meta</label>
                <div className="card bg-white mb-4 mb-xl-0">
                  <div className="card-body">
                    <Form.Item
                      label="Meta Title"
                      name="meta_title"
                      rules={[
                        {
                          required: true,
                          message: "Meta title is required",
                        },
                        { min: 5, max: 255, message: "Invalid meta title" },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      label="Meta Keywords"
                      name="meta_keywords"
                      rules={[
                        {
                          min: 5,
                          max: 255,
                          message: "Invalid meta keywords",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item className="mb-0" label="Meta Description" name="meta_review">
                      <TextArea rows={8} />
                    </Form.Item>
                  </div>
                </div>
              </Col>

              <Col className="gutter-row" xs={24} xl={16}>
                <div className="card bg-white mb-4 mb-xl-0">
                  <div className="card-body">
                    <Form.Item>
                      <ImageField
                        title="Avatar"
                        image={avatar || (isUpdateForm && data.dataById?.avatar?.full_path)}
                        height={150}
                        width={150}
                        setImage={(image_data) => {
                          setAvatar(image_data);
                          setImageCropOpen(true);
                        }}
                      />
                      {avatar && image_crop_open && (
                        <ImageCrop
                          height={150}
                          width={150}
                          image={avatar}
                          setCroppedImage={setAvatar}
                          setImageCropOpen={setImageCropOpen}
                        />
                      )}
                    </Form.Item>
                    <Form.Item
                      style={{ marginTop: "-20px" }}
                      label="Alt Text"
                      name="avatar_alt"
                      rules={[{ min: 5, max: 50, message: "Invalid Alt Text" }]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Caption"
                      name="avatar_caption"
                      rules={[{ min: 5, max: 50, message: "Invalid caption" }]}
                    >
                      <Input />
                    </Form.Item>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Spin>
      </Form>
    </>
  );
};

export default StoryAuthorForm;
