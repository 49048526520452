import { apiSlice } from "../../api/apiSlice";
import {
  createQueryForList,
  createQueryById,
  createDeleteRestoreMutation,
} from "../commonFunction";

export const storyAuthorApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["StoryAuthor", "StoryAuthorById", "DeletedStoryAuthor"],
  endpoints: (builder) => ({
    getStoryAuthorList: createQueryForList(
      builder,
      "/travel/story/author/active",
      ["StoryAuthor"]
    ),

    getStoryAuthorById: createQueryById(
      builder,
      "/travel/story/author/active",
      ["StoryAuthorById"]
    ),

    getDeletedStoryAuthorList: createQueryForList(
      builder,
      "/travel/story/author/deleted",
      ["DeletedStoryAuthor"]
    ),

    deleteStoryAuthor: createDeleteRestoreMutation(
      builder,
      "/travel/story/author/bulk/delete",
      ["StoryAuthor", "DeletedStoryAuthor"]
    ),

    permanentDeleteStoryAuthor: createDeleteRestoreMutation(
      builder,
      "/travel/story/author/bulk/perma-delete",
      ["DeletedStoryAuthor"]
    ),

    restoreStoryAuthorList: createDeleteRestoreMutation(
      builder,
      "/travel/story/author/bulk/restore",
      ["StoryAuthor", "DeletedStoryAuthor"]
    ),

    createStoryAuthor: builder.mutation({
      query: (formData) => {
        let data = Object.fromEntries(
          Object.entries(formData)
            .filter(([_, v]) => v != null)
            .filter(([_, v]) => v !== "")
            .filter(([_, v]) => v !== undefined)
        );
        let webKitFormData = new FormData();
        Object.keys(data).forEach((item, i) => {
          webKitFormData.append(item, data[item]);
        });

        return {
          url: "/travel/story/author/add",
          method: "POST",
          headers: {
            Accept: "application/json",
          },
          body: webKitFormData,
        };
      },
      invalidatesTags: ["StoryAuthor"],
    }),

    updateStoryAuthor: builder.mutation({
      query: ({ formData, id }) => {
        let data = Object.fromEntries(
          Object.entries(formData)
            .filter(([_, v]) => v != null)
            .filter(([_, v]) => v !== "")
            .filter(([_, v]) => v !== undefined)
        );

        let webKitFormData = new FormData();
        if (data.avatar && typeof data.avatar === "string") {
          delete data.avatar;
        } else if (data.avatar && typeof data.avatar === "object") {
          Object.keys(data).forEach((item, i) => {
            webKitFormData.append(item, data[item]);
          });
          webKitFormData.append("_method", "put");
        }

        return {
          url: `/travel/story/author/edit/${id}`,
          method: "POST",
          body:
            typeof formData.avatar === "object"
              ? webKitFormData
              : { ...formData, _method: "put" },
        };
      },
      invalidatesTags: ["StoryAuthor", "StoryAuthorById"],
    }),
  }),
});
export const {
  useGetStoryAuthorListQuery,
  useGetStoryAuthorByIdQuery,
  useGetDeletedStoryAuthorListQuery,
  useDeleteStoryAuthorMutation,
  usePermanentDeleteStoryAuthorMutation,
  useRestoreStoryAuthorListMutation,
  useCreateStoryAuthorMutation,
  useUpdateStoryAuthorMutation,
} = storyAuthorApiSlice;
