import DashboardList from "../../Components/Layouts/DashboardList";
import {
  useGetReelListQuery,
  useDeleteReelMutation,
} from "../../redux/slice/reel/reelApiSlice";

function List() {
  const [onDelete] = useDeleteReelMutation("deleteEntityById");

  const tableConfig = {
    title: "Reels",
    filterOptionTitle: "Reels Type",
    filterOptions: ["package"],
    defaultFilterValue: "admin",
    applyFilter: false,
    tableColumns: [
      { name: "Title", selector: "caption", width: "77%" },
      { name: "Status", selector: "publish_status", width: "3%" },
      // { name: "Order", selector: "order", width: "3%" },
      { name: "Modified Date", selector: "updated_at", width: "15%" },
      { name: "ID", selector: "id", width: "5%" },
    ],
    filterByColumn: "caption",
    searchByColumn: "caption",
    updatePath: "/reel/update",
    addPath: "/reel/add",
    noDataMessage: "No reel exists",
    updateLinkColumn: "caption",
    deleteBtnText: "Delete",
    deleteItemsByIdList: (idList) => {
      onDelete(idList);
    },
  };

  return (
    <DashboardList getList={useGetReelListQuery()} tableConfig={tableConfig} />
  );
}

export default List;
