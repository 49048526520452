import { useParams } from "react-router-dom";
import { useEffect } from "react";

import DashboardUpdate from "../../Components/Layouts/DashboardUpdate";
import DepartureForm from "../../Components/Forms/DepartureForm";
import {
  useCreateDepartureMutation,
  useDeleteDepartureMutation,
  useGetDepartureByPackageIdQuery,
  useUpdateDepartureInfoMutation,
} from "../../redux/slice/departure/departureApiSlice";
import { message } from "antd";

function Update() {
  let params = useParams();
  const getEntityById = useGetDepartureByPackageIdQuery;
  const [onDelete] = useDeleteDepartureMutation("deleteEntityById");

  const [
    createEntity,
    {
      data: createdDepartureData,
      isLoading: creatingDeparture,
      isError: createDepartureError,
      isSuccess: createDepartureSuccess,
      error: errorCreate,
    },
  ] = useCreateDepartureMutation("createDeparture");

  const [
    updateDeparture,
    {
      data: updatedDeparture,
      isLoading: updating,
      isError: updateDepartureError,
      isSuccess: updateSuccess,
      error,
    },
  ] = useUpdateDepartureInfoMutation("updateDeparture");

  const {
    data: Departures,
    isLoading: loadingById,
    isError: updateError,
    isSuccess: successById,
    refetch,
  } = getEntityById(params.id);

  useEffect(() => {
    refetch();
  }, []);

  let data = {
    data: undefined,
    loading: updating || loadingById || creatingDeparture,
    error: updateError || updateDepartureError || createDepartureError,
    dataById: successById && Departures,
  };
  const selectedForm = {
    data: undefined,
    loading: updating || loadingById || creatingDeparture,
    error: createDepartureError
      ? errorCreate?.data?.errors
      : error?.data?.errors,
    loadedRecently: false,
    dataById: undefined,
  };

  return (
    <DashboardUpdate>
      <DepartureForm
        isUpdateForm
        {...{
          createEntity,
          params,
          getEntityById,
          updateEntityInfo: updateDeparture,
          data,
          selectedForm,
          onDelete,
        }}
      />
    </DashboardUpdate>
  );
}

export default Update;
