import React, { useState, useEffect } from "react";
import DashboardLayout from "../../Components/Layouts/DashboardLayout";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import Avatar from "../../Components/Core/Upload/Avatar";

import { Form, Input, Select, DatePicker, InputNumber, Spin } from "antd";
import TextArea from "antd/lib/input/TextArea";
//tabs
import { Tabs, Tab } from "react-bootstrap";
import moment from "moment";
import { BASE_URL } from "../../lib/urls";
import {
  useDeleteSettingByIdMutation,
  useGetSettingListQuery,
  useUpdateSettingByIdMutation,
} from "../../redux/slice/settings/settingsApiSlice";

const { Option } = Select;

function List() {
  const [image, setImage] = useState();

  const [form] = Form.useForm();
  const { validateFields, setFieldsValue, getFieldValue } = form;

  const { data: listData, isLoading: listLoading } =
    useGetSettingListQuery("settingList");

  const [updateEntityById, { isLoading: updating }] =
    useUpdateSettingByIdMutation("udpateSettingById");

  const [onDelete, { isLoading: deleting }] =
    useDeleteSettingByIdMutation("deleteSetting");

  let loading = listLoading || updating || deleting;
  const ValueType = (props) => {
    switch (props.types) {
      case "string":
        return <Input {...props} />;
      case "text":
        return <TextArea {...props}></TextArea>;
      case "integer":
        return <InputNumber {...props} />;
      case "decimal":
        return <InputNumber {...props} />;
      case "file":
        return <input type="file" {...props} />;
      case "image":
        return <input type="file" {...props} accept="image/*" />;
      case "date":
        return <DatePicker {...props} />;
    }
  };

  const SettingsComponents = ({ data, idx }) => {
    const {
      id,
      key_label,
      value_type,
      global_key,
      setting_key,
      setting_value,
      description,
      updated_at,
    } = data;

    const handleOnchanged = (values) => {
      updateEntityById({ ...values });
    };

    return (
      <li key={idx}>
        <div className="col-2">
          <h4>{key_label}</h4>
          {description !== "undefined" && (
            <span className="small">{description}</span>
          )}
        </div>
        <div className="col-6">
          <div className="field-wrapper custom-form">
            {value_type === "image" ? (
              <>
                <label htmlFor={global_key} className="avatar">
                  {setting_value ? (
                    <img src={BASE_URL + "/" + setting_value} />
                  ) : (
                    <>
                      <i className="bi-plus"></i>
                      <span>Upload</span>
                    </>
                  )}
                </label>
                <input
                  id={global_key}
                  type="file"
                  accept="image/*"
                  onChange={(e) =>
                    handleOnchanged({
                      setting_value: e.target.files[0],
                      setting_key: global_key,
                    })
                  }
                />
              </>
            ) : (
              <ValueType
                types={value_type}
                validateStatus="validating"
                defaultValue={
                  value_type === "date" ? moment(setting_value) : setting_value
                }
                onBlur={(e) =>
                  handleOnchanged({
                    setting_value: e.target.value,
                    setting_key: global_key,
                  })
                }
                onPressEnter={(e) =>
                  handleOnchanged({
                    setting_value: e.target.value,
                    setting_key: global_key,
                  })
                }
              />
            )}
          </div>
        </div>
        <div className="col-2 update">
          <span className="badge bg-light-warning rounded-pill">
            <Moment fromNow ago>
              {updated_at}
            </Moment>{" "}
            &nbsp;ago
          </span>
        </div>
        <div className="col-2 action">
          <Link
            to={`/setting/update/${id}`}
            type="button"
            className="btn btn-link edit"
          >
            <i className="bi-pencil-square"></i>
          </Link>
          <button
            type="button"
            className="btn btn-link delete"
            onClick={() => onDelete(id)}
          >
            <i className="bi-trash"></i>
          </button>
        </div>
      </li>
    );
  };

  const companySettingsData = listData?.filter(
    (itm) => itm.key_group === "company"
  );
  const imagesSettingsData = listData?.filter(
    (itm) => itm.key_group === "images"
  );
  const advanceSettingsData = listData?.filter(
    (itm) => itm.key_group === "advanced"
  );

  return (
    <DashboardLayout>
      <div className="page-content">
        <div className="container-fluid">
          <div className="common-module">
            <div className="action-bar">
              <div className="title">
                <h1>Settings</h1>
              </div>
              <div className="action-slot">
                <ul>
                  <li>
                    <Link
                      to="/setting/add"
                      className="btn btn-primary"
                      id="add_new"
                    >
                      <i className="bi-plus-circle"></i> Add New
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="common-module mb-2">
            <Spin spinning={loading} delay={500}>
              <Tabs
                defaultActiveKey="site"
                transition={false}
                id="noanim-tab-example"
                className="mb-3 custom-tab"
              >
                <Tab eventKey="site" title="Site Settings">
                  <div className="settings-list">
                    <ul>
                      {companySettingsData &&
                      companySettingsData.length >= 1 ? (
                        companySettingsData?.map((itm, idx) => {
                          return <SettingsComponents key={idx} data={itm} />;
                        })
                      ) : loading === false ? (
                        <div className="px-3">No data exists!</div>
                      ) : (
                        ""
                      )}
                    </ul>
                  </div>
                </Tab>
                <Tab eventKey="images" title="Images">
                  <div className="settings-list">
                    <ul>
                      {imagesSettingsData && imagesSettingsData.length >= 1 ? (
                        imagesSettingsData.map((itm, idx) => {
                          return <SettingsComponents key={idx} data={itm} />;
                        })
                      ) : loading === false ? (
                        <div className="px-3">No data exists!</div>
                      ) : (
                        ""
                      )}
                    </ul>
                  </div>
                </Tab>
                <Tab eventKey="advance" title="Advance Settings">
                  <div className="settings-list">
                    <ul>
                      {advanceSettingsData &&
                      advanceSettingsData.length >= 1 ? (
                        advanceSettingsData.map((itm, idx) => {
                          return <SettingsComponents key={idx} data={itm} />;
                        })
                      ) : loading === false ? (
                        <div className="px-3">No data exists!</div>
                      ) : (
                        ""
                      )}
                    </ul>
                  </div>
                </Tab>
              </Tabs>
            </Spin>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default List;
