import React, { useContext, useTransition } from "react";
import { Link, useNavigate } from "react-router-dom";
import GlobalContext from "../utils/GlobalContext";
import { logOut as clearToken, selectCurrentToken } from "../redux/slice/auth";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import axios from "axios";

export default function Toolbar({ toggleSidebar, sidebarOpen }) {
  const globalData = useContext(GlobalContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector(selectCurrentToken) || localStorage.getItem("access_token");

  const handleLogout = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}api/v1/admin/auth/logout`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Logout successful:", response.data);
    } catch (err) {
      message.error("Failed to logout");
      console.error("Failed to logout:", err);
    } finally {
      dispatch(clearToken());
      navigate("/login");
    }
  };

  return (
    <aside className="toolbar dark">
      <div className="toolbar-container">
        <nav>
          <ul>
            <li>
              <Link
                className={`sidebar-toggler ${sidebarOpen ? " in " : ""}`}
                onClick={() => toggleSidebar()}
              >
                <div className="toggle-btn">
                  <span>Menu</span>
                </div>
              </Link>
            </li>
            <li>
              <Link to="../">
                <i className="bi bi-house-door"></i>
              </Link>
            </li>
            <li>
              <Link to="/redirection">
                <i className="bi-box-arrow-up-right"></i>
              </Link>
            </li>
          </ul>
        </nav>
        <nav>
          <ul>
            <li className="user-profile active">
              <Link to="/">
                <img
                  src={
                    globalData?.avatar ? process.env.REACT_APP_IMAGE_URL + globalData?.avatar : null
                  }
                  alt={process.env.REACT_APP_COMPANY_NAME}
                />
              </Link>
            </li>
            {localStorage.admin_type === "super" && (
              <li>
                <Link to="/settings">
                  <i className="bi bi-gear"></i>
                </Link>
              </li>
            )}
            <li>
              <a href="#">
                <i className="bi bi-bell"></i>
              </a>
            </li>
            <li>
              <Link onClick={handleLogout}>
                <i className="bi bi-power"></i>
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </aside>
  );
}
