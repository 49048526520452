import { VerticalAlignMiddleOutlined } from "@ant-design/icons";
import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Button, message, Modal, Popconfirm, Space, Spin, Table, TreeSelect } from "antd";
import React, { useContext, useEffect, useMemo, useState } from "react";
import Moment from "react-moment";
import { Link, useNavigate } from "react-router-dom";

const { TreeNode } = TreeSelect;

const RowContext = React.createContext({});

const DragHandle = () => {
  const { setActivatorNodeRef, listeners } = useContext(RowContext);
  return (
    <Button
      type="text"
      size="large"
      icon={<VerticalAlignMiddleOutlined />}
      style={{
        cursor: "grab",
        color: "#999",
      }}
      ref={setActivatorNodeRef}
      {...listeners}
    />
  );
};

const Row = (props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props["data-row-key"],
  });
  const style = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    ...(isDragging
      ? {
          position: "relative",
          zIndex: 9999,
        }
      : {}),
  };
  const contextValue = useMemo(
    () => ({
      setActivatorNodeRef,
      listeners,
    }),
    [setActivatorNodeRef, listeners]
  );
  return (
    <RowContext.Provider value={contextValue}>
      <tr {...props} ref={setNodeRef} style={style} {...attributes} />
    </RowContext.Provider>
  );
};

const Status = ({ id, status_checked }) => (
  <div className="status">
    <span className="form-check">
      <input
        className="form-check-input"
        type="checkbox"
        checked={status_checked === 0 ? false : true}
        id={id}
        onChange={() => {}}
      />
      <label className="form-check-label" htmlFor={id}></label>
    </span>
  </div>
);

const FilterComponent = ({ searchText, onSearch, searchByColumn }) => (
  <div>
    <div className="input-search">
      <input
        id="search"
        className="form-control"
        type="text"
        placeholder={`Search By Title...`}
        aria-label="Search Input"
        value={searchText}
        onChange={onSearch}
      />
    </div>
  </div>
);

const Sorting = ({
  searchText,
  setSearchText,
  setFilter,
  filterText,
  filterOptionTitle,
  filterOptions,
  searchByColumn,
  applyFilter,
}) => {
  const handleClear = () => {
    if (searchText) {
      setSearchText("");
    }
  };

  return (
    <>
      {applyFilter && (
        <div className="category-sorting">
          <label htmlFor="catlist">{filterOptionTitle}:</label>
          <div className="custom_select">
            <TreeSelect
              showSearch
              style={{ width: "100%" }}
              dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
              placeholder="Please select"
              allowClear
              treeDefaultExpandAll
              onSelect={(e) => {
                setFilter(e);
              }}
              value={filterText}
            >
              <TreeNode value="all" title="All" />
              {filterOptions?.map((parent) => {
                const { value, text, children } = parent;
                return (
                  <TreeNode key={value} value={value} title={text}>
                    {children?.length !== 0 &&
                      children?.map((child) => {
                        const { value, text, children } = child;
                        return (
                          <TreeNode key={value} value={value} title={text}>
                            {children?.length !== 0 &&
                              children?.map((gChild) => {
                                const { value, text } = gChild;
                                return <TreeNode key={value} value={value} title={text} />;
                              })}
                          </TreeNode>
                        );
                      })}
                  </TreeNode>
                );
              })}
            </TreeSelect>
          </div>
        </div>
      )}

      <FilterComponent
        onSearch={(e) => setSearchText(e.target.value)}
        onClear={handleClear}
        searchText={searchText}
        searchByColumn={searchByColumn}
      />
    </>
  );
};

const DraggableDataTable = ({
  title,
  filterOptionTitle,
  updateLinkColumn,
  filterOptions,
  tableData,
  tableColumns,
  searchByColumn,
  filterByColumn,
  applyFilter,
  updatePath,
  updateParentPath,
  addPath,
  addTypePath,
  addTypeText,
  restorePath,
  dataToList,
  deleteBtnText,
  deleteItemsByIdList,
  restoreItemsByIdList,
  sortItemsByIdList,
  is_booking,
  is_inquiry,
  is_menu,
  is_payment,
  sendEmailByBookingId,
}) => {
  const [dataSource, setDataSource] = useState(tableData);
  const [selectedItems, setSelectedItems] = useState();
  const [searchText, setSearchText] = useState("");
  const [filterText, setFilter] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    let filteredData = tableData;
    if (filterText) {
      if (filterText === "all") {
        filteredData = tableData;
      } else if (title === "Packages") {
        filteredData = filteredData.filter(
          (item) => item[filterByColumn] && item[filterByColumn].includes(filterText)
        );
      } else {
        filteredData = filteredData.filter((item) => item[filterByColumn] === filterText);
      }
    }

    if (searchText) {
      filteredData = filteredData.filter((item) => {
        return (
          item[searchByColumn] &&
          item[searchByColumn].toString().toLowerCase().includes(searchText.toLowerCase())
        );
      });
    }

    setDataSource(filteredData);
  }, [tableData, searchText, filterText, title, filterByColumn, searchByColumn]);

  const handleCopy = (url) => {
    navigator.clipboard.writeText(url);
  };

  const handleOrderChild = (record) => {
    setDataSource(record.children);
  };

  const columns = tableColumns?.map((x) => {
    return {
      title: x.name,
      dataIndex: x.selector,
      width: x.width,
      ...(x.name === "Order" && {
        render: () => <DragHandle />,
        className: "drag-visible",
      }),
      ...(x.name === "Status" && { className: "drag-visible" }),
      ...(is_booking &&
        x.name === "operation" && {
          title: "Links",
          dataIndex: "operation",
          render: (_, record) =>
            data.length >= 1 ? (
              <Space size="middle">
                <Popconfirm
                  title="Copy to Clipboard?"
                  onConfirm={() => handleCopy(record.travellerinfo_url)}
                >
                  <span className="btn btn-sm btn-primary">
                    <i className="bi-clipboard-check" /> Copy
                  </span>
                </Popconfirm>
                {/* <a
                  className="btn btn-sm btn-warning"
                  onClick={() => {
                    setModalData(record);
                    setModalOpen(true);
                  }}
                >
                  <i className="bi-eye-fill" /> Invoice
                </a> */}
              </Space>
            ) : null,
        }),
      ...(is_payment &&
        x.name === "operation" && {
          title: "Urls",
          dataIndex: "operation",
          render: (_, record) =>
            data.length >= 1 ? (
              <Space size="middle">
                <Popconfirm
                  title="Copy to Clipboard?"
                  onConfirm={() => handleCopy(record.checkout_url)}
                >
                  <span className="btn btn-sm btn-primary">
                    <i className="bi-clipboard-check" /> Copy
                  </span>
                </Popconfirm>
              </Space>
            ) : null,
        }),

      ...(is_inquiry &&
        x.name === "operation" && {
          title: "",
          dataIndex: "operation",
          render: (_, record) =>
            data.length >= 1 ? (
              <Space size="middle">
                <span
                  className="btn btn-sm btn-warning"
                  onClick={() => {
                    setModalData(record);
                    setModalOpen(true);
                  }}
                >
                  <i className="bi-eye-fill" /> View Detail
                </span>
              </Space>
            ) : null,
        }),
      ...(is_menu &&
        x.name === "operation" && {
          title: "Children",
          dataIndex: "operation",
          render: (_, record) =>
            record.children?.length > 1 && (
              <span className="btn btn-sm btn-warning" onClick={() => handleOrderChild(record)}>
                VIEW
              </span>
            ),
        }),
    };
  });

  if (
    title !== "Categories" &&
    title !== "Deleted Categorie(s)" &&
    title !== "Menu" &&
    title !== "Articles" &&
    title !== "Deleted Menu(s)" &&
    title !== "Media" &&
    title !== "Faqs" &&
    title !== "Comments"
  ) {
    columns.splice(0, 0, {
      title: "S.N",
      dataIndex: "sn",
      width: "3%",
    });
  }

  const generateData = (dataSource) => {
    return dataSource?.map((x, idx) => {
      const generateItem = (item, indexPrefix) => {
        return {
          ...item,
          [updateLinkColumn]: (
            <Link
              className={`link`}
              to={updateParentPath ? `${updateParentPath}/${item.id}` : `${updatePath}/${item.id}`}
            >
              {item[updateLinkColumn]}
            </Link>
          ),
          index: indexPrefix + (idx + 1),
          key: indexPrefix + (idx + 1),
          sn: indexPrefix + (idx + 1),
          status: <Status id={item.id} status_checked={item.status} />,
          approval_status: <Status id={item.id} status_checked={item.approval_status} />,
          publish_status: <Status id={item.id} status_checked={item.publish_status} />,
          updated_at: (
            <Moment fromNow ago>
              {item.updated_at}
            </Moment>
          ),
          created_at: (
            <Moment fromNow ago>
              {item.created_at}
            </Moment>
          ),
          ...(item.children && {
            children: item.children.map((child, jdx) => {
              return generateItem(child, indexPrefix + (idx + 1) + (jdx + 1));
            }),
          }),
        };
      };

      return generateItem(x, "");
    });
  };

  let data = dataSource ? generateData(dataSource) : [];
  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setDataSource((prevState) => {
        const activeIndex = prevState.findIndex(
          (item) =>
            (title === "Menu" ? item?.item_order?.toString() : item?.package_order?.toString()) ===
            active?.id
        );
        const overIndex = prevState.findIndex(
          (item) =>
            (title === "Menu" ? item?.item_order?.toString() : item?.package_order?.toString()) ===
            over?.id
        );

        const newData = arrayMove(prevState, activeIndex, overIndex);

        if (title === "Menu") {
          sortItemsByIdList({
            type: data.find((item) => item.index === over?.id).type.id,
            parent_id: data.find((item) => item.index === over?.id).parent_id,
            item_array: newData.map((item) => item.id),
          });
        } else {
          sortItemsByIdList({
            item_array: newData.map((item) => item.id),
          });
        }

        return newData;
      });
    }
  };
  const handleDelete = () => {
    Modal.confirm({
      title: "Delete",
      content: "Are you sure, you want to delete?",
      okText: "Yes",
      cancelText: "Cancel",
      onOk: async () => {
        const selectedIds = selectedItems.map((itm) => itm.id);
        await deleteItemsByIdList(selectedItems.map((x) => x.id));
        setDataSource(data.filter((itm) => !selectedIds.includes(itm.id)));
        message.success(`${title} deleted successfully`);

        setSelectedItems();
      },
    });
  };

  const handleRestore = () => {
    Modal.confirm({
      title: "Restore",
      content: "Are you sure, you want to restore?",
      okText: "Yes",
      cancelText: "Cancel",
      onOk: () => {
        restoreItemsByIdList(selectedItems.map((x) => x.id));
        //openNotificationWithIcon('success', `${title} restore successfully`);
        setSelectedItems();
        navigate(restorePath);
      },
    });
  };

  const rowSelection = {
    onSelect: (record, selected, selectedRows) => {
      setSelectedItems(selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      setSelectedItems(selectedRows);
    },
    onSelectNone: () => {
      setSelectedItems([]);
    },
    selectedRowKeys: selectedItems?.map((item) => item.key),
  };
  return (
    <>
      <div className="common-module">
        <div className="action-bar">
          <div className="title">
            <h1>{title}</h1>
          </div>
          <div className="action-slot">
            <ul>
              {addPath && (
                <li>
                  <Link to={addPath} className="btn btn-primary" id="add_new">
                    <i className="bi-plus-circle"></i> Add New
                  </Link>
                </li>
              )}

              {localStorage.admin_type === "super" && addTypePath && (
                <li>
                  <Link to={addTypePath} className="btn btn-warning" id="add_new">
                    <i className="bi-plus"></i> {addTypeText}
                  </Link>
                </li>
              )}
              {selectedItems && selectedItems.length && localStorage.admin_type === "super" ? (
                <li>
                  <button type="button" className="btn btn-danger" onClick={handleDelete}>
                    <i className="bi-x"></i> {deleteBtnText}
                  </button>
                </li>
              ) : (
                ""
              )}
              {restoreItemsByIdList &&
              selectedItems &&
              selectedItems.length &&
              localStorage.admin_type === "super" ? (
                <li>
                  <button type="button" className="btn btn-warning" onClick={handleRestore}>
                    <i className="bi-clock"></i> Restore
                  </button>
                </li>
              ) : (
                ""
              )}
            </ul>
          </div>
        </div>
      </div>
      <div className="common-module data-table bg-white">
        <div className="sorting-wrapper py-2">
          <div className="alert-text">
            <i className="bi-info-circle text-primary"></i> {data?.length} {title} are listed
          </div>
          <Sorting
            {...{
              filterOptionTitle,
              filterOptions,
              searchText,
              setFilter,
              filterText,
              setSearchText,
              searchByColumn,
              filterByColumn,
              applyFilter,
            }}
          />
        </div>

        <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
          <SortableContext items={data?.map((i) => i.key)} strategy={verticalListSortingStrategy}>
            <Table
              rowKey="key"
              columns={columns}
              expandableRowIcon={"+"}
              //pagination={is_menu && { pageSize: 30 }}
              pagination={{
                pageSizeOptions: [10, 20, 30, 50, 100, 200],
                ...(is_menu && {pageSize: 30})
                //total: true
              }}
              rowSelection={{ ...rowSelection }}
              loading={dataToList.loading && <Spin />}
              dataSource={data}
              components={{
                body: {
                  row: Row,
                },
              }}
            />
          </SortableContext>
        </DndContext>

        {is_booking && (
          <Modal
            title="Modal 1000px width"
            centered
            open={modalOpen}
            onOk={() => setModalOpen(false)}
            onCancel={() => setModalOpen(false)}
            width={1000}
          >
            {console.log(modalData)}
            <p>some contents...</p>
            <p>some contents...</p>
            <p>some contents...</p>
          </Modal>
        )}

        {is_inquiry && modalData && (
          <Modal
            title={"Inquiry from " + modalData.type + " Page"}
            open={modalOpen}
            onOk={() => {
              window.location.href = `mailto:${modalData.email}`;
              setModalOpen(false);
            }}
            onCancel={() => setModalOpen(false)}
            okText="Response it"
          >
            <h5>Contact Address</h5>
            <p>
              {modalData.name} <br /> {modalData.email} <br />{" "}
              <a href={`tel:${modalData.url}`}>{modalData.phone_no}</a> <br />{" "}
              <a href={modalData.url} target="_blank" rel="noreferrer">
                {modalData.url}
              </a>
            </p>
            <h5>Message</h5>
            <p>{modalData.message}</p>
          </Modal>
        )}
      </div>
    </>
  );
};
export default DraggableDataTable;
