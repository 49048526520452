import { apiSlice } from "../../api/apiSlice";
import {
  createQueryForList,
  createQueryById,
  createDeleteRestoreMutation,
} from "../commonFunction";

export const teamApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["Team", "TeamById", "DeletedTeam"],
  endpoints: (builder) => ({
    getTeamList: createQueryForList(builder, "/team/member/active", ["Team"]),

    getTeamById: createQueryById(builder, "/team/member/active", ["TeamById"]),

    getDeletedTeamList: createQueryForList(builder, "/team/member/deleted", [
      "Team", "DeletedTeam",
    ]),

    deleteTeam: createDeleteRestoreMutation(
      builder,
      "/team/member/bulk/delete",
      ["Team", "DeletedTeam"]
    ),

    permanentDeleteTeam: createDeleteRestoreMutation(
      builder,
      "/team/member/bulk/perma-delete",
      ["DeletedTeam"]
    ),

    restoreTeamList: createDeleteRestoreMutation(
      builder,
      "/team/member/bulk/restore",
      ["Team", "DeletedTeam"]
    ),

    createTeam: builder.mutation({
      query: (formData) => {
        let data = Object.fromEntries(
          Object.entries(formData)
            .filter(([_, v]) => v != null)
            .filter(([_, v]) => v !== "")
            .filter(([_, v]) => v !== undefined)
        );
        let webKitFormData = new FormData();

        if (data.categories) {
          for (let i = 0; i < data.categories.length; i++) {
            webKitFormData.append(`categories[${i}]`, data.categories[i]);
          }
        }

        ["categories"].forEach((e) => delete data[e]);

        Object.keys(data).forEach((item, i) => {
          webKitFormData.append(item, data[item]);
        });
        return {
          url: "/team/member/add",
          method: "POST",
          headers: {
            Accept: "application/json",
          },
          body: webKitFormData,
        };
      },
      invalidatesTags: ["Team"],
    }),

    updateTeamInfo: builder.mutation({
      query: ({ formData, id }) => {
        let data = Object.fromEntries(
          Object.entries(formData)
            .filter(([_, v]) => v != null)
            .filter(([_, v]) => v !== "")
            .filter(([_, v]) => v !== undefined)
        );

        let webKitFormData = new FormData();
        if (data.categories) {
          for (let i = 0; i < data.categories.length; i++) {
            webKitFormData.append(`categories[${i}]`, data.categories[i]);
          }
        }

        ["categories"].forEach((e) => delete data[e]);

        Object.keys(data).forEach((item, i) => {
          webKitFormData.append(item, data[item]);
        });
        webKitFormData.append("_method", "put");
        return {
          url: `/team/member/edit/${id}`,
          method: "POST",
          headers: {
            Accept: "application/json",
          },
          body: webKitFormData,
        };
      },
      invalidatesTags: ["Team", "TeamById"],
    }),
  }),
});
export const {
  useGetTeamListQuery,
  useGetTeamByIdQuery,
  useGetDeletedTeamListQuery,
  useDeleteTeamMutation,
  usePermanentDeleteTeamMutation,
  useRestoreTeamListMutation,
  useCreateTeamMutation,
  useUpdateTeamInfoMutation,
} = teamApiSlice;
