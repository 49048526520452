import { Alert, Col, DatePicker, Form, Input, InputNumber, Row, Select, Spin } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useNavigate } from "react-router-dom";
import { useGetPackageListQuery } from "../../redux/slice/package/packageApiSlice";
import { cleanFormValues } from "../../utils/cleanValues";
import DashboardActionBar from "../DashboardActionBar";
import countryCode from "./../../lib/country-code.json";

const { Option } = Select;
const { RangePicker } = DatePicker;

const PaymentForm = ({
  selectedForm,
  createEntity,
  updateEntityInfo,
  isAddForm,
  isUpdateForm,
  params,
  data,
}) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { setFieldsValue, getFieldValue } = form;

  const { data: packageList, isLoading: packageListLoading } =
    useGetPackageListQuery("packageList");

  const loading = selectedForm.loading || data.loading || packageListLoading;

  let packages = packageList?.map((itm) => {
    return {
      label: itm.package_title,
      value: itm.id,
    };
  });

  packages &&
    packages.unshift({
      label: "Custom Package",
      value: 0,
    });

  const countryNameList = countryCode.map((itm) => {
    return {
      label: itm.name,
      value: itm.name,
    };
  });

  const countryCodeList = countryCode.map((itm) => {
    return {
      label: itm.dial_code,
      value: itm.dial_code,
    };
  });

  const userAction = (e, action) => {
    e.preventDefault();
    form.validateFields().then((formValues) => {
      let values = cleanFormValues(formValues);
      if (action === "add") {
        createEntity({
          ...values,
        });
      } else if (action === "addandexit") {
        createEntity({
          ...values,
        })
          .unwrap()
          .then(() => navigate("/payments"))
          .catch();
      } else if (action === "update") {
        updateEntityInfo(
          {
            ...values,
          },
          params.id
        )
          .unwrap()
          .then()
          .catch();
      }
    });
  };

  const addNewEntry = (e) => {
    userAction(e, "add");
  };

  const addNewEntryAndExistToList = (e) => {
    userAction(e, "addandexit");
  };

  const updateExistingEntry = (e) => {
    userAction(e, "update");
  };

  // const onFinish = (values) => {
  //   if (isAddForm) {
  //     createEntity(
  //       {
  //         ...values,
  //         status: values.status ? 1 : 0,
  //       },
  //       tokenData
  //     ).then(() =>{
  //       message.success('Category is added!')
  //       loadCategory()
  //     });
  //   } else if (isUpdateForm) {
  //     updateEntityInfo(
  //       {
  //         ...values,
  //         status: values.status ? 1 : 0,
  //         is_featured: values.is_featured ? 1 : 0,
  //         description,
  //         featured_image: response.data,
  //       },
  //       tokenData,
  //       params.id
  //     ).then(() => {
  //       message.success('Category is updated!')
  //       loadEntityById()
  //       loadCategory()
  //     });
  //   }
  // };

  const clearForm = () => {
    form.resetFields();
  };

  if (isUpdateForm) {
    setFieldsValue({
      package_id: data.dataById?.trip_id,
      custom_title: data.dataById?.custom_title,
      customer_total: data.dataById?.customer_total,
      customer_name: data.dataById?.customer_name,
      customer_email: data.dataById?.customer_email,
      percent: data.dataById?.percent,
    });
  }

  const actionBarOptions = {
    titleToAdd: "Create Cart",
    titleToUpdate: "Update Cart",
    taskCompleteMessageDisplayTime: 2000,
    addButtonLabel: "Save & New",
    updateButtonLabel: "Update",
    taskCompleteAddMessage: "Cart is added!",
    taskCompleteUpdateMessage: "Cart is updated!",
    discardLink: "payments",
    addNewEntry,
    updateExistingEntry,
    loading,
    isAddForm,
    isUpdateForm,
    selectedForm,
    clearForm,
    addNewEntryAndExistToList,
  };

  return (
    <>
      <Form
        form={form}
        size="large"
        layout="vertical"
        className="custom-form"
        wrapperCol={{
          flex: 1,
        }}
      >
        <DashboardActionBar {...{ actionBarOptions }} />

        {selectedForm?.error?.errors && (
          <Alert
            className="custom-alert"
            message="Error"
            showIcon
            description={[
              <ul>
                {Object.values(selectedForm?.error?.errors).map((itm, idx) => {
                  return <li key={idx}>{itm}</li>;
                })}
              </ul>,
            ]}
            type="error"
            closable
          />
        )}
        <Spin spinning={loading} delay={500}>
          <div className="common-module bg-white mt-3">
            <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item
                  label="Traveller Full Name"
                  name="customer_name"
                  rules={[
                    {
                      required: true,
                      message: "Full Name is required",
                    },
                    { min: 5, max: 255, message: "Invalid Name" },
                  ]}
                >
                  <Input placeholder="Full Name" />
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item
                  label="Customer E-mail"
                  name="customer_email"
                  rules={[
                    {
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                    {
                      required: true,
                      message: "Please input your E-mail!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item
                  label="Total Price (US$)"
                  name="customer_total"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <InputNumber />
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item
                  label="Payable Percentage"
                  name="percent"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <InputNumber />
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item
                  label="Package"
                  name="package_id"
                  rules={[
                    {
                      required: true,
                      message: "Package is required",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                    }
                    options={packages}
                  />
                </Form.Item>
              </Col>

              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.package_id !== currentValues.package_id
                }
              >
                {({ getFieldValue }) =>
                  getFieldValue("package_id") === 0 ? (
                    <Col className="gutter-row" xs={24} sm={12} xl={8}>
                      <Form.Item
                        label="Custom Package Title"
                        name="custom_title"
                        rules={[
                          {
                            required: true,
                            message: "Custom Package is required",
                          },
                        ]}
                      >
                        <TextArea placeholder="Custom Package" rows={2} />
                      </Form.Item>
                    </Col>
                  ) : null
                }
              </Form.Item>

              {/* {getFieldValue("package_id") == 0 && (
                <Col className="gutter-row" xs={24} sm={12} xl={8}>
                  <Form.Item
                    label="Custom Package Title"
                    name="custom_title"
                    rules={[
                      {
                        required: true,
                        message: "Custom Package is required",
                      },
                    ]}
                  >
                    <TextArea placeholder="Custom Package" rows={2} />
                  </Form.Item>
                </Col>
              )} */}
            </Row>
          </div>
        </Spin>
      </Form>
    </>
  );
};

export default PaymentForm;
