import { Alert, Col, DatePicker, Form, Input, InputNumber, Row, Select, Spin, Switch } from "antd";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useGetPackageListQuery } from "../../redux/slice/package/packageApiSlice";
import { cleanFormValues } from "../../utils/cleanValues";
import DashboardActionBar from "../DashboardActionBar";
import countryCode from "./../../lib/country-code.json";

const { Option } = Select;
const { RangePicker } = DatePicker;

const BookingForm = ({
  selectedForm,
  createEntity,
  updateEntityInfo,
  isAddForm,
  isUpdateForm,
  params,
  getEntityById,
  data,
}) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { setFieldsValue } = form;
  const { data: packageList, isLoading: packageListLoading } =
    useGetPackageListQuery("packageList");

  const loading = selectedForm.loading || data.loading || packageListLoading;

  const packages = packageList?.map((itm) => {
    return {
      label: itm.package_title,
      value: itm.id,
    };
  });

  const countryNameList = countryCode.map((itm) => {
    return {
      label: itm.name,
      value: itm.name,
    };
  });

  const countryCodeList = countryCode.map((itm) => {
    return {
      label: itm.dial_code,
      value: itm.dial_code,
    };
  });

  const userAction = (e, action) => {
    e.preventDefault();
    form.validateFields().then((formValues) => {
      let values = cleanFormValues(formValues);
      if (action === "add") {
        createEntity({
          ...values,
          start_date: moment(values.departure_date[0]).format("YYYY-MM-DD"),
          end_date: moment(values.departure_date[1]).format("YYYY-MM-DD"),
        });
      } else if (action === "addandexit") {
        createEntity({
          ...values,
          start_date: moment(values.departure_date[0]).format("YYYY-MM-DD"),
          end_date: moment(values.departure_date[1]).format("YYYY-MM-DD"),
        })
          .unwrap()
          .then(() => navigate("/bookings"))
          .catch();
      } else if (action === "update") {
        updateEntityInfo({
          formData: {
            ...values,
            start_date: moment(values.departure_date[0]).format("YYYY-MM-DD"),
            end_date: moment(values.departure_date[1]).format("YYYY-MM-DD"),
          },
          id: params.id,
        });
      }
    });
  };

  const addNewEntry = (e) => {
    userAction(e, "add");
  };

  const addNewEntryAndExistToList = (e) => {
    userAction(e, "addandexit");
  };

  const updateExistingEntry = (e) => {
    userAction(e, "update");
  };

  // const onFinish = (values) => {
  //   if (isAddForm) {
  //     createEntity(
  //       {
  //         ...values,
  //         status: values.status ? 1 : 0,
  //       },
  //       tokenData
  //     ).then(() =>{
  //       message.success('Category is added!')
  //       loadCategory()
  //     });
  //   } else if (isUpdateForm) {
  //     updateEntityInfo(
  //       {
  //         ...values,
  //         status: values.status ? 1 : 0,
  //         is_featured: values.is_featured ? 1 : 0,
  //         description,
  //         featured_image: response.data,
  //       },
  //       tokenData,
  //       params.id
  //     ).then(() => {
  //       message.success('Category is updated!')
  //       loadEntityById()
  //       loadCategory()
  //     });
  //   }
  // };

  const clearForm = () => {
    form.resetFields();
  };

  if (isUpdateForm) {
    setFieldsValue({
      trip_id: data.dataById?.trip_id,
      start_date: data.dataById?.start_date,
      end_date: data.dataById?.end_date,
      full_name: data.dataById?.full_name,
      email: data.dataById?.email,
      date_of_birth: data.dataById?.date_of_birth,
      nationality: data.dataById?.nationality,
      country_code: data.dataById?.country_code,
      mobile_number: data.dataById?.mobile_number,
      pickup_details: data.dataById?.pickup_details,
      payment_options: data.dataById?.payment_options,
      total_price: data.dataById?.total_price,
      payable_percentage: data.dataById?.payable_percentage,
    });
  }

  const actionBarOptions = {
    titleToAdd: "Create Booking",
    titleToUpdate: "Update Booking",
    taskCompleteMessageDisplayTime: 2000,
    addButtonLabel: "Save & New",
    updateButtonLabel: "Update",
    taskCompleteAddMessage: "Booking is added!",
    taskCompleteUpdateMessage: "Booking is updated!",
    discardLink: "bookings",
    addNewEntry,
    updateExistingEntry,
    loading,
    isAddForm,
    isUpdateForm,
    selectedForm,
    clearForm,
    addNewEntryAndExistToList,
  };

  return (
    <>
      <Form
        form={form}
        size="large"
        layout="vertical"
        className="custom-form"
        wrapperCol={{
          flex: 1,
        }}
      >
        <DashboardActionBar {...{ actionBarOptions }} />

        {selectedForm?.error?.errors && (
          <Alert
            className="custom-alert"
            message="Error"
            showIcon
            description={[
              <ul>
                {Object.values(selectedForm?.error?.errors).map((itm, idx) => {
                  return <li key={idx}>{itm}</li>;
                })}
              </ul>,
            ]}
            type="error"
            closable
          />
        )}
        <Spin spinning={loading} delay={500}>
          <div className="common-module bg-white mt-3">
            <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item
                  label="Full Name"
                  name="full_name"
                  rules={[
                    {
                      required: true,
                      message: "Name is required",
                    },
                    { min: 5, max: 255, message: "Invalid Name" },
                  ]}
                >
                  <Input placeholder="Full Name" />
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item
                  label="E-mail"
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                    {
                      required: true,
                      message: "Please input your E-mail!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item
                  label="Departure Date"
                  name="departure_date"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Departure Date",
                    },
                  ]}
                >
                  <RangePicker
                    format={"YYYY-MM-DD"}
                    disabledDate={(current) => current && current < moment().endOf("day")}
                  />
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item
                  label="Number of Traveller"
                  name="number_of_traveller"
                  rules={[
                    {
                      required: true,
                      message: "min 1 traveller required",
                    },
                  ]}
                >
                  <InputNumber min={1} />
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item
                  label="Package"
                  name="trip_id"
                  rules={[
                    {
                      required: true,
                      message: "Package is required",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                    }
                    options={packages}
                  />
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item
                  label="Total Price (US$)"
                  name="total_price"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <InputNumber />
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item
                  label="Payable Percentage"
                  name="payable_percentage"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <InputNumber />
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} xl={12} hidden>
                <div className="switch-group">
                  <Form.Item label="Status" valuePropName="checked" name="is_custom">
                    <Switch checkedChildren="Yes" unCheckedChildren="No" />
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </div>
        </Spin>
      </Form>
    </>
  );
};

export default BookingForm;
