import React, { useCallback, useMemo } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import MediaManager from "../../Components/MediaManager";
import { BASE_URL } from "../../lib/urls";

const ItemType = "ITEM";

const DraggableItem = ({ item, index, moveItem, handleDeleteMedia }) => {
  const [, ref] = useDrag({
    type: ItemType,
    item: { index },
  });

  const [, drop] = useDrop({
    accept: ItemType,
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveItem(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  return (
    <li ref={(node) => ref(drop(node))} style={{ cursor: "move" }}>
      <figure>
        <div className="image-slot">
          <img src={`${BASE_URL}${item.full_path}`} alt="" />
        </div>
        <figcaption>
          <button onClick={() => handleDeleteMedia(item.id)} className="btn-trash">
            <i className="bi-trash"></i>
          </button>
        </figcaption>
      </figure>
    </li>
  );
};

const DraggableMediaManager = ({ mediaKeyType, isMultiple, selectedItems = [], setSelectedItems }) => {
  const handleSelectItems = useCallback(
    (items) => {
      const newItems = items?.filter(
        (item) => !selectedItems.some((selectedItem) => selectedItem.id === item.id)
      );
      if (newItems.length > 0) {
        setSelectedItems((prevItems = []) => [...prevItems, ...newItems]);
      }
    },
    [selectedItems, setSelectedItems]
  );

  const handleDeleteMedia = useCallback(
    (id) => {
      setSelectedItems((prev = []) => prev.filter((item) => item.id !== id));
    },
    [setSelectedItems]
  );

  const moveItem = useCallback(
    (fromIndex, toIndex) => {
      setSelectedItems((prevItems = []) => {
        const updatedItems = [...prevItems];
        const [movedItem] = updatedItems.splice(fromIndex, 1);
        updatedItems.splice(toIndex, 0, movedItem);
        return updatedItems;
      });
    },
    [setSelectedItems]
  );

  const renderedItems = useMemo(() => {
    return selectedItems?.map((item, index) => (
      <DraggableItem
        key={item.id}
        index={index}
        item={item}
        moveItem={moveItem}
        handleDeleteMedia={handleDeleteMedia}
      />
    ));
  }, [selectedItems, moveItem, handleDeleteMedia]);

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="media-display-slot">
        <ul>
          {renderedItems}
          <li className="btn-media-add">
            <MediaManager
              isMultiple={isMultiple}
              onSelectItems={handleSelectItems}
              mediaKeyType={mediaKeyType}
            />
          </li>
        </ul>
      </div>
    </DndProvider>
  );
};

export default DraggableMediaManager;
