import DashboardList from "../../Components/Layouts/DashboardList";
import { useGetPaymentListQuery } from "../../redux/slice/payment/paymentApiSlice";

function List() {
  const tableConfig = {
    title: "All Cart",
    is_payment: true,
    tableColumns: [
      { name: "operation", selector: "checkout_url", width: "2%" },
      { name: "Name", selector: "customer_name", width: "50%" },
      { name: "Email", selector: "customer_email", width: "15%" },
      { name: "Price", selector: "customer_total", width: "15%" },
      { name: "Created At", selector: "created_at", width: "15%" },
      { name: "ID", selector: "id", width: "2%" },
    ],
    filterByColumn: "type",
    searchByColumn: "full_name",
    addPath: "/payment/add",
    updatePath: "/payment/update",
    noDataMessage: "No Cart package",
    updateLinkColumn: "full_name",
    // sendEmailByBookingId: (id) => {
    //   onSendEmail(id);
    // },
  };
  return (
    <DashboardList
      getList={useGetPaymentListQuery()}
      tableConfig={tableConfig}
    />
  );
}

export default List;
