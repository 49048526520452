import DashboardList from "../../Components/Layouts/DashboardList";
import {
  useGetDeletedItineraryListQuery,
  usePermanentDeleteItineraryMutation,
  useRestoreItineraryListMutation,
} from "../../redux/slice/itinerary/itineraryApiSlice";

function DeletedList() {
  const [onDelete, { isLoading: permaDeleting }] =
    usePermanentDeleteItineraryMutation();
  const [onRestore, { isLoading: restoring }] =
    useRestoreItineraryListMutation();

  let loading = permaDeleting || restoring;
  const tableConfig = {
    title: "Deleted Itineraries",
    filterOptionTitle: "Team Type",
    filterOptions: ["package"],
    applyFilter: false,
    tableColumns: [
      { name: "Status", selector: "status", width: "3%" },
      //{name: "Order", selector: 'order', width: '3%'},
      { name: "Title", selector: "itinerary_title", width: "70%" },
      { name: "Modified Date", selector: "updated_at", width: "20%" },
      { name: "ID", selector: "id", width: "5%" },
    ],
    filterByColumn: "title",
    searchByColumn: "title",
    updatePath: "/itinerary/update",
    addPath: "/itinerary/add",
    restorePath: "/itineraries",
    noDataMessage: "No Itinerary exists",
    updateLinkColumn: "itinerary_title",
    deleteBtnText: "Permanent Delete",
    deleteItemsByIdList: (idList) => {
      onDelete(idList);
    },
    restoreItemsByIdList: (idList) => {
      onRestore(idList);
    },
    loading,
  };

  return (
    <DashboardList
      getList={useGetDeletedItineraryListQuery()}
      tableConfig={tableConfig}
    />
  );
}
export default DeletedList;
