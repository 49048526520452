import DashboardList from "../../Components/Layouts/DashboardList";
import {
  useGetDeletedStoryListQuery,
  usePermanentDeleteStoryMutation,
  useRestoreStoryListMutation,
} from "../../redux/slice/story/storyApiSlice";

function DeletedList() {
  const [onDelete, { isLoading: permaDeleting }] =
    usePermanentDeleteStoryMutation();
  const [onRestore, { isLoading: restoring }] = useRestoreStoryListMutation();

  let loading = permaDeleting || restoring;

  const tableConfig = {
    title: "Deleted Story",
    filterOptionTitle: "Story Type",
    filterOptions: ["admin", "superadmin", "member"],
    defaultFilterValue: "admin",
    applyFilter: false,
    tableColumns: [
      { name: "Order", selector: "order", width: "5%" },
      { name: "Status", selector: "status", width: "5%" },
      { name: "Title", selector: "title", width: "70%" },
      { name: "Modified Date", selector: "updated_at", width: "15%" },
      { name: "ID", selector: "id", width: "5%" },
    ],
    filterByColumn: "title",
    searchByColumn: "title",
    updatePath: "/story/update",
    addPath: "/story/add",
    restorePath: "/stories",
    noDataMessage: "No story exists",
    updateLinkColumn: "title",
    deleteBtnText: "Permanent Delete",
    deleteItemsByIdList: (idList) => {
      onDelete(idList);
    },
    restoreItemsByIdList: (idList) => {
      onRestore(idList);
    },
    loading,
  };
  return (
    <DashboardList
      getList={useGetDeletedStoryListQuery()}
      tableConfig={tableConfig}
    />
  );
}

export default DeletedList;
