import DashboardAdd from "../../Components/Layouts/DashboardAdd";
import PackageForm from "../../Components/Forms/PackageForm";
import {
  useCreatePackageMutation,
  useGetPackageByIdQuery,
} from "../../redux/slice/package/packageApiSlice";
import useHandleCreateErrorsAndSuccess from "../../hooks/useHandleCreateErrorsAndSuccess";

function Add() {
  const [
    createEntity,
    {
      data: createdPackageData,
      isLoading: creatingPackage,
      isError: createPackageError,
      isSuccess: packageCreated,
      error,
    },
  ] = useCreatePackageMutation("createPackage");

  let data = {
    data: undefined,
    loading: creatingPackage,
    error: createPackageError,
    dataById: undefined,
  };
  const selectedForm = {
    data: undefined,
    loading: creatingPackage,
    error: error?.data.errors,
    loadedRecently: false,
    dataById: undefined,
  };

  useHandleCreateErrorsAndSuccess(error, packageCreated, "Package");

  return (
    <DashboardAdd>
      <PackageForm
        isAddForm
        {...{
          getEntityById: useGetPackageByIdQuery,
          createEntity,
          data,
          selectedForm,
        }}
      />
    </DashboardAdd>
  );
}

export default Add;
