import DashboardList from "../../Components/Layouts/DashboardList";
import { useGetPackageListQuery } from "../../redux/slice/package/packageApiSlice";

function List() {
  const tableConfig = {
    title: "Trip Departures",
    filterOptionTitle: "Category Type",
    //filterOptions: filter_options,
    applyFilter: false,
    tableColumns: [
      { name: "Title", selector: "package_title", width: "77%" },
      { name: "Status", selector: "status", width: "3%" },
      { name: "Modified Date", selector: "updated_at", width: "15%" },
      { name: "ID", selector: "id", width: "5%" },
    ],
    filterByColumn: "type_id",
    searchByColumn: "package_title",
    // addPath: "/departure/add",
    updatePath: "/departure/update",
    noDataMessage: "No trip departure exists",
    deleteBtnText: "Delete",
    updateLinkColumn: "package_title",
    // deleteItemsByIdList: (idList) => {
    //   onDelete(idList);
    // },
  };
  return (
    <DashboardList
      getList={useGetPackageListQuery()}
      tableConfig={tableConfig}
    />
  );
}

export default List;
