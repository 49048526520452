import DashboardList from "../../Components/Layouts/DashboardList";
import {
  useGetStoryAuthorListQuery,
  useDeleteStoryAuthorMutation,
} from "../../redux/slice/storyAuthor/storyAuthorApiSlice";

function List() {
  const [onDelete] = useDeleteStoryAuthorMutation("deleteEntityById");

  const tableConfig = {
    title: "Story Authors",
    filterOptionTitle: "Author Type",
    filterOptions: ["author"],
    defaultFilterValue: "admin",
    applyFilter: false,
    tableColumns: [
      { name: "Status", selector: "status", width: "3%" },
      { name: "Order", selector: "order", width: "3%" },
      { name: "Name", selector: "name", width: "70%" },
      { name: "Modified Date", selector: "updated_at", width: "17%" },
      { name: "ID", selector: "id", width: "5%" },
    ],
    filterByColumn: "name",
    searchByColumn: "name",
    updatePath: "/story-author/update",
    addPath: "/story-author/add",
    noDataMessage: "No author exists",
    updateLinkColumn: "name",
    deleteBtnText: "Delete",
    deleteItemsByIdList: (idList) => {
      onDelete(idList);
    },
  };

  return (
    <DashboardList
      getList={useGetStoryAuthorListQuery()}
      tableConfig={tableConfig}
    />
  );
}

export default List;
