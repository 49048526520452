import { apiSlice } from "../../api/apiSlice";
import {
  createQueryForList,
  createQueryById,
  createDeleteRestoreMutation,
} from "../commonFunction";

export const storyApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["Story", "StoryById", "DeletedStory"],
  endpoints: (builder) => ({
    getStoryList: createQueryForList(builder, "/travel/story/active", [
      "Story",
    ]),

    getStoryById: createQueryById(builder, (id) => "/travel/story/active", [
      "StoryById",
    ]),

    getDeletedStoryList: createQueryForList(builder, "/travel/story/deleted", [
      "DeletedStory",
    ]),

    deleteStory: createDeleteRestoreMutation(
      builder,
      "/travel/story/bulk/delete",
      ["Story", "DeletedStory"]
    ),

    permanentDeleteStory: createDeleteRestoreMutation(
      builder,
      "/travel/story/bulk/perma-delete",
      ["DeletedStory"]
    ),

    restoreStoryList: createDeleteRestoreMutation(
      builder,
      "/travel/story/bulk/restore",
      ["Story", "DeletedStory"]
    ),

    createStory: builder.mutation({
      query: (formData) => {
        let data = Object.fromEntries(
          Object.entries(formData)
            .filter(([_, v]) => v != null)
            .filter(([_, v]) => v !== "")
            .filter(([_, v]) => v !== undefined)
        );
        let webKitFormData = new FormData();

        if (data.authors) {
          for (let i = 0; i < data.authors.length; i++) {
            webKitFormData.append(`authors[${i}]`, data.authors[i]);
          }
        }

        ["authors"].forEach((e) => delete data[e]);

        Object.keys(data).forEach((item, i) => {
          webKitFormData.append(item, data[item]);
        });

        Object.keys(data).forEach((item, i) => {
          webKitFormData.append(item, data[item]);
        });
        return {
          url: "/travel/story/add",
          method: "POST",
          headers: {
            Accept: "application/json",
          },
          body: webKitFormData,
        };
      },
      invalidatesTags: ["Story"],
    }),

    updateStoryInfo: builder.mutation({
      query: ({ formData, id }) => {
        let data = Object.fromEntries(
          Object.entries(formData)
            .filter(([_, v]) => v != null)
            .filter(([_, v]) => v !== "")
            .filter(([_, v]) => v !== undefined)
        );

        let webKitFormData = new FormData();

        if (data.authors) {
          for (let i = 0; i < data.authors.length; i++) {
            webKitFormData.append(`authors[${i}]`, data.authors[i]);
          }
        }
        ["authors"].forEach((e) => delete data[e]);

        Object.keys(data).forEach((item, i) => {
          webKitFormData.append(item, data[item]);
        });
        webKitFormData.append("_method", "put");

        return {
          url: `/travel/story/edit/${id}`,
          method: "POST",
          headers: {
            Accept: "application/json",
          },
          body: webKitFormData,
        };
      },
      invalidatesTags: ["Story", "StoryById"],
    }),
  }),
});
export const {
  useGetStoryListQuery,
  useGetStoryByIdQuery,
  useGetDeletedStoryListQuery,
  useDeleteStoryMutation,
  usePermanentDeleteStoryMutation,
  useRestoreStoryListMutation,
  useCreateStoryMutation,
  useUpdateStoryInfoMutation,
} = storyApiSlice;
