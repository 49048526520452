import DashboardAdd from "../../Components/Layouts/DashboardAdd";
import TestimonialForm from "../../Components/Forms/TestimonialForm";
import { useCreateTestimonialMutation } from "../../redux/slice/testimonial/testimonialApiSlice";
import useHandleCreateErrorsAndSuccess from "../../hooks/useHandleCreateErrorsAndSuccess";

function Add() {
  const [
    createEntity,
    {
      data: createdTestimonialData,
      isLoading: creatingTestimonial,
      isError: creatingError,
      isSuccess: createTestimonialSuccess,
      error,
    },
  ] = useCreateTestimonialMutation("createTestimonial");

  let data = {
    data: undefined,
    loading: creatingTestimonial,
    error: creatingError,
    dataById: undefined,
  };
  const selectedForm = {
    data: undefined,
    loading: creatingTestimonial,
    error: error?.data.errors,
    loadedRecently: false,
    dataById: undefined,
  };

  useHandleCreateErrorsAndSuccess(error, createTestimonialSuccess, "Testimonial");

  return (
    <DashboardAdd>
      <TestimonialForm
        isAddForm
        {...{
          createEntity,
          data,
          selectedForm,
        }}
      />
    </DashboardAdd>
  );
}
export default Add;
