import { useEffect } from "react";

import DashboardAdd from "../../Components/Layouts/DashboardAdd";
import CategoryForm from "../../Components/Forms/CategoryForm";
import { message } from "antd";
import {
  useCreateCategoryMutation,
  useGetCategoryByIdQuery,
} from "../../redux/slice/category/categoryApiSlice";
import useHandleCreateErrorsAndSuccess from "../../hooks/useHandleCreateErrorsAndSuccess";

function Add() {
  const [
    createEntity,
    {
      isLoading: creating,
      isError: createError,
      error,
      isSuccess: categoryCreated,
    },
  ] = useCreateCategoryMutation("createCategory");

  let data = {
    data: undefined,
    loading: creating,
    error: createError,
    dataById: undefined,
  };

  useHandleCreateErrorsAndSuccess(error, categoryCreated, "Category");

  return (
    <DashboardAdd>
      <CategoryForm
        isAddForm
        {...{
          getEntityById: useGetCategoryByIdQuery,
          createEntity,
          data,
        }}
      />
    </DashboardAdd>
  );
}

export default Add;
