import { Alert, Col, Form, Input, Row, Select, Spin, Switch, TreeSelect } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ImageField from "../Core/ImageField";
import TextEditor from "../Core/ReactTinymce/ReactTinymce";
import ImageCrop from "../ImageCrop";

import { useGetPackageListQuery } from "../../redux/slice/package/packageApiSlice";

//import "antd/dist/antd.css";
import axios from "axios";
import { cleanFormValues } from "../../utils/cleanValues";
import DashboardActionBar from "../DashboardActionBar";
const { TreeNode } = TreeSelect;
const { Option } = Select;

const ReelForm = ({
  loginData,
  selectedForm,
  createEntity,
  updateEntityInfo,
  isAddForm,
  isUpdateForm,
  params,
  getEntityById,
  data,
}) => {
  const navigate = useNavigate();
  const [short_description, setShortDescription] = useState(
    isUpdateForm && data.dataById?.page_description
  );
  const [thumbnail, setThumbnail] = useState(
    isUpdateForm ? data.dataById && data.dataById.thumbnail : null
  );
  const [image_crop_open, setImageCropOpen] = useState(false);
  const [form] = Form.useForm();
  const { setFieldsValue } = form;

  const { data: list, isLoading: listLoading, isError: listError } = useGetPackageListQuery();

  const loading = selectedForm.loading || data.loading || listLoading;
  // useEffect(() => {
  //   if (isUpdateForm && !loading) {
  //     setThumbnail(data.dataById?.thumbnail)
  //   }
  // }, [isUpdateForm, loading, data.dataById?.thumbnail])

  const articleListOptions = list?.map((itm) => {
    return {
      value: itm.id,
      label: itm.package_title,
      // ...(itm.children?.length !== 0 && {
      //   children: itm.children?.map((a) => {
      //     return {
      //       value: a.id,
      //       label: a.page_title,
      //       ...(a.children?.length !== 0 && {
      //         children: a.children?.map((b) => {
      //           return {
      //             value: b.id,
      //             label: b.page_title,
      //           };
      //         }),
      //       }),
      //     };
      //   }),
      // }),
    };
  });

  const clearForm = () => {
    form.resetFields();
    setShortDescription("");
    setThumbnail("");
  };

  if (isUpdateForm) {
    setFieldsValue({
      model_type: "package", //data.dataById?.model_type,
      model_item_id: data.dataById?.parent_id,
      caption: data.dataById?.caption,
      alt_text: data.dataById?.alt_text,
      thumbnail: data.dataById?.thumbnail,
      reel_url: data.dataById?.reel_url,
      short_description: data.dataById?.short_description,
      status: data.dataById?.status === 1 ? true : false,
      is_featured: data.dataById?.is_featured === 1 ? true : false,
    });
  }

  const reelAction = (e, action) => {
    e.preventDefault();
    form.validateFields().then((formValues) => {
      let values = cleanFormValues(formValues);
      if (action === "add") {
        if (thumbnail) {
          axios.get(thumbnail, { responseType: "blob" }).then((response) => {
            createEntity({
              ...values,
              status: values.status ? 1 : 0,
              is_featured: values.is_featured ? 1 : 0,
              thumbnail: response.data,
            });
          });
        } else {
          createEntity({
            ...values,
            status: values.status ? 1 : 0,
            is_featured: values.is_featured ? 1 : 0,
          });
        }
      } else if (action === "addandexit") {
        if (thumbnail) {
          axios.get(thumbnail, { responseType: "blob" }).then((response) => {
            createEntity({
              ...values,
              status: values.status ? 1 : 0,
              is_featured: values.is_featured ? 1 : 0,
              thumbnail: response.data,
            })
              .unwrap()
              .then(() => navigate("/reels"))
              .catch();
          });
        } else {
          createEntity({
            ...values,
            status: values.status ? 1 : 0,
            is_featured: values.is_featured ? 1 : 0,
          })
            .unwrap()
            .then(() => navigate("/reels"))
            .catch();
        }
      } else if (action === "update") {
        if (thumbnail) {
          axios.get(thumbnail, { responseType: "blob" }).then((response) => {
            updateEntityInfo({
              formData: {
                ...values,
                status: values.status ? 1 : 0,
                is_featured: values.is_featured ? 1 : 0,
                thumbnail: response.data,
              },
              id: params.id,
            });
          });
        } else {
          updateEntityInfo({
            formData: {
              ...values,
              status: values.status ? 1 : 0,
              is_featured: values.is_featured ? 1 : 0,
            },
            id: params.id,
          });
        }
      }
    });
  };

  const addNewEntry = (e) => {
    reelAction(e, "add");
  };

  const addNewEntryAndExistToList = (e) => {
    reelAction(e, "addandexit");
  };

  const updateExistingEntry = (e) => {
    reelAction(e, "update");
  };

  const actionBarOptions = {
    titleToAdd: "Add Reel",
    titleToUpdate: "Update Reel",
    taskCompleteMessageDisplayTime: 2000,
    addButtonLabel: "Save & Add New",
    updateButtonLabel: "Update",
    discardLink: "reels",
    addNewEntry,
    updateExistingEntry,
    loading,
    isAddForm,
    isUpdateForm,
    selectedForm,
    clearForm,
    addNewEntryAndExistToList,
  };

  return (
    <>
      <Form
        form={form}
        size="large"
        layout="vertical"
        className="custom-form"
        wrapperCol={{
          flex: 1,
        }}
      >
        <DashboardActionBar {...{ actionBarOptions }} />

        {selectedForm?.error?.errors && (
          <Alert
            className="custom-alert"
            message="Error"
            showIcon
            description={[
              <ul>
                {Object.values(selectedForm.error.errors).map((itm, idx) => {
                  return <li key={idx}>{itm}</li>;
                })}
              </ul>,
            ]}
            type="error"
            closable
          />
        )}
        <Spin spinning={loading} delay={500}>
          <div className="common-module mt-3">
            <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
              <Col xs={24} md={24} lg={18}>
                <div className="common-module bg-white">
                  <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
                    <Col className="gutter-row" xs={24}>
                      <Form.Item
                        label="Select a Model Type"
                        name="model_type"
                        initialValue="package"
                        rules={[
                          {
                            required: true,
                            message: "Model type is required",
                          },
                        ]}
                      >
                        <Select>
                          <Option label="Package" value="package" />
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24}>
                      <Form.Item
                        label="Model Item"
                        name="model_item_id"
                        rules={[
                          {
                            required: true,
                            message: "Package is required",
                          },
                        ]}
                      >
                        <TreeSelect
                          showSearch
                          style={{ width: "100%" }}
                          dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                          placeholder="Please select"
                          allowClear
                          treeDefaultExpandAll
                        >
                          <TreeNode value={0} title="No Parent" />
                          {articleListOptions?.map((parent) => {
                            const { value, label, children } = parent;
                            return (
                              <TreeNode key={value} value={value} title={label}>
                                {children?.length !== 0 &&
                                  children?.map((child) => {
                                    const { value, label, children } = child;
                                    return (
                                      <TreeNode key={value} value={value} title={label}>
                                        {children?.length !== 0 &&
                                          children?.map((gChild) => {
                                            const { value, label } = gChild;
                                            return (
                                              <TreeNode key={value} value={value} title={label} />
                                            );
                                          })}
                                      </TreeNode>
                                    );
                                  })}
                              </TreeNode>
                            );
                          })}
                        </TreeSelect>
                      </Form.Item>
                    </Col>

                    <Col className="gutter-row" xs={24}>
                      <Form.Item>
                        <ImageField
                          title="Thumbnail"
                          image={thumbnail || (isUpdateForm && data.dataById?.thumbnail)}
                          height={process.env.REACT_APP_REEL_THUMBINAL_HEIGHT}
                          width={process.env.REACT_APP_REEL_THUMBINAL_WIDTH}
                          setImage={(image_data) => {
                            setThumbnail(image_data);
                            setImageCropOpen(true);
                          }}
                        />
                        {thumbnail && image_crop_open && (
                          <ImageCrop
                            height={process.env.REACT_APP_REEL_THUMBINAL_HEIGHT}
                            width={process.env.REACT_APP_REEL_THUMBINAL_WIDTH}
                            image={thumbnail}
                            setCroppedImage={setThumbnail}
                            setImageCropOpen={setImageCropOpen}
                          />
                        )}
                      </Form.Item>
                      <Form.Item
                        style={{ marginTop: "-20px" }}
                        label="Alt Text"
                        name="alt_text"
                        rules={[{ min: 5, max: 50, message: "Invalid Alt Text" }]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="Caption"
                        name="caption"
                        rules={[{ min: 5, max: 50, message: "Invalid caption" }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24}>
                      <Form.Item
                        label="Reel Url ID"
                        name="reel_url"
                        rules={[{ required: true, message: "ID required" }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24}>
                      <div className="switch-group">
                        <Form.Item label="Status" valuePropName="checked" name="status">
                          <Switch checkedChildren="Publish" unCheckedChildren="Unpublish" />
                        </Form.Item>
                        <Form.Item label="Is Featured" valuePropName="checked" name="is_featured">
                          <Switch checkedChildren="Yes" unCheckedChildren="No" />
                        </Form.Item>
                      </div>
                    </Col>
                    <Col className="gutter-row" xs={24}>
                      <Form.Item label="Short Description" name="short_description">
                        <TextEditor
                          simpleTollbar
                          value={short_description}
                          initialValue={isUpdateForm && short_description}
                          onEditorChange={(e) => {
                            setShortDescription(e.target.value);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </Spin>
      </Form>
    </>
  );
};

export default ReelForm;
