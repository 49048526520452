import React, { useRef, useState } from "react";
import { BASE_URL } from "../../../lib/urls";
import MediaManager from "../../MediaManager";
import BundledEditor from "./BundledEditor";

const TextEditor = (props) => {
  const [mediaVisible, setMediaVisible] = useState(false);
  const editorRef = useRef(null);

  // const handleEditorChange = (e) => {
  //   console.log("e", e);
  //   console.log("Content was updated:", e.target.getContent());
  // };

  const handleSelectItem = (item) => {
    if (editorRef.current) {
      editorRef.current.insertContent(
        `<figure class="image">
           <img src="${BASE_URL}${item[0].full_path}" alt="${item[0].alt_text}" />
           <figcaption>${item[0].caption}</figcaption>
         </figure>`
      );
    }
  };

  const editorConfig = {
    menubar: !props.simpleTollbar,
    plugins: [
      "advlist",
      "anchor",
      "autolink",
      "autoresize",
      "autosave",
      "charmap",
      "code",
      "codesample",
      "directionality",
      "emoticons",
      "fullscreen",
      "help",
      "image",
      "importcss",
      "insertdatetime",
      "link",
      "lists",
      "media",
      "nonbreaking",
      "pagebreak",
      "preview",
      "quickbars",
      "save",
      "searchreplace",
      "table",
      "visualblocks",
      "visualchars",
      "wordcount",
    ],
    contextmenu: "paste copy | link image inserttable | cell row column deletetable",
    toolbar: props.simpleTollbar
      ? "H2 H3 H4 | bold italic blockquote | bullist numlist removeformat link"
      : props.isBlog
      ? "undo redo | blocks formatselect | bold italic backcolor | alignleft aligncenter " +
        "alignright alignjustify | bullist numlist outdent indent | removeformat | " +
        " blog-block | media custom-image | fullscreen"
      : "undo redo | blocks formatselect | bold italic backcolor | alignleft aligncenter " +
        "alignright alignjustify | bullist numlist outdent indent | removeformat | " +
        "media custom-image fullscreen",
    setup: function (editor) {
      if (props.isBlog) {
        editor.ui.registry.addButton("blog-block", {
          text: "Add Package",
          onAction: () => {
            editor.insertContent(
              '<div class="package-block" style="font-weight:bold;display: block;padding: .25em 0;background: #e9f9ff;color: #0a80af;">[block]()</div><p></p>'
            );
          },
        });
      }

      editor.ui.registry.addButton("custom-image", {
        icon: "image",
        tooltip: "Insert Image",
        onAction: () => setMediaVisible(true),
      });

      // wrapping table and iframe with respective classes
      editor.on("NodeChange", (e) => {
        const tableElements = editor.getDoc().querySelectorAll("table");
        tableElements.forEach((table) => {
          if (!table.parentElement.classList.contains("responsive-table")) {
            const wrapper = document.createElement("div");
            wrapper.className = "responsive-table";
            table.parentNode.insertBefore(wrapper, table);
            wrapper.appendChild(table);
          }
        });

        const iframeElements = editor.getDoc().querySelectorAll("iframe");
        iframeElements.forEach((iframe) => {
          if (!iframe.parentElement.classList.contains("embedded-iframe")) {
            const wrapper = document.createElement("div");
            wrapper.className = "embedded-iframe";
            iframe.parentNode.insertBefore(wrapper, iframe);
            wrapper.appendChild(iframe);
          }
        });
      });
    },
    image_advtab: true,
    image_caption: true,
    paste_data_images: false,
    content_style: `
      .mce-content-body {
        overflow-y: auto !important;
      }
      .mce-match-marker {
        background-color: yellow;
        font-weight: bold;
      }
      .mce-match-marker-selected {
        background-color: green;
      }`,
  };

  const handleCloseModal = () => {
    setMediaVisible(false);
  };
  return (
    <>
      <BundledEditor
        value={props.value}
        initialValue={props.initialValue ? props.initialValue : null}
        init={{
          ...editorConfig,
          ...(props.inline && { inline: true, referrer_policy: "origin" }),
          ...(props.placeholder && { placeholder: props.placeholder }),
          ...(props.simpleTollbar ? { max_height: 200 } : { max_height: 800, minHeight: 400 }),
          toolbar_sticky: true,
          branding: false,
          min_height: 250,
          max_height: 1000,
        }}
        onEditorChange={props.onChange}
        //onChange={handleEditorChange}
        onInit={(evt, editor) => (editorRef.current = editor)}
      />
      <MediaManager
        visible={mediaVisible}
        onClose={handleCloseModal}
        mediaKeyType={"attachments"}
        onSelectItems={handleSelectItem}
        disableButton
      />
    </>
  );
};

export default TextEditor;
