import { useEffect, useState } from "react";
import DashboardList from "../../Components/Layouts/DashboardList";
import {
  useGetDeletedPackageListQuery,
  usePermanentDeletePackageMutation,
  useRestorePackageListMutation,
} from "../../redux/slice/package/packageApiSlice";
import { useGetCategoryListByTypeKeyQuery } from "../../redux/slice/category/categoryApiSlice";

function DeletedList() {
  const [filter_options, setFilterOptions] = useState();

  let category_type_key = "destination";
  const { data: categoryByTypeKey, isLoading: categoryListLoading } =
    useGetCategoryListByTypeKeyQuery({
      type_key: category_type_key,
    });

  const [onDelete, { isLoading: permaDeleting }] =
    usePermanentDeletePackageMutation();
  const [onRestore, { isLoading: restoring }] = useRestorePackageListMutation();

  let loading = categoryListLoading || permaDeleting || restoring;

  useEffect(() => {
    if (categoryByTypeKey) {
      setFilterOptions(
        categoryByTypeKey?.map((e) => {
          return { value: e.id, text: e.title };
        })
      );
    }
  }, [categoryByTypeKey]);

  const tableConfig = {
    title: "Deleted Packages",
    filterOptionTitle: "Category",
    filterOptions: filter_options,
    applyFilter: true,
    tableColumns: [
      { name: "Status", selector: "status", width: "3%" },
      { name: "Order", selector: "order", width: "3%" },
      { name: "Title", selector: "package_title", width: "70%" },
      { name: "Modified Date", selector: "updated_at", width: "17%" },
      { name: "ID", selector: "id", width: "5%" },
    ],
    filterByColumn: "title",
    searchByColumn: "title",
    updatePath: "/package/update",
    addPath: "/package/add",
    restorePath: "/packages",
    noDataMessage: "No Package exists",
    updateLinkColumn: "package_title",
    deleteBtnText: "Permanent Delete",
    deleteItemsByIdList: (idList) => {
      onDelete(idList);
    },
    restoreItemsByIdList: (idList) => {
      onRestore(idList);
    },
    loading,
  };

  return (
    <DashboardList
      getList={useGetDeletedPackageListQuery()}
      tableConfig={tableConfig}
    />
  );
}
export default DeletedList;
