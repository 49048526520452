import { apiSlice } from "../../api/apiSlice";
import {
  createQueryForList,
  createQueryById,
  createDeleteRestoreMutation,
} from "../commonFunction";

export const departureApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["Departure", "DepartureById", "DepartureByPackageId", "DeletedDeparture"],
  endpoints: (builder) => ({
    getDepartureList: createQueryForList(builder, "/travel/departure/all", ["Departure"]),

    getDepartureById: createQueryById(builder, "travel/departure/single", ["DepartureById"]),

    getDepartureByPackageId: createQueryById(builder, "/travel/departure/package", [
      "DepartureByPackageId",
    ]),

    getDeletedDepartureList: createQueryForList(builder, "/travel/departure/deleted", [
      "DeletedDeparture",
    ]),

    deleteDeparture: createDeleteRestoreMutation(builder, "/travel/departure/bulk/perma-delete", [
      "Departure",
      "DeletedDeparture",
    ]),

    createDeparture: builder.mutation({
      query: (formData) => {
        let data = Object.fromEntries(
          Object.entries(formData)
            .filter(([_, v]) => v != null)
            .filter(([_, v]) => v !== "")
            .filter(([_, v]) => v !== undefined)
        );
        let webKitFormData = new FormData();

        if (data.departures) {
          let departureDate = data.departures.map((item) => item.departure_date);
          let departureCost = data.departures.map((item) => item.departure_cost);
          let departureNote = data.departures.map((itm) => itm.departure_note);
          let departureStatus = data.departures.map((itm) => itm.departure_status);
          for (let i = 0; i < departureDate.length; i++) {
            webKitFormData.append("departures[" + i + "][departure_date]", departureDate[i]);
          }
          for (let i = 0; i < departureNote.length; i++) {
            webKitFormData.append("departures[" + i + "][departure_note]", formData.note);
          }
          for (let i = 0; i < departureStatus.length; i++) {
            webKitFormData.append("departures[" + i + "][departure_status]", formData.status);
          }
          for (let i = 0; i < departureCost.length; i++) {
            webKitFormData.append("departures[" + i + "][departure_cost]", departureCost[i]);
          }
        }
        ["departures"].forEach((e) => delete data[e]);

        Object.keys(data).forEach((item, i) => {
          webKitFormData.append(item, data[item]);
        });

        return {
          url: "/travel/departure/bulk/add",
          method: "POST",
          headers: {
            Accept: "application/json",
          },
          body: webKitFormData,
        };
      },
      invalidatesTags: ["Departure", "DepartureByPackageId"],
    }),

    updateDepartureInfo: builder.mutation({
      query: ({ formData, id }) => {
        let data = Object.fromEntries(
          Object.entries(formData)
            .filter(([_, v]) => v != null)
            .filter(([_, v]) => v !== "")
            .filter(([_, v]) => v !== undefined)
        );
        console.log(formData);
        let webKitFormData = new FormData();

        ["itineraries"].forEach((e) => delete data[e]);
        Object.keys(data).forEach((item, i) => {
          webKitFormData.append(item, data[item]);
        });
        webKitFormData.append("_method", "put");

        return {
          url: `/travel/departure/edit/${id}`,
          method: "POST",
          headers: {
            Accept: "application/json",
          },
          body: webKitFormData,
        };
      },
      invalidatesTags: ["Departure", "DepartureById"],
    }),
  }),
});
export const {
  useGetDepartureListQuery,
  useGetDepartureByIdQuery,
  useGetDepartureByPackageIdQuery,
  useGetDeletedDepartureListQuery,
  useDeleteDepartureMutation,
  useCreateDepartureMutation,
  useUpdateDepartureInfoMutation,
} = departureApiSlice;
