import {
  Alert,
  Anchor,
  Col,
  Form,
  Input,
  InputNumber,
  Menu,
  Row,
  Select,
  Spin,
  Switch,
  TreeSelect,
  Upload,
} from "antd";
import React, { useEffect, useState } from "react";

import slugify from "react-slugify";
import ImageField from "../Core/ImageField";
import TextEditor from "../Core/ReactTinymce/ReactTinymce";
import ImageCrop from "../ImageCrop";

//import "antd/dist/antd.css";
import { UploadOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../lib/urls";
import {
  useGetCategoryListByTypeKeyQuery,
  useGetCategoryListQuery,
} from "../../redux/slice/category/categoryApiSlice";
import { useGetPackageListQuery } from "../../redux/slice/package/packageApiSlice";
import { useGetTripInfoListQuery } from "../../redux/slice/tripInfo/tripInfoApiSlice";
import SpeedoMeter from "./../SpeedoMeter";

import axios from "axios";
import { cleanFormValues } from "../../utils/cleanValues";
import DraggableMediaManager from "../MediaManager/DraggableMediaManager";
import DepartureForm from "./DepartureForm";
import ItineraryForm from "./ItineraryForm";

const { Option } = Select;
const { SHOW_CHILD } = TreeSelect;
const { TreeNode } = TreeSelect;
const { Link } = Anchor;

const PackageForm = ({
  selectedForm,
  createEntity,
  updateEntityInfo,
  isAddForm,
  isUpdateForm,
  params,
  setUpdatedRecentlyToFalse,
  data,
}) => {
  let navigate = useNavigate();
  const [package_detail, setPackageDetails] = useState();
  const [package_cost_includes, setPackageCostIncludes] = useState();
  const [package_cost_excludes, setPackageCostExcludes] = useState();
  const [package_highlights, setPackageHighlights] = useState();
  const [package_departure_note, setPackageDepatureNote] = useState();
  const [package_useful_info, setPackageUsefulInfo] = useState();
  const [featured_image, setFeaturedImage] = useState(
    isUpdateForm &&
      data.dataById &&
      data.dataById.featured_image &&
      data.dataById.featured_image.full_path
  );

  const [bannerImage, setBannerImage] = useState();
  const [mapImage, setMapImage] = useState();
  const [image_crop_open, setImageCropOpen] = useState(false);

  const [categoriesIDList, setCategoriesIDList] = useState();
  const [selectedBanners, setSelectedBanners] = useState([]);
  const [currentMenu, setCurrentMenu] = useState("overview");

  useEffect(() => {
    if (isUpdateForm) {
      let banners = data.dataById?.banners
        ?.map((item) => item.media)
        .filter((item) => item !== null);
      setSelectedBanners(banners);
    }
  }, [data.dataById, isUpdateForm]);

  const [form] = Form.useForm();
  const { setFieldsValue } = form;

  const { data: tripinfoData, isLoading: tripinfoLoading } = useGetTripInfoListQuery();
  const { data: categoryListData, isLoading: categoryListLoading } = useGetCategoryListQuery();
  const { data: packageListData, isLoading: packageListLoading } = useGetPackageListQuery();

  const {
    data: faqsList,
    isLoading: faqsListLoading,
    isError: faqsListError,
  } = useGetCategoryListByTypeKeyQuery({
    type_key: "faqs",
  });

  const loading =
    selectedForm.loading ||
    categoryListLoading ||
    faqsListLoading ||
    tripinfoLoading ||
    packageListLoading;

  useEffect(() => {
    if (categoryListData) {
      setCategoriesIDList(categoryListData.filter((e) => e.type.category_key === "destination"));
    }
  }, [categoryListData]);

  const packageDestination = categoryListData
    ?.filter((e) => e.type.category_key === "destination")
    .map((a) => {
      return { value: a.id, label: a.title };
    });
  const packageGrade = categoryListData
    ?.filter((e) => e.type.category_key === "trip_gradings")
    .map((a) => {
      return { value: a.id, label: a.title };
    });
  const packageStyles = categoryListData
    ?.filter((e) => e.type.category_key === "trip_style")
    .map((a) => {
      return { value: a.id, label: a.title };
    });
  const packageTransportation = categoryListData
    ?.filter((e) => e.type.category_key === "transportation")
    .map((a) => {
      return { value: a.id, label: a.title };
    });
  const packageAccommodation = categoryListData
    ?.filter((e) => e.type.category_key === "accommodation")
    .map((a) => {
      return { value: a.id, label: a.title };
    });
  const packageMeals = categoryListData
    ?.filter((e) => e.type.category_key === "meals")
    .map((a) => {
      return { value: a.id, label: a.title };
    });

  const tripinfoOptions = tripinfoData?.map((a) => {
    return { value: a.id, label: a.page_title };
  });

  let faqsOptions = faqsList?.map((itm, idx) => {
    const { id, title, faqs } = itm;
    return {
      key: `0-${idx}`,
      value: `0-${idx}`,
      label: title,
      ...(faqs && {
        children: faqs?.map((child, jdx) => {
          const { id, question } = child;
          return {
            value: id,
            key: `0-${idx}-${jdx}`,
            label: question,
          };
        }),
      }),
    };
  });

  let categoriesOptions = categoriesIDList?.map((itm) => {
    const { id, title, children } = itm;
    return {
      value: id,
      label: title,
      ...(children && {
        children: children?.map((child) => {
          const { id, title, children } = child;
          return {
            value: id,
            label: title,
            ...(children && {
              children: children?.map((gChild) => {
                const { id, title } = gChild;
                return {
                  value: id,
                  label: title,
                };
              }),
            }),
          };
        }),
      }),
    };
  });

  const package_list_options = packageListData?.map((itm) => {
    return {
      label: itm.package_title,
      value: itm.id,
    };
  });

  const items = [
    {
      key: "overview",
      label: "Overview",
    },
    ...(isUpdateForm
      ? [
          {
            key: "inclusion",
            label: "What's included",
          },
          {
            key: "photos",
            label: "Photos",
          },
          {
            key: "facts",
            label: "Facts & Info",
          },
          {
            key: "extra_info",
            label: "Others",
          },

          {
            key: "itinerary",
            label: "Itinerary",
          },
          {
            key: "availability",
            label: "Pricing & Availability",
          },
        ]
      : []),
  ];

  const onClick = (e) => {
    setCurrentMenu(e.key);
  };

  if (isUpdateForm) {
    setFieldsValue({
      package_title: data.dataById?.package_title,
      url_title: data.dataById?.urlinfo?.url_title,
      slug: data.dataById?.urlinfo?.url_slug,
      canonical: data.dataById?.urlinfo?.canonical,
      package_details: data.dataById?.package_details,
      package_highlights: data.dataById?.package_highlights,
      package_cost_includes: data.dataById?.package_cost_includes,
      package_cost_excludes: data.dataById?.package_cost_excludes,
      package_price: data.dataById?.package_price,
      package_discount: data.dataById?.package_discount,
      package_duration: data.dataById?.package_duration,
      package_duration_type: data.dataById?.package_duration_type,
      status: data.dataById?.status === 1 ? true : false,
      is_featured: data.dataById?.is_featured === 1 ? true : false,
      package_destination: data.dataById?.package_destination,
      meta_title: data.dataById?.meta?.meta_title,
      meta_keywords: data.dataById?.meta?.meta_keywords,
      meta_description: data.dataById?.meta?.meta_description,
      featured_image: data.dataById?.featured?.thumb_path,
      featured_alt: data.dataById?.featured?.alt_text,
      featured_caption: data.dataById?.featured?.caption,
      package_grade: data.dataById?.package_grade,
      package_style: data.dataById?.package_style,
      package_transportation: data.dataById?.package_transportation,
      package_meals: data.dataById?.package_meals,
      package_accommodation: data.dataById?.package_accommodation,
      package_group_size: data.dataById?.package_group_size,
      package_max_altitude: data.dataById?.package_max_altitude,
      facts: data.dataById?.additional_facts,
      package_departure_note: data.dataById?.package_departure_note,
      categories: data.dataById?.categories?.map((a) => a.id),
      custom_related: data.dataById?.custom_related?.map((a) => a.id),
      trip_info: data.dataById?.tripinfo?.map((a) => a.id),
      package_trip_info: data.dataById?.package_trip_info,
      group_faqs: data.dataById?.groupfaqs?.map((a) => a.id),
      banner_alt: data.dataById?.banner?.alt_text,
      banner_caption: data.dataById?.banner?.caption,
      banner_image: data.dataById?.banner?.full_path,
      package_map_image: data.dataById?.package_map_path,
      package_geo_latitude: data.dataById?.package_geo_latitude,
      package_geo_longitude: data.dataById?.package_geo_longitude,
      pricegroup: data.dataById?.pricegroup,
      cost_includes: data.dataById?.cost_includes,
      cost_excludes: data.dataById?.cost_excludes,
      additional_field_1: data.dataById?.additional_field_1,
      additional_field_2: data.dataById?.additional_field_2,
      package_abstract: data.dataById?.package_abstract,
      package_extra_faqs: data.dataById?.package_extra_faqs,
    });
  }

  const packageAction = (e, action) => {
    e.preventDefault();
    form.validateFields().then((formValues) => {
      let values = cleanFormValues(formValues);
      if (action === "add") {
        if (featured_image) {
          axios.get(featured_image, { responseType: "blob" }).then((response) => {
            createEntity({
              ...values,
              status: values.status ? 1 : 0,
              is_featured: values.is_featured ? 1 : 0,
              featured_image: response.data,
              ...(bannerImage && { banner_image: bannerImage.file }),
              ...(mapImage && { package_map_image: mapImage.file }),
            });
          });
        } else {
          createEntity({
            ...values,
            status: values.status ? 1 : 0,
            is_featured: values.is_featured ? 1 : 0,
            ...(bannerImage && { banner_image: bannerImage.file }),
            ...(mapImage && { package_map_image: mapImage.file }),
          });
        }
      } else if (action === "addandexit") {
        if (featured_image) {
          axios.get(featured_image, { responseType: "blob" }).then((response) => {
            createEntity({
              ...values,
              status: values.status ? 1 : 0,
              is_featured: values.is_featured ? 1 : 0,
              featured_image: response.data,
              ...(bannerImage && { banner_image: bannerImage.file }),
              ...(mapImage && { package_map_image: mapImage.file }),
            })
              .unwrap()
              .then(() => navigate("/packages"))
              .catch();
          });
        } else {
          createEntity({
            ...values,
            status: values.status ? 1 : 0,
            is_featured: values.is_featured ? 1 : 0,
            ...(bannerImage && { banner_image: bannerImage.file }),
            ...(mapImage && { package_map_image: mapImage.file }),
          })
            .unwrap()
            .then(() => navigate("/packages"))
            .catch();
        }
      } else if (action === "update") {
        if (featured_image) {
          axios.get(featured_image, { responseType: "blob" }).then((response) => {
            updateEntityInfo({
              formData: {
                ...values,
                status: values.status ? 1 : 0,
                is_featured: values.is_featured ? 1 : 0,
                featured_image: response.data,
                ...(bannerImage && { banner_image: bannerImage.file }),
                ...(mapImage && { package_map_image: mapImage.file }),
                ...(selectedBanners && {
                  banners_media: selectedBanners.map((item) => item.id),
                }),
              },
              id: params.id,
            });
          });
        } else {
          updateEntityInfo({
            formData: {
              ...values,
              status: values.status ? 1 : 0,
              is_featured: values.is_featured ? 1 : 0,
              ...(bannerImage && { banner_image: bannerImage.file }),
              ...(mapImage && { package_map_image: mapImage.file }),
              ...(selectedBanners && {
                banners_media: selectedBanners.map((item) => item.id),
              }),
            },
            id: params.id,
          });
        }
      }
    });
  };

  const addNewEntry = (e) => {
    packageAction(e, "add");
  };

  const addNewEntryAndExistToList = (e) => {
    packageAction(e, "addandexit");
  };

  const updateExistingEntry = (e) => {
    packageAction(e, "update");
  };

  const clearForm = () => {
    form.resetFields();
    setPackageDetails("");
    setFeaturedImage("");
    setPackageHighlights("");
    setPackageCostIncludes("");
    setPackageCostExcludes("");
    setPackageDepatureNote("");
    setPackageUsefulInfo("");
  };

  const actionBarOptions = {
    titleToAdd: "Add Package",
    titleToUpdate: isUpdateForm && data.dataById?.package_title,
    taskCompleteMessageDisplayTime: 2000,
    addButtonLabel: "Save & Add New",
    updateButtonLabel: "Update",
    discardLink: "packages",
    pageUrl: isUpdateForm && "package/" + data.dataById?.urlinfo?.url_slug,
    addNewEntry,
    updateExistingEntry,
    loading,
    isAddForm,
    isUpdateForm,
    selectedForm,
    clearForm,
    setUpdatedRecentlyToFalse,
    addNewEntryAndExistToList,
  };

  return (
    <div className="container">
      <Form
        form={form}
        size="large"
        layout="vertical"
        className="custom-form"
        wrapperCol={{
          flex: 1,
        }}
      >
        {/* <DashboardActionBar {...{ actionBarOptions }} /> */}
        <div className="common-module mb-0">
          <div className="action-bar">
            <div className="action-bar-left">
              <span onClick={() => navigate(`/${actionBarOptions.discardLink}`)}>
                Back to package list
              </span>
              <div className="action-bar-title">
                <button
                  className="btn btn-discard"
                  onClick={() => navigate(`/${actionBarOptions.discardLink}`)}
                >
                  <i class="bi-arrow-left-short"></i>
                </button>

                {isAddForm && <h1>{actionBarOptions.titleToAdd}</h1>}
                {isUpdateForm && (
                  <>
                    <h1>{actionBarOptions.titleToUpdate}</h1>
                    <a
                      href={process.env.REACT_APP_FRONT_URL + actionBarOptions.pageUrl}
                      rel="noreferrer"
                      target="_blank"
                    >
                      <i className="bi-link-45deg"></i>
                    </a>
                  </>
                )}
              </div>
            </div>
            <div className="action-bar-right">
              {isUpdateForm && !loading && (
                <SpeedoMeter
                  score={
                    15 +
                    (form.getFieldValue("package_details") ? 5 : 0) +
                    (form.getFieldValue("pricegroup")?.length ? 2 : 0) +
                    (form.getFieldValue("meta_description") ? 3 : 0) +
                    (form.getFieldValue("package_cost_includes") ||
                    form.getFieldValue("cost_includes")?.length
                      ? 5
                      : 0) +
                    (form.getFieldValue("package_cost_excludes") ||
                    form.getFieldValue("cost_excludes")?.length
                      ? 5
                      : 0) +
                    (selectedBanners?.length > 0
                      ? 5
                      : 0 + data.dataById?.featured?.full_path
                      ? 10
                      : 0 + data.dataById?.banner?.full_path
                      ? 5
                      : 0) +
                    ((form.getFieldValue("package_grade") ? 1 : 0) +
                      (form.getFieldValue("package_style") ? 1 : 0) +
                      (form.getFieldValue("package_transportation") ? 1 : 0) +
                      (form.getFieldValue("package_highlights") ? 2 : 0) +
                      (form.getFieldValue("package_trip_info") ? 2 : 0) +
                      (form.getFieldValue("group_faqs")?.length ? 3 : 0)) +
                    (data.dataById.has_departure ? 15 : 0) +
                    (data.dataById.has_itinerary ? 20 : 0)
                  }
                  totalScore={100}
                />
              )}
            </div>
          </div>
        </div>

        <div className="common-module mb-0">
          <div className="fis-nav-tabs">
            <Menu onClick={onClick} selectedKeys={[currentMenu]} mode="horizontal" items={items} />
          </div>
        </div>

        {selectedForm?.error?.errors && (
          <Alert
            className="custom-alert"
            message="Error"
            showIcon
            description={[
              <ul>
                {Object.values(selectedForm?.error?.errors).map((itm, idx) => {
                  return <li key={idx}>{itm}</li>;
                })}
              </ul>,
            ]}
            type="error"
            closable
          />
        )}

        <Spin spinning={loading} delay={500}>
          <div
            className={`common-module mb-0 bg-white border step-content ${
              currentMenu === "overview" && "active"
            }`}
          >
            <div className="inner-action-bar header">
              <div className="left-content">
                <h2>Details</h2>
                {isUpdateForm && !loading && (
                  <SpeedoMeter
                    score={
                      15 +
                      (form.getFieldValue("package_details") ? 5 : 0) +
                      (form.getFieldValue("pricegroup")?.length ? 2 : 0) +
                      (form.getFieldValue("meta_description") ? 3 : 0)
                    }
                    totalScore={25}
                  />
                )}
              </div>
              <div className="right-content">
                <div className="btn-group">
                  {isAddForm && (
                    <button
                      disabled={actionBarOptions.loading}
                      htmlType="submit"
                      className="btn btn-primary"
                      onClick={actionBarOptions.addNewEntryAndExistToList}
                      id="add_new"
                    >
                      <i className="bi bi-save2"></i>Save Package
                    </button>
                  )}
                  {isUpdateForm && (
                    <button
                      disabled={actionBarOptions.loading}
                      htmlType="submit"
                      className="btn btn-primary"
                      onClick={actionBarOptions?.updateExistingEntry}
                    >
                      <i className="bi bi-save2"></i>Update Package
                    </button>
                  )}
                </div>
              </div>
            </div>

            <div className="module-container">
              <div className="module-container-left">
                <Anchor offsetTop={24} className="fis-anchor">
                  <Link href="#information" title="Basic information" />
                  <Link href="#categorization" title="Categorization" />
                  <Link href="#pricing" title="Pricing" />
                  <Link href="#meta" title="Meta Details" />
                  <Link href="#introduction" title="Introduction" />
                  <Link href="#status" title="Status" />
                </Anchor>
              </div>
              <div className="module-container-right">
                <div id="information">
                  <h3>Basic Information</h3>

                  <Row gutter={{ xs: 8, sm: 10 }}>
                    <Col className="gutter-row" xs={24} md={24} xl={18}>
                      <Form.Item
                        label="Package Title"
                        name="package_title"
                        rules={[
                          {
                            required: true,
                            message: "Package title is required",
                          },
                          {
                            min: 5,
                            max: 255,
                            message: "Invalid package title",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={{ xs: 8, sm: 10 }}>
                    <Col className="gutter-row" xs={24} md={12} lg={8} xl={6}>
                      <Form.Item
                        label="Url Title"
                        name="url_title"
                        rules={[
                          {
                            required: true,
                            message: "Url title is required",
                          },
                          {
                            min: 5,
                            max: 255,
                            message: "Invalid package title",
                          },
                        ]}
                      >
                        <Input
                          onChange={(e) =>
                            form.setFieldsValue({
                              slug: slugify(e.target.value),
                            })
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} md={12} lg={8} xl={6}>
                      <Form.Item
                        // label={
                        //   <span className="ant-form-item-optional">
                        //     Slug
                        //   </span>
                        // }
                        label="Slug"
                        name="slug"
                        title="Auto Generate from Url Title"
                        rules={[
                          {
                            required: true,
                            message: "Slug is required",
                          },
                        ]}
                      >
                        <Input readOnly />
                      </Form.Item>
                    </Col>

                    <Col className="gutter-row" xs={24} md={24} lg={8} xl={6}>
                      <Form.Item label="Canonical" name="canonical" title="Canonical">
                        <Input
                        // onChange={(e) =>
                        //   setTimeout(() => {
                        //     form.setFieldsValue({
                        //       canonical: slugify(e.target.value),
                        //     });
                        //   }, 3000)
                        // }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
                <div id="categorization">
                  <h3>Categorization</h3>
                  <Row gutter={{ xs: 8, sm: 10 }}>
                    <Col className="gutter-row" xs={24} sm={12} xl={9}>
                      <Form.Item
                        label="Package Durations"
                        name="package_duration"
                        rules={[
                          {
                            required: true,
                            message: "Package Duration is required",
                          },
                        ]}
                      >
                        <InputNumber
                          addonAfter={
                            <Form.Item noStyle name="package_duration_type" initialValue="days">
                              <Select style={{ width: 100 }}>
                                <Option value="days">Days</Option>
                                <Option value="hrs">Hours</Option>
                              </Select>
                            </Form.Item>
                          }
                        />
                      </Form.Item>
                    </Col>

                    <Col className="gutter-row" xs={24} sm={12} xl={9}>
                      <Form.Item
                        label="Package Destination"
                        name="package_destination"
                        rules={[
                          {
                            required: true,
                            message: "Package Destination is required",
                          },
                        ]}
                      >
                        <Select>
                          {packageDestination?.map((itm, idx) => {
                            return (
                              <Option key={idx} value={itm.value}>
                                {itm.label}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} xl={18}>
                      <Form.Item
                        label="Categories"
                        name="categories"
                        rules={[
                          {
                            required: true,
                            message: "Category is required",
                          },
                          {
                            type: "array",
                          },
                        ]}
                      >
                        <TreeSelect
                          showSearch
                          style={{ width: "100%" }}
                          dropdownStyle={{
                            maxHeight: 400,
                            overflow: "auto",
                          }}
                          placeholder="Please select"
                          allowClear
                          multiple
                          treeDefaultExpandAll
                        >
                          {categoriesOptions?.map((parent) => {
                            const { value, label, children } = parent;
                            return (
                              <TreeNode key={value} value={value} title={label}>
                                {children.length !== 0 &&
                                  children?.map((child) => {
                                    const { value, label, children } = child;
                                    return (
                                      <TreeNode key={value} value={value} title={label}>
                                        {children.length !== 0 &&
                                          children?.map((gChild) => {
                                            const { value, label } = gChild;
                                            return (
                                              <TreeNode key={value} value={value} title={label} />
                                            );
                                          })}
                                      </TreeNode>
                                    );
                                  })}
                              </TreeNode>
                            );
                          })}
                        </TreeSelect>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>

                <div id="pricing" className="group-price departures">
                  <h3>Group Price</h3>
                  <Form.List name="pricegroup">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map((field, index) => (
                          <>
                            <div className="ant-form-item-label">
                              <label>Group Price #{index + 1}</label>{" "}
                              <span className="btn-delate" onClick={() => remove(field.name)}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  class="bi bi-trash"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                  <path
                                    fill-rule="evenodd"
                                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                  />
                                </svg>{" "}
                                Delete
                              </span>
                            </div>

                            <Row gutter={{ xs: 5, sm: 5, md: 5 }} key={field.key} align="baseline">
                              <Col md={6} xs={12}>
                                <Form.Item
                                  {...field}
                                  name={[field.name, "min_people"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Min People is required",
                                    },
                                  ]}
                                >
                                  <InputNumber min={1} placeholder="Min people*" />
                                </Form.Item>
                              </Col>
                              <Col md={6} xs={12}>
                                <Form.Item
                                  {...field}
                                  name={[field.name, "max_people"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Max People is required",
                                    },
                                  ]}
                                >
                                  <InputNumber placeholder="Max People*" />
                                </Form.Item>

                                {isUpdateForm && (
                                  <Form.Item
                                    hidden
                                    {...field}
                                    initialValue={0}
                                    name={[field.name, "id"]}
                                  >
                                    <InputNumber />
                                  </Form.Item>
                                )}
                              </Col>
                              <Col md={6} xs={12}>
                                <Form.Item
                                  {...field}
                                  name={[field.name, "unit_price"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Price is required",
                                    },
                                  ]}
                                >
                                  <InputNumber min={1} placeholder="Price*" initialValue={0} />
                                </Form.Item>
                              </Col>
                              <Col md={6} xs={12} hidden>
                                <Form.Item {...field} name={[field.name, "short_description"]}>
                                  <Input />
                                </Form.Item>
                              </Col>

                              <Col md={6} xs={12} hidden>
                                <Form.Item {...field} name={[field.name, "offer_unit_price"]}>
                                  <InputNumber />
                                </Form.Item>
                              </Col>

                              <Col md={6} xs={12} hidden>
                                <Form.Item {...field} name={[field.name, "offer_label"]}>
                                  <Input />
                                </Form.Item>
                              </Col>

                              <Col md={6} xs={12} hidden>
                                <Form.Item {...field} name={[field.name, "offer_information"]}>
                                  <Input />
                                </Form.Item>
                              </Col>
                              <Col md={6} xs={12}>
                                <div className="switch-group">
                                  <Form.Item
                                    valuePropName="checked"
                                    {...field}
                                    name={[field.name, "is_default"]}
                                  >
                                    <Switch
                                      checkedChildren="Default Price"
                                      unCheckedChildren="Not Default Price"
                                    />
                                  </Form.Item>
                                </div>
                              </Col>
                            </Row>
                          </>
                        ))}
                        <Col>
                          <Form.Item>
                            <button
                              type="button"
                              onClick={() => add()}
                              className="btn btn-secondary font-weight-medium"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="currentColor"
                                className="bi bi-plus"
                                viewBox="0 0 16 16"
                              >
                                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                              </svg>
                              Add grop & price
                            </button>
                          </Form.Item>
                        </Col>
                      </>
                    )}
                  </Form.List>
                  <Form.Item
                    label="Package Price"
                    name="package_price"
                    initialValue={0}
                    hidden
                    rules={[
                      {
                        required: true,
                        message: "Package Price is required",
                      },
                    ]}
                  >
                    <InputNumber
                      addonAfter={
                        <Select defaultValue="USD">
                          <Option value="USD">$</Option>
                          <Option value="EUR">€</Option>
                          <Option value="GBP">£</Option>
                          <Option value="AUD">A$</Option>
                        </Select>
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    label="Package Discount"
                    name="package_discount"
                    initialValue={0}
                    hidden
                  >
                    <InputNumber />
                  </Form.Item>
                </div>

                <div id="meta" className="mb-3">
                  <h3>Meta</h3>
                  <Row gutter={{ xs: 8, sm: 10 }}>
                    <Col className="gutter-row" xs={24} xl={18}>
                      <Form.Item
                        label="Meta Title"
                        name="meta_title"
                        rules={[
                          {
                            required: true,
                            message: "Meta title is required",
                          },
                          {
                            min: 5,
                            max: 255,
                            message: "Invalid meta title",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>

                    <Col className="gutter-row" xs={24} xl={18} hidden>
                      <Form.Item
                        label="Meta Keywords"
                        name="meta_keywords"
                        rules={[
                          {
                            min: 5,
                            max: 255,
                            message: "Invalid meta keywords",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>

                    <Col className="gutter-row" xs={24} xl={18}>
                      <Form.Item label="Meta Description" name="meta_description">
                        <TextArea rows={8} />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>

                <div id="introduction">
                  <h3>Introduction</h3>
                  <Row gutter={{ xs: 8, sm: 10 }}>
                    <Col className="gutter-row" xs={24} xl={18}>
                      <Form.Item label="Abstract" name="package_abstract">
                        <TextArea rows={3} />
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} xl={24}>
                      <Form.Item name="package_details" label="Details">
                        <TextEditor
                          value={package_detail}
                          initialValue={isUpdateForm && package_detail}
                          onEditorChange={(e) => {
                            setPackageDetails(e.target.value);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>

                <div id="status">
                  <h3>Tour Status</h3>
                  <Col className="gutter-row" xs={24} xl={12}>
                    <div className="switch-group">
                      <Form.Item label="Status" valuePropName="checked" name="status">
                        <Switch checkedChildren="Publish" unCheckedChildren="Unpublish" />
                      </Form.Item>
                      <Form.Item label="Is Featured?" valuePropName="checked" name="is_featured">
                        <Switch checkedChildren="Featured" unCheckedChildren="Not Featured" />
                      </Form.Item>
                    </div>
                  </Col>
                </div>
              </div>
            </div>

            <div className="inner-action-bar footer">
              <div className="right-content ml-auto">
                <div className="btn-group">
                  {isAddForm && (
                    <button
                      disabled={actionBarOptions.loading}
                      htmlType="submit"
                      className="btn btn-primary"
                      onClick={actionBarOptions.addNewEntryAndExistToList}
                      id="add_new"
                    >
                      <i className="bi bi-save2"></i>Save Package
                    </button>
                  )}
                  {isUpdateForm && (
                    <button
                      disabled={actionBarOptions.loading}
                      htmlType="submit"
                      className="btn btn-primary"
                      onClick={actionBarOptions?.updateExistingEntry}
                    >
                      <i className="bi bi-save2"></i>Update Package
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>

          {isUpdateForm && (
            <div
              className={`common-module mb-0 bg-white border step-content ${
                currentMenu === "inclusion" && "active"
              }`}
            >
              <div className="inner-action-bar header">
                <div className="left-content">
                  <h2>Inclusion</h2>
                  {isUpdateForm && !loading && (
                    <SpeedoMeter
                      score={
                        ((form.getFieldValue("package_cost_includes") ||
                          form.getFieldValue("cost_includes").length) &&
                          5) +
                        ((form.getFieldValue("package_cost_excludes") ||
                          form.getFieldValue("cost_excludes").length) &&
                          5)
                      }
                      totalScore={10}
                    />
                  )}
                </div>
                <div className="right-content">
                  <div className="btn-group">
                    <button
                      disabled={actionBarOptions.loading}
                      htmlType="submit"
                      className="btn btn-primary"
                      onClick={actionBarOptions.updateExistingEntry}
                      id="add_new"
                    >
                      <i className="bi bi-save2"></i>Save
                    </button>
                  </div>
                </div>
              </div>

              <div className="module-container">
                <div className="module-container-left">
                  <Anchor offsetTop={24} className="fis-anchor">
                    <Link href="#includes" title="Cost Includes" />
                    <Link href="#excludes" title="Cost Exclude" />
                  </Anchor>
                </div>
                <div className="module-container-right">
                  <div id="includes" className="mb-3">
                    <h3>Cost Include</h3>
                    <Row gutter={{ xs: 8, sm: 10 }}>
                      <Col className="gutter-row" xs={24} xl={18}>
                        <Form.Item
                          label="Cost Includes - default design"
                          name="package_cost_includes"
                        >
                          <TextEditor
                            simpleTollbar
                            smallSize
                            value={package_cost_includes}
                            initialValue={isUpdateForm && package_cost_includes}
                            onEditorChange={(e) => {
                              setPackageCostIncludes(e.target.value);
                            }}
                          />
                        </Form.Item>
                      </Col>

                      <Col className="gutter-row" xs={24} xl={18}>
                        <div className="common-module mb-0 departures">
                          <div className="ant-form-item-label w-100">
                            <label>Cost Includes - icon based design</label>
                          </div>

                          <Form.List name="cost_includes">
                            {(fields, { add, remove }) => (
                              <>
                                {fields.map((field, index) => (
                                  <>
                                    <div className="ant-form-item-label">
                                      <label>Cost Include #{index + 1}</label>
                                      <span
                                        className="btn-delate"
                                        onClick={() => remove(field.name)}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          className="bi bi-trash"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                          <path
                                            fill-rule="evenodd"
                                            d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                          />
                                        </svg>
                                        Delete
                                      </span>
                                    </div>

                                    <Row
                                      gutter={{ xs: 15, sm: 15, md: 15 }}
                                      key={field.key}
                                      align="baseline"
                                    >
                                      <Col md={6} xs={6}>
                                        <Form.Item
                                          {...field}
                                          name={[field.name, "detail_key"]}
                                          label="Key"
                                          rules={[
                                            {
                                              required: true,
                                              message: "Key is required",
                                            },
                                          ]}
                                        >
                                          <Select placeholder="Key">
                                            <Option value="others" label="Others">
                                              Others
                                            </Option>
                                            <Option value="accommodation" label="Accommodation">
                                              Accommodation
                                            </Option>
                                            <Option value="guide" label="Guide">
                                              Guide
                                            </Option>
                                            <Option value="meals" label="Meals">
                                              Meals
                                            </Option>
                                            <Option
                                              value="additional_services"
                                              label="Additional Services"
                                            >
                                              Additional Services
                                            </Option>
                                            <Option value="transport" label="Transport">
                                              Transport
                                            </Option>
                                          </Select>
                                        </Form.Item>
                                      </Col>
                                      <Col md={12} xs={18}>
                                        <Form.Item
                                          label="Title"
                                          {...field}
                                          name={[field.name, "detail_title"]}
                                          rules={[
                                            {
                                              required: true,
                                              message: "Title is required",
                                            },
                                          ]}
                                        >
                                          <Input placeholder="Title*" />
                                        </Form.Item>

                                        {isUpdateForm && (
                                          <Form.Item
                                            hidden
                                            {...field}
                                            initialValue={0}
                                            name={[field.name, "id"]}
                                          >
                                            <InputNumber />
                                          </Form.Item>
                                        )}
                                      </Col>
                                      <Col md={24} xs={24}>
                                        <Form.Item
                                          {...field}
                                          name={[field.name, "detail_description"]}
                                        >
                                          <TextEditor simpleTollbar inline placeholder="Details" />
                                        </Form.Item>
                                      </Col>
                                    </Row>
                                  </>
                                ))}
                                <Col>
                                  <Form.Item>
                                    <button
                                      type="button"
                                      onClick={() => add()}
                                      className="btn btn-sm btn-secondary font-weight-medium"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        fill="currentColor"
                                        className="bi bi-plus"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                      </svg>
                                      Add New
                                    </button>
                                  </Form.Item>
                                </Col>
                              </>
                            )}
                          </Form.List>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <div id="excludes" className="mb-3">
                    <h3>Cost Exclude</h3>
                    <Row gutter={{ xs: 8, sm: 10 }}>
                      <Col className="gutter-row" xs={24} xl={18}>
                        <Form.Item label="Cost Exclude" name="package_cost_excludes">
                          <TextEditor
                            simpleTollbar
                            smallSize
                            value={package_cost_excludes}
                            initialValue={isUpdateForm && package_cost_excludes}
                            onEditorChange={(e) => {
                              setPackageCostExcludes(e.target.value);
                            }}
                          />
                        </Form.Item>
                      </Col>

                      <Col className="gutter-row" xs={24} xl={18}>
                        <div className="common-module mb-0 departures">
                          <div className="ant-form-item-label w-100">
                            <label>Cost Exclude - icon based design</label>
                          </div>

                          <Form.List name="cost_excludes">
                            {(fields, { add, remove }) => (
                              <>
                                {fields.map((field, index) => (
                                  <>
                                    <div className="ant-form-item-label">
                                      <label>Cost Exclude #{index + 1}</label>
                                      <span
                                        className="btn-delate"
                                        onClick={() => remove(field.name)}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          className="bi bi-trash"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                          <path
                                            fill-rule="evenodd"
                                            d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                          />
                                        </svg>
                                        Delete
                                      </span>
                                    </div>

                                    <Row
                                      gutter={{ xs: 15, sm: 15, md: 15 }}
                                      key={field.key}
                                      align="baseline"
                                    >
                                      <Col md={6} xs={6}>
                                        <Form.Item
                                          {...field}
                                          name={[field.name, "detail_key"]}
                                          label="Key"
                                          rules={[
                                            {
                                              required: true,
                                              message: "Key is required",
                                            },
                                          ]}
                                        >
                                          <Select placeholder="Key">
                                            <Option value="others" label="Others">
                                              Others
                                            </Option>
                                            <Option value="accommodation" label="Accommodation">
                                              Accommodation
                                            </Option>
                                            <Option value="guide" label="Guide">
                                              Guide
                                            </Option>
                                            <Option value="meals" label="Meals">
                                              Meals
                                            </Option>
                                            <Option
                                              value="additional_services"
                                              label="Additional Services"
                                            >
                                              Additional Services
                                            </Option>
                                            <Option value="transport" label="Transport">
                                              Transport
                                            </Option>
                                          </Select>
                                        </Form.Item>
                                      </Col>
                                      <Col md={12} xs={18}>
                                        <Form.Item
                                          label="Title"
                                          {...field}
                                          name={[field.name, "detail_title"]}
                                          rules={[
                                            {
                                              required: true,
                                              message: "Title is required",
                                            },
                                          ]}
                                        >
                                          <Input placeholder="Title*" />
                                        </Form.Item>

                                        {isUpdateForm && (
                                          <Form.Item
                                            hidden
                                            {...field}
                                            initialValue={0}
                                            name={[field.name, "id"]}
                                          >
                                            <InputNumber />
                                          </Form.Item>
                                        )}
                                      </Col>
                                      <Col md={24} xs={24}>
                                        <Form.Item
                                          {...field}
                                          name={[field.name, "detail_description"]}
                                        >
                                          <TextEditor simpleTollbar inline placeholder="Details" />
                                        </Form.Item>
                                      </Col>
                                    </Row>
                                  </>
                                ))}
                                <Col>
                                  <Form.Item>
                                    <button
                                      type="button"
                                      onClick={() => add()}
                                      className="btn btn-sm btn-secondary font-weight-medium"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        fill="currentColor"
                                        className="bi bi-plus"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                      </svg>
                                      Add New
                                    </button>
                                  </Form.Item>
                                </Col>
                              </>
                            )}
                          </Form.List>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>

              <div className="inner-action-bar footer">
                <div className="right-content ml-auto">
                  <div className="btn-group">
                    <button
                      disabled={actionBarOptions.loading}
                      htmlType="submit"
                      className="btn btn-primary"
                      onClick={actionBarOptions.addNewEntryAndExistToList}
                      id="add_new"
                    >
                      <i className="bi bi-save2"></i>Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {isUpdateForm && (
            <div
              className={`common-module mb-0 bg-white border step-content ${
                currentMenu === "photos" && "active"
              }`}
            >
              <div className="inner-action-bar header">
                <div className="left-content">
                  <h2>Photos</h2>
                  {isUpdateForm && !loading && (
                    <SpeedoMeter
                      score={
                        (selectedBanners?.length > 0 ? 5 : 0) +
                        (data.dataById?.featured?.full_path ? 10 : 0) +
                        (data.dataById?.banner?.full_path ? 5 : 0)
                      }
                      totalScore={20}
                    />
                  )}
                </div>
                <div className="right-content">
                  <div className="btn-group">
                    <button
                      disabled={actionBarOptions.loading}
                      htmlType="submit"
                      className="btn btn-primary"
                      onClick={actionBarOptions.updateExistingEntry}
                      id="add_new"
                    >
                      <i className="bi bi-save2"></i>Save
                    </button>
                  </div>
                </div>
              </div>

              <div className="module-container">
                <div className="module-container-left">
                  <Anchor offsetTop={24} className="fis-anchor">
                    <Link href="#featured" title="Featured Image" />
                    <Link href="#banner" title="Single Banner" />
                    <Link href="#gallery" title="Photo Gallery" />
                  </Anchor>
                </div>
                <div className="module-container-right">
                  <div id="featured" className="mb-3">
                    <h3>Featured</h3>
                    <Row gutter={{ xs: 8, sm: 10 }}>
                      <Col className="gutter-row" xs={24}>
                        <Form.Item>
                          <ImageField
                            //title="Featured Image"
                            image={
                              featured_image ||
                              (isUpdateForm &&
                                data.dataById &&
                                data.dataById?.featured &&
                                data.dataById?.featured?.full_path)
                            }
                            height={process.env.REACT_APP_PACKAGE_THUMBINAL_HEIGHT}
                            width={process.env.REACT_APP_PACKAGE_THUMBINAL_WIDTH}
                            setImage={(image_data) => {
                              setFeaturedImage(image_data);
                              setImageCropOpen(true);
                            }}
                          />
                          {featured_image && image_crop_open && (
                            <ImageCrop
                              height={process.env.REACT_APP_PACKAGE_THUMBINAL_HEIGHT}
                              width={process.env.REACT_APP_PACKAGE_THUMBINAL_WIDTH}
                              image={featured_image}
                              setCroppedImage={setFeaturedImage}
                              setImageCropOpen={setImageCropOpen}
                            />
                          )}
                        </Form.Item>
                      </Col>

                      <Col className="gutter-row" xs={24} xl={6}>
                        <Form.Item
                          label="Alt Text"
                          name="featured_alt"
                          rules={[{ min: 5, max: 50, message: "Invalid Alt Text" }]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>

                      <Col className="gutter-row" xs={24} xl={12}>
                        <Form.Item
                          label="Caption"
                          name="featured_caption"
                          rules={[{ min: 5, max: 50, message: "Invalid caption" }]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>

                  <div id="banner" className="mb-3">
                    <h3>Banner Image</h3>
                    <Row gutter={10}>
                      <Col xs={24}>
                        <Form.Item name="bannerImage" style={{ marginBottom: "0" }}>
                          <Upload
                            listType="picture-card"
                            maxCount={1}
                            name="tet"
                            beforeUpload={(file) => {
                              return false;
                            }}
                            onChange={(file) => setBannerImage(file)}
                          >
                            {isUpdateForm && data.dataById?.banner?.full_path && !bannerImage ? (
                              <img src={BASE_URL + data.dataById?.banner?.full_path} alt="" />
                            ) : (
                              <UploadOutlined style={{ fontSize: "27px" }} />
                            )}
                          </Upload>
                          <span className="text-muted">Size should be 1920x750</span>
                        </Form.Item>
                      </Col>

                      <Col xs={24} lg={6}>
                        <Form.Item label="Banner Alt" name="banner_alt">
                          <Input placeholder="Alt Text" />
                        </Form.Item>
                      </Col>

                      <Col xs={24} lg={12}>
                        <Form.Item label="Banner Caption" name="banner_caption">
                          <Input placeholder="banner caption" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>

                  <div id="gallery" className="mb-3">
                    <h3>Media Gallery</h3>
                    {/* <div className="media-display-slot">
                      <ul>
                        {selectedBanners?.length > 0 && (
                          <>
                            {selectedBanners.map((banner) => (
                              <li key={banner.id}>
                                <figure>
                                  <div className="image-slot">
                                    <img src={`${BASE_URL}${banner.full_path}`} alt="" />
                                  </div>
                                  <figcaption>
                                    <button
                                      onClick={() => handleDeleteBanners(banner.id)}
                                      className="btn-trash"
                                    >
                                      <i className="bi-trash"></i>
                                    </button>
                                  </figcaption>
                                </figure>
                              </li>
                            ))}
                          </>
                        )}
                        <li className="btn-media-add">
                          <MediaManager
                            isMultiple
                            onSelectItems={handleSelectItems}
                            mediaKeyType="trip-gallery"
                          />
                        </li>
                      </ul>
                    </div> */}
                    <DraggableMediaManager
                      selectedItems={selectedBanners}
                      setSelectedItems={setSelectedBanners}
                      mediaKeyType="trip-gallery"
                      isMultiple
                    />
                  </div>
                </div>
              </div>

              <div className="inner-action-bar footer">
                <div className="right-content ml-auto">
                  <div className="btn-group">
                    <button
                      disabled={actionBarOptions.loading}
                      htmlType="submit"
                      className="btn btn-primary"
                      onClick={actionBarOptions.updateExistingEntry}
                      id="add_new"
                    >
                      <i className="bi bi-save2"></i>Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {isUpdateForm && (
            <div
              className={`common-module mb-0 bg-white border step-content ${
                currentMenu === "facts" && "active"
              }`}
            >
              <div className="inner-action-bar header">
                <div className="left-content">
                  <h2>Facts & other Info</h2>
                  {isUpdateForm && !loading && (
                    <SpeedoMeter
                      score={
                        (form.getFieldValue("package_grade") ? 1 : 0) +
                        (form.getFieldValue("package_style") ? 1 : 0) +
                        (form.getFieldValue("package_transportation") ? 1 : 0) +
                        (form.getFieldValue("package_highlights") ? 2 : 0) +
                        (form.getFieldValue("package_trip_info") ? 2 : 0) +
                        (form.getFieldValue("group_faqs")?.length ? 3 : 0)
                      }
                      totalScore={10}
                    />
                  )}
                </div>
                <div className="right-content">
                  <div className="btn-group">
                    <button
                      disabled={actionBarOptions.loading}
                      htmlType="submit"
                      className="btn btn-primary"
                      onClick={actionBarOptions.updateExistingEntry}
                      id="add_new"
                    >
                      <i className="bi bi-save2"></i>Save
                    </button>
                  </div>
                </div>
              </div>

              <div className="module-container">
                <div className="module-container-left">
                  <Anchor offsetTop={24} className="fis-anchor">
                    <Link href="#facts" title="Trip Facts" />
                    <Link href="#highlights" title="Trip Highlights" />
                    <Link href="#departure-note" title="Departure Note" />
                    <Link href="#good-to-know" title="Good To Know" />
                    <Link href="#trip-note" title="Trip Note" />
                    <Link href="#trip-map" title="Trip Map" />
                    <Link href="#trip-faqs" title="Trip Faqs" />
                  </Anchor>
                </div>
                <div className="module-container-right">
                  <div id="facts" className="mb-3">
                    <h3>Trip Facts</h3>
                    <div className="trip_facts">
                      <Row gutter={{ xs: 8, sm: 12 }}>
                        <Col className="gutter-row" xs={24} sm={18} xl={9}>
                          <Form.Item label="Trip Grade" name="package_grade">
                            <Select>
                              {packageGrade?.map((itm, idx) => {
                                return (
                                  <Option key={idx} value={itm.value}>
                                    {itm.label}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col className="gutter-row" xs={24} sm={18} xl={9}>
                          <Form.Item label="Trip Style" name="package_style">
                            <Select>
                              {packageStyles?.map((itm, idx) => {
                                return (
                                  <Option key={idx} value={itm.value}>
                                    {itm.label}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} sm={18} xl={9}>
                          <Form.Item label="Status (ribbon)" name="additional_field_1">
                            <Select>
                              <Option value="Private Trip">Private Trip</Option>
                              <Option value="Guaranteed Departure">Guaranteed Departure</Option>
                              <Option value="On Sale">On Sale !</Option>
                              <Option value="Featured">Featured !</Option>
                              <Option value="Top Seller">Top Seller !</Option>
                              <Option value="Group Tours">Group Tours</Option>
                              <Option value="Best Price">Best Price</Option>
                              <Option value="Not Available">Not Available</Option>
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col className="gutter-row" xs={24} sm={18} xl={9}>
                          <Form.Item label="Transportation" name="package_transportation">
                            <Select>
                              {packageTransportation?.map((itm, idx) => {
                                return (
                                  <Option key={idx} value={itm.value}>
                                    {itm.label}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col className="gutter-row" xs={24} sm={18} xl={9}>
                          <Form.Item label="Accommodation" name="package_accommodation">
                            <Select>
                              {packageAccommodation?.map((itm, idx) => {
                                return (
                                  <Option key={idx} value={itm.value}>
                                    {itm.label}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col className="gutter-row" xs={24} sm={18} xl={9}>
                          <Form.Item label="Meals" name="package_meals">
                            <Select>
                              {packageMeals?.map((itm, idx) => {
                                return (
                                  <Option key={idx} value={itm.value}>
                                    {itm.label}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col className="gutter-row" xs={24} sm={18} xl={9}>
                          <Form.Item label="Group Size" name="package_group_size">
                            <InputNumber min="1" max="100" />
                          </Form.Item>
                        </Col>

                        <Col className="gutter-row" xs={24} sm={18} xl={9}>
                          <Form.Item label="Max Altitude" name="package_max_altitude">
                            <InputNumber min={1} max={8848} />
                          </Form.Item>
                        </Col>

                        <Form.List name="facts">
                          {(fields, { add, remove }) => (
                            <>
                              {fields.map((field, index) => (
                                <Col className="gutter-row" xs={24} sm={18}>
                                  <Row
                                    gutter={{ xs: 5, sm: 5, md: 5 }}
                                    key={field.key}
                                    align="baseline"
                                  >
                                    <span className="btn-delate" onClick={() => remove(field.name)}>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        class="bi bi-trash"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                        <path
                                          fill-rule="evenodd"
                                          d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                        />
                                      </svg>
                                    </span>
                                    <Col xs={8}>
                                      <Form.Item
                                        {...field}
                                        label={`Fact #${index + 1}`}
                                        name={[field.name, "fact_title"]}
                                      >
                                        <Input placeholder="Title" />
                                      </Form.Item>
                                    </Col>
                                    <Col xs={16}>
                                      <Form.Item
                                        {...field}
                                        label="&nbsp;"
                                        name={[field.name, "fact_value"]}
                                      >
                                        <Input placeholder="Value" />
                                      </Form.Item>
                                      {isUpdateForm && (
                                        <Form.Item
                                          hidden
                                          {...field}
                                          label="&nbsp;"
                                          initialValue={0}
                                          name={[field.name, "id"]}
                                        >
                                          <InputNumber />
                                        </Form.Item>
                                      )}
                                    </Col>
                                  </Row>
                                </Col>
                              ))}
                              <Col xs={24}>
                                <Form.Item>
                                  <button
                                    type="button"
                                    onClick={() => add()}
                                    className="btn btn-sm btn-secondary rounded font-weight-medium"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      fill="currentColor"
                                      className="bi bi-plus"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                    </svg>
                                    Add New Facts
                                  </button>
                                </Form.Item>
                              </Col>
                            </>
                          )}
                        </Form.List>
                      </Row>
                    </div>
                  </div>

                  <div id="highlights" className="mb-3">
                    <h3>Outline Itinerary</h3>
                    <Row gutter={10}>
                      <Col xs={24} lg={18}>
                        <Form.Item label="Highlights" name="package_highlights">
                          <TextEditor
                            value={package_highlights}
                            initialValue={isUpdateForm && package_highlights}
                            onEditorChange={(e) => {
                              setPackageHighlights(e.target.value);
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>

                  <div id="departure-note" className="mb-3">
                    <h3>Departure Note</h3>
                    <Row gutter={10}>
                      <Col xs={24} lg={18}>
                        <Form.Item label="Depature note" name="package_departure_note">
                          <TextEditor
                            simpleTollbar
                            smallSize
                            value={package_departure_note}
                            initialValue={isUpdateForm && package_departure_note}
                            onEditorChange={(e) => {
                              setPackageDepatureNote(e.target.value);
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>

                  <div id="good-to-know" className="mb-3">
                    <h3>Good to Know</h3>
                    <Row gutter={10}>
                      <Col xs={24} lg={18}>
                        <Form.Item label="Good to Know" name="package_trip_info">
                          <TextEditor
                            value={package_useful_info}
                            initialValue={isUpdateForm && package_useful_info}
                            onEditorChange={(e) => {
                              setPackageDetails(e.target.value);
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>

                  <div id="trip-note" className="mb-3">
                    <h3>Trip Note & alert</h3>
                    <Row gutter={10}>
                      <Col xs={24} lg={18}>
                        <Form.Item label="Trip Note" name="trip_info">
                          <Select
                            mode="multiple"
                            filterOption={(inputValue, option) =>
                              option.label?.toLowerCase().includes(inputValue?.toLowerCase())
                            }
                            placeholder="Useful Info"
                            optionLabelProp="label"
                          >
                            {tripinfoOptions?.map((itm) => {
                              return (
                                <Option value={itm.value} label={itm.label}>
                                  {itm.label}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={24} lg={18}>
                        <Form.Item label="Trip Notification" name="additional_field_2">
                          <TextEditor simpleTollbar inline placeholder="Details" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>

                  <div id="trip-map" className="mb-3">
                    <h3>Trip Map</h3>
                    <Row gutter={10}>
                      <Col xs={18}>
                        <Form.Item name="mapImage">
                          <Upload
                            listType="picture-card"
                            maxCount={1}
                            name="tet"
                            beforeUpload={(file) => {
                              return false;
                            }}
                            onChange={(file) => setMapImage(file)}
                          >
                            {isUpdateForm && data.dataById?.package_map_path && !mapImage ? (
                              <img src={BASE_URL + data.dataById?.package_map_path} alt="" />
                            ) : (
                              <UploadOutlined style={{ fontSize: "27px" }} />
                            )}
                          </Upload>
                          <span className="text-muted">Max image size 2MB</span>
                        </Form.Item>
                      </Col>
                      <Col lg={9} xxl={9}>
                        <Form.Item name="package_geo_longitude" label="Geo Longitude">
                          <InputNumber placeholder="longitude" />
                        </Form.Item>
                      </Col>
                      <Col lg={9} xxl={9}>
                        <Form.Item name="package_geo_latitude" label="Geo Latitude">
                          <InputNumber placeholder="latitude" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>

                  <div id="trip-faqs" className="mb-3">
                    <h3>Trip Faqs</h3>
                    <Row gutter={10}>
                      <Col xs={24} lg={18}>
                        <Form.Item label="Faqs" name="group_faqs">
                          <TreeSelect
                            showSearch
                            style={{ width: "100%" }}
                            dropdownStyle={{
                              maxHeight: 400,
                              overflow: "auto",
                            }}
                            placeholder="Please select"
                            treeCheckable
                            treeLine
                            treeIcon
                            showCheckedStrategy={SHOW_CHILD}
                          >
                            {faqsOptions?.map((parent) => {
                              const { value, label, children, key } = parent;
                              return (
                                <TreeNode key={key} value={value} title={label}>
                                  {children.length !== 0 &&
                                    children?.map((child, jdx) => {
                                      const { value, label, key } = child;
                                      return <TreeNode key={key} value={value} title={label} />;
                                    })}
                                </TreeNode>
                              );
                            })}
                          </TreeSelect>
                        </Form.Item>
                      </Col>
                      <Col xs={24} lg={18}>
                        <h3>Extra FAQS</h3>
                        <p className="help-text" style={{ fontSize: "14px", color: "#084c68" }}>
                          Title should be heading 3, question should be heading 4, and answer should
                          be paragraph
                        </p>

                        <Form.Item name="package_extra_faqs">
                          <TextEditor placeholder="" />
                        </Form.Item>

                        {/* <Form.List name="extra_faqs">
                          {(fields, { add, remove }) => {
                            return (
                              <>
                                {fields.map((field, index) => (
                                  <>
                                    <div className="ant-form-item-label">
                                      <label>Faqs #{index + 1}</label>
                                      <span
                                        className="btn-delate text-danger"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => remove(field.name)}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          className="bi bi-trash"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                          <path
                                            fill-rule="evenodd"
                                            d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                          />
                                        </svg>
                                      </span>
                                    </div>

                                    <Row
                                      gutter={{ xs: 15, sm: 15, md: 15 }}
                                      key={field.key}
                                      align="baseline"
                                    >
                                      <Col xs={24}>
                                        <Form.Item
                                          {...field}
                                          name={[field.name, "question"]}
                                          rules={[
                                            {
                                              required: true,
                                              message: "Question is required",
                                            },
                                          ]}
                                        >
                                          <Input placeholder="Question" />
                                        </Form.Item>
                                      </Col>
                                      <Col xs={24}>
                                        <Form.Item
                                          {...field}
                                          name={[field.name, "answer"]}
                                          rules={[
                                            {
                                              required: true,
                                              message: "Answer is required",
                                            },
                                          ]}
                                        >
                                          <TextEditor simpleTollbar placeholder="Answer" />
                                        </Form.Item>

                                        {isUpdateForm && (
                                          <Form.Item
                                            hidden
                                            {...field}
                                            initialValue={0}
                                            name={[field.name, "id"]}
                                          >
                                            <InputNumber />
                                          </Form.Item>
                                        )}
                                      </Col>
                                    </Row>
                                  </>
                                ))}
                                <Col>
                                  <Form.Item>
                                    <button
                                      type="button"
                                      onClick={() => add()}
                                      className="btn btn-md btn-secondary rounded font-weight-medium"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        fill="currentColor"
                                        className="bi bi-plus"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                      </svg>
                                      Add New
                                    </button>
                                  </Form.Item>
                                </Col>
                              </>
                            )
                          }}
                        </Form.List> */}
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>

              <div className="inner-action-bar footer">
                <div className="right-content ml-auto">
                  <div className="btn-group">
                    <button
                      disabled={actionBarOptions.loading}
                      htmlType="submit"
                      className="btn btn-primary"
                      onClick={actionBarOptions.updateExistingEntry}
                      id="add_new"
                    >
                      <i className="bi bi-save2"></i>Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {isUpdateForm && (
            <div
              className={`common-module mb-0 bg-white border step-content ${
                currentMenu === "extra_info" && "active"
              }`}
            >
              <div className="inner-action-bar header">
                <div className="left-content">
                  <h2>Others</h2>
                </div>
                <div className="right-content">
                  <div className="btn-group">
                    <button
                      disabled={actionBarOptions.loading}
                      htmlType="submit"
                      className="btn btn-primary"
                      onClick={actionBarOptions.updateExistingEntry}
                      id="add_new"
                    >
                      <i className="bi bi-save2"></i>Save
                    </button>
                  </div>
                </div>
              </div>

              <div className="module-container">
                <div className="module-container-left">
                  <Anchor offsetTop={24} className="fis-anchor">
                    <Link href="#related" title="Related Trip" />
                    <Link href="#media_options" title="Media Options" />
                  </Anchor>
                </div>
                <div className="module-container-right">
                  <div id="related" className="mb-3">
                    <h3>Related</h3>
                    <Row gutter={10}>
                      <Col className="gutter-row" xs={24} sm={18}>
                        <Form.Item label="Related Trip" name="custom_related">
                          <Select
                            mode="multiple"
                            placeholder="Inserted are removed"
                            style={{
                              width: "100%",
                            }}
                            options={package_list_options && package_list_options}
                            filterOption={(inputValue, option) =>
                              option.label.toLowerCase().includes(inputValue.toLowerCase())
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" xs={24} sm={18}>
                        <Form.Item label="Banner Options" name="package_geo_longitude">
                          <Select>
                            <Option value="0">Single Image</Option>
                            <Option value="1">Galleries</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>

              <div className="inner-action-bar footer">
                <div className="right-content ml-auto">
                  <div className="btn-group">
                    <button
                      disabled={actionBarOptions.loading}
                      htmlType="submit"
                      className="btn btn-primary"
                      onClick={actionBarOptions.updateExistingEntry}
                      id="add_new"
                    >
                      <i className="bi bi-save2"></i>Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Spin>
      </Form>
      <div className="common-module mb-0 bg-white border">
        {currentMenu === "availability" && isUpdateForm && (
          <DepartureForm
            group_default_price={data.dataById?.group_default_price}
            hasDeparture={data.dataById.has_departure}
          />
        )}
        {currentMenu === "itinerary" && isUpdateForm && (
          <>
            <ItineraryForm
              isUpdateForm={data.dataById.has_itinerary}
              package_id={data.dataById.id}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default PackageForm;
