import DashboardAdd from "../../Components/Layouts/DashboardAdd";
import DepartureForm from "../../Components/Forms/DepartureForm";
import { useCreateDepartureMutation } from "../../redux/slice/departure/departureApiSlice";
import useHandleCreateErrorsAndSuccess from "../../hooks/useHandleCreateErrorsAndSuccess";

function Add() {
  const [
    createEntity,
    {
      data: createdDepartureData,
      isLoading: creatingDeparture,
      isError: createDepartureError,
      isSuccess: createDepartureSuccess,
      error,
    },
  ] = useCreateDepartureMutation("createDeparture");

  let data = {
    data: undefined,
    loading: creatingDeparture,
    error: createDepartureError,
    dataById: undefined,
  };
  const selectedForm = {
    data: undefined,
    loading: creatingDeparture,
    error: error?.data.errors,
    loadedRecently: false,
    dataById: undefined,
  };

  useHandleCreateErrorsAndSuccess(error, createDepartureSuccess, "Departure");

  return (
    <DashboardAdd>
      <DepartureForm
        isAddForm
        {...{
          createEntity,
          data,
          selectedForm,
        }}
      />
    </DashboardAdd>
  );
}

export default Add;
