import DashboardList from "../../Components/Layouts/DashboardList";
import {
  useGetDeletedCategoryTypeListQuery,
  usePermanentDeleteCategoryTypeMutation,
  useRestoreDeletedCategoryTypeMutation,
} from "../../redux/slice/category/categoryTypeApiSlice";

function DeletedCategoryType() {
  const [onDelete, { isLoading: permaDeleting }] =
    usePermanentDeleteCategoryTypeMutation();
  const [onRestore, { isLoading: restoring }] =
    useRestoreDeletedCategoryTypeMutation();

  let loading = permaDeleting || restoring;

  const tableConfig = {
    title: "Deleted Category Type",
    filterOptionTitle: "Category Type",
    filterOptions: ["admin", "superadmin", "member"],
    defaultFilterValue: "admin",
    applyFilter: false,
    tableColumns: [
      { name: "Status", selector: "status", width: "3%" },
      { name: "Title", selector: "category_type", width: "77%" },
      { name: "Modified Date", selector: "updated_at", width: "15%" },
      { name: "ID", selector: "id", width: "5%" },
    ],
    filterByColumn: "type",
    searchByColumn: "title",
    addPath: "/category-type/add",
    //updatePath: "/category-type/update",
    restorePath: "/category-types",
    noDataMessage: "No category type exists",
    //updateLinkColumn: 'title',
    deleteBtnText: "Permanent Delete",
    deleteItemsByIdList: (idList) => {
      onDelete(idList);
    },
    restoreItemsByIdList: (idList) => {
      onRestore(idList);
    },
    loading,
  };
  return (
    <DashboardList
      getList={useGetDeletedCategoryTypeListQuery()}
      tableConfig={tableConfig}
    />
  );
}

export default DeletedCategoryType;
