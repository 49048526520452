import { message } from "antd";

function providesList(resultsWithIds, tagType) {
  return resultsWithIds?.length
    ? [
        { type: tagType, id: "LIST" },
        ...resultsWithIds.map(({ id }) => ({ type: tagType, id: `id-${id}` })),
      ]
    : [{ type: tagType, id: "LIST" }];
}

export const createQueryForList = (builder, url, providesTags) => {
  return builder.query({
    query: () => url,
    providesTags: (result) => providesList(result, providesTags),
    transformResponse: (response) => response.data.sort((a, b) => b.id - a.id),
  });
};

// query with id
export const createQueryById = (builder, url, providesTags) => {
  return builder.query({
    query: (id) => `${url}/${id}`,
    providesTags: (result) => providesList([result], providesTags),
    transformResponse: (response) => response.data,
    // providesTags: providesTags,
  });
};

//delete mutation
export const createDeleteRestoreMutation = (builder, url, invalidatesTags) => {
  return builder.mutation({
    query: (ids) => ({
      url: url,
      method: "POST",
      body: { bulk: ids },
    }),
    transformResponse: (response) => response.data,
    invalidatesTags: invalidatesTags,
  });
};

/* mutation for list while creating item
  direct append formData is showing in list
  but doesnnot work in our cause id is not assigned yet
*/
const optimisticUpdate = async ({ apiSlice, endpoint, formData, dispatch, queryFulfilled }) => {
  const updateResult = dispatch(
    apiSlice.util.updateQueryData(endpoint, undefined, (draftData) => {
      draftData.push(formData);
    })
  );

  try {
    await queryFulfilled;
  } catch (error) {
    updateResult.undo();
    console.error("Error on adding the data:", error);
    message.error("Error on adding the data");
  }
};

export default optimisticUpdate;
